import React, { useState } from 'react';

type IntakeContextType = {
	currentIndex: number;
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
};

/* eslint-disable */
export const IntakeContext = React.createContext<IntakeContextType>({
	currentIndex: 0,
	setCurrentIndex: () => {},
});

/* eslint-enable */
type IntakeProviderProps = { children: React.ReactNode };

const IntakeProvider = ({ children }: IntakeProviderProps) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	return (
		<IntakeContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				// Section Position
				currentIndex,
				setCurrentIndex,
			}}
		>
			{children}
		</IntakeContext.Provider>
	);
};

export default IntakeProvider;
