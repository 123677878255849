import {
	BulkDeleteWithConfirmButton,
	BulkExportButton,
} from 'ra-ui-materialui';
import React, { useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { exportFile } from '../../../helpers';
import ActivateButton from './activate.button';
import ArchiveButton from './archive.button';

const UserActionsButtons = (props: any) => {
	const { selectedIds } = props;
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const { mutate: exportUsers, data } = useMutation<{
		data: Record<string, any>;
	}>(
		() =>
			dataProvider.userExportCsv({
				ids: selectedIds,
			}),
		{
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	useEffect(() => {
		if (data) exportFile(data?.data, 'users');
	}, [data]);

	const exporter = async () => {
		exportUsers();
	};

	return (
		<>
			<ActivateButton {...props} />
			<ArchiveButton {...props} />
			<BulkDeleteWithConfirmButton {...props} />
			<BulkExportButton {...props} exporter={exporter} />
		</>
	);
};

export default UserActionsButtons;
