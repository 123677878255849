import React, { useEffect, useState } from 'react';
import {
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger,
} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import useScroll from '../hooks/useScroll';
import { IntakeQuestion } from '../intake.data';
import { IntakeFormData } from '../IntakePage';
import Choices from './content/Choices';
import Description from './content/Description';
import IntakeLabel from './IntakeLabel';
import SkillRating from './content/MultiSelect';
import HybridSelection from './content/HybridSelection';
import YesNoAvailability from './content/YesNoAvail';
import ConsentSection from './content/ConsentSection';

type IntakeSectionProps = {
	index: number;
	section: IntakeQuestion;
	register: UseFormRegister<IntakeFormData>;
	setValue: UseFormSetValue<IntakeFormData>;
	trigger: UseFormTrigger<IntakeFormData>;
	errors: any;
	loading?: boolean;
	getValues: UseFormGetValues<IntakeFormData>;
	onSubmit: () => void;
};

const IntakeSection = ({
	index,
	section,
	register,
	setValue,
	trigger,
	errors,
	loading,
	getValues,
	onSubmit,
}: IntakeSectionProps) => {
	const { scroll } = useScroll();

	const [phoneValue, setPhoneValue] = useState<string>();

	const handleSubmit = () => {
		if (section.type === 'text1') {
			onSubmit();
		} else {
			inputSubmit();
		}
	};

	const inputSubmit = async () => {
		if (!section.inputName) return scroll('down', index);

		const result = await trigger(section.inputName, { shouldFocus: true });
		if (!result) return console.log('Form Error');
		scroll('down', index);
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			inputSubmit();
		}
	};

	useEffect(() => {
		setValue('phone', phoneValue ?? '');
	}, [phoneValue, setValue]);

	return (
		<div id={index.toString()} className="question-container w-full min-h-screen flex items-center">
			<div className="container mx-auto lg:px-64 px-6 py-6 justify-between">
				<div
					className={`flex flex-col w-full max-h-screen overflow-y-auto ${section.description.length <= 100
							? 'justify-center'
							: ''
						}`}>
					{/* Only show IntakeLabel and Description if not text1 type */}
					{section.type !== 'text1' && (
						<div className="font-button">
							<IntakeLabel section={section} getValues={getValues} />
							<Description description={section.description} type={section.type} />
						</div>
					)}

					{section.type === 'input' && (
						<Input
							refIndex={index}
							onKeyPress={handleKeyPress}
							name={section.inputName}
							register={register}
							className="text-xl px-2 font-normal"
						/>
					)}

					{section.type === 'phone' && (
						<div className="mb-3">
							<PhoneInput
								id={`input-${index}`}
								onKeyPress={handleKeyPress}
								international
								countryCallingCodeEditable={false}
								defaultCountry="US"
								value={phoneValue ?? ''}
								onChange={(x) => setPhoneValue(x)}
							/>
						</div>
					)}

					{/* CHOICES */}
					{section.type === 'choices' && section.choices && section.inputName && section.choices.length > 0 && (
						<div className="max-h-[50vh] overflow-y-auto">
							<Choices
								choices={section.choices}
								inputName={section.inputName}
								setValue={setValue}
							/>
						</div>
					)}

					{/* RATING */}
					{section.type === 'rating' && section.choices && section.inputName && section.choices.length > 0 && (
						<div className="max-h-[50vh] overflow-y-auto">
							<SkillRating
								choices={section.choices}
								inputName={section.inputName}
								setValue={setValue}
							/>
						</div>
					)}


					{/* HYBRID */}
					{section.type === 'hybrid' && section.choices && section.inputName && section.choices.length > 0 && (
						<div className="max-h-[50vh] overflow-y-auto">
							<HybridSelection
								choices={section.choices}
								inputName={section.inputName}
								setValue={setValue}
								allowMultiple={['motivations', 'digitalImpactVision'].includes(section.inputName)}
							/>
						</div>
					)}

					{/* YES/NO AVAILABILITY */}
					{section.type === 'yesno' && section.inputName === 'contributeViaWorkshops' && (
						<YesNoAvailability
							inputName={section.inputName}
							setValue={setValue}
						/>
					)}


					{/* ERRORS */}
					{section.inputName && errors[section.inputName] && (
						<p className="mt-4">
							{errors[section.inputName]?.message}
						</p>
					)}

					{/* BUTTON */}
					{section.type !== 'text1' && (
						<div className={`mt-6 ${section.button === 'Begin Application' ? 'text-center' : ''}`}>
							{section.button === 'Begin Application' && (
								<Button
									type="button"
									onClick={inputSubmit}
									className="text-lg"
								>
									{section.button}
								</Button>
							)}
							{section.button === 'OK' && section.type !== 'yesno' && (
								<div className="flex justify-left">
									<Button
										type="button"
										onClick={inputSubmit}
										className="text-lg"
									>
										{section.button}
									</Button>
								</div>
							)}
							{section.button !== 'Submit' && section.button !== 'Begin Application' && section.button !== 'OK' && !section.choices && (
								<Button
									type="button"
									onClick={inputSubmit}
									className="text-lg"
								>
									{section.button}
								</Button>
							)}
						</div>
					)}

					{section.type === 'text1' && (
						<ConsentSection
							label={Array.isArray(section.label) ? section.label : [section.label]}
							onSubmit={handleSubmit}
						/>
					)}
				</div>
			</div>
		</div>

	);
};

export default IntakeSection;
