import React, { useContext, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { uniqueId } from 'lodash';
import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';
import Button from '../../../../components/Button';

type HybridSelectionProps = {
    inputName: InputName;
    choices: string[];
    setValue: UseFormSetValue<IntakeFormData>;
    allowMultiple: boolean;
};

const HybridSelection = ({ inputName, choices, setValue, allowMultiple }: HybridSelectionProps) => {
    const { currentIndex } = useContext(IntakeContext);
    const { scroll } = useScroll();
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const handleOptionChange = (choice: string) => {
        setSelectedOptions(prev => {
            let newSelection: string[];
            if (allowMultiple) {
                newSelection = prev.includes(choice)
                    ? prev.filter(item => item !== choice)
                    : [...prev, choice];
            } else {
                newSelection = [choice];
            }
            
            setValue(inputName as keyof IntakeFormData, newSelection);
            return newSelection;
        });
    };

    const handleNext = () => {
        if (inputName && selectedOptions.length > 0) {
            setValue(inputName as keyof IntakeFormData, allowMultiple ? selectedOptions : selectedOptions[0]);
            scroll('down', currentIndex);
        }
    };

    return (
        <div className="w-full max-w-3xl pb-5">
            <style>{`
                .custom-radio {
                    appearance: none;
                    -webkit-appearance: none;
                    background-color: #fff;
                    margin: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    border: 2px solid #d1d5db;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                }

                .custom-radio:checked {
                    border-color: #000;
                }

                .custom-radio:checked::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 0.75rem;
                    height: 0.75rem;
                    border-radius: 50%;
                    background-color: #000;
                }

                .custom-radio:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
                }

                .custom-radio:hover {
                    border-color: #9ca3af;
                }
            `}</style>

            <div className="space-y-4">
                {choices.map((choice) => (
                    <div key={uniqueId()} className="flex items-center font-button text-xl">
                        <input
                            type="radio"
                            id={`choice-${choice}`}
                            name={allowMultiple ? `hybrid-selection-${choice}` : `hybrid-selection-${inputName}`}
                            value={choice}
                            checked={selectedOptions.includes(choice)}
                            onChange={() => handleOptionChange(choice)}
                            className="custom-radio"
                        />
                        <label htmlFor={`choice-${choice}`} className="ml-3">
                            {choice}
                        </label>
                    </div>
                ))}
            </div>

            <div className="mt-8 flex justify-left">
                <Button
                    type="button"
                    className="bg-gray-900 text-white px-8 py-2 rounded"
                    onClick={handleNext}
                    disabled={selectedOptions.length === 0}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default HybridSelection;