import React, { useState } from 'react';
import { BsGrid } from 'react-icons/bs';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import {
	AllChartMilestone,
	FinancialChart,
	PersonalChart,
	ProfessionalChart,
} from './charts/milestonesTreeChart';

const Spacer = () => <span style={{ margin: '20px' }} />;

export const PillarDropdown = () => {
	const [selectedOption, setSelectedOption] = useState('all');
	const [showComponent, setShowComponent] = useState(true);

	const handleChange = (event) => {
		setSelectedOption(event.target.value);
		setShowComponent(true);
	};

	return (
		<>
			<div
				style={{
					// float: 'right',
					display: 'inline-block',
					padding: '10px',
				}}
			>
				<BsGrid
					style={{
						display: 'inline-block',
						marginTop: '10px',
						marginLeft: '10px',
						marginRight: '10px',
					}}
				/>
			</div>
			<div>
				<Select
					value={selectedOption}
					onChange={handleChange}
					sx={{
						width: '200px',
						height: '40px',
						margin: '10px',
					}}
				>
					<MenuItem value="all">All Pillars</MenuItem>
					<MenuItem value="Professional">Professional</MenuItem>
					<MenuItem value="Personal">Personal</MenuItem>
					<MenuItem value="Financial">Financial</MenuItem>
				</Select>
				<Spacer />
				{showComponent && selectedOption === 'all' && (
					<>
						<Typography
							variant="h5"
							component="h2"
							style={{
								float: 'right',
								marginTop: '10px',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Milestones wise sessions of all pillars
						</Typography>
						<AllChartMilestone />
					</>
				)}
				{showComponent && selectedOption === 'Professional' && (
					<>
						<Typography
							variant="h5"
							component="h2"
							style={{
								float: 'right',
								marginTop: '10px',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Milestones wise sessions of professional pillar
						</Typography>
						<ProfessionalChart />
					</>
				)}
				{showComponent && selectedOption === 'Personal' && (
					<>
						<Typography
							variant="h5"
							component="h2"
							style={{
								float: 'right',
								marginTop: '10px',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Milestones wise sessions of personal pillar
						</Typography>
						<PersonalChart />
					</>
				)}
				{showComponent && selectedOption === 'Financial' && (
					<>
						<Typography
							variant="h5"
							component="h2"
							style={{
								float: 'right',
								marginTop: '10px',
								marginLeft: '10px',
								marginRight: '10px',
							}}
						>
							Milestones wise sessions of financial pillar
						</Typography>
						<FinancialChart />
					</>
				)}
			</div>
		</>
	);
};
