import * as React from 'react';
import { gql, useQuery } from 'urql';
import PieChart from './PieChart';
import './charts.css';

const totalUsersQuery = gql`
	query totalUsers {
		totalUsers {
			activated
			unactivated
		}
	}
`;

const MembersTypesChart = () => {
	const [users] = useQuery({
		query: totalUsersQuery,
	});

	const activeUsers = users?.data?.totalUsers.activated;
	const inactiveUsers = users?.data?.totalUsers.unactivated;

	const activeInactiveUsersData: { label: string; value: number }[] = [
		{ label: `Active`, value: activeUsers },
		{ label: `Inactive`, value: inactiveUsers },
	];
	return (
		<>
			<PieChart data={activeInactiveUsersData} width={500} height={250} />
		</>
	);
};

export default MembersTypesChart;
