import { gql } from '@apollo/client';

export const GetUserCheckoutQuery = gql`
	query getUserCheckout($type: SubscriptionType!) {
		paymentCreateCheckout(type: $type) {
			sessionID
		}
	}
`;

export enum UserCheckoutType {
	MONTHLY = 'MONTHLY',
	YEARLY = 'YEARLY',
}

export interface IGetUserCheckoutQueryVariables {
	type: UserCheckoutType;
}

export interface IGetUserCheckoutQueryData {
	paymentCreateCheckout: {
		sessionID: string;
	};
}
