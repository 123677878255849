import React from 'react';
import { Resource } from 'react-admin';
import ArticleIcon from '@mui/icons-material/VideoCall';
import { AuthorDataList } from '../author-data-list';
import { AuthorDataShow } from '../author-data-show';

export const AuthorList = () => {
	return (
		<>
			<Resource
				name="livingRooms"
				options={{
					label: 'Authors Data',
				}}
				show={AuthorDataShow}
				list={AuthorDataList}
				icon={ArticleIcon}
			/>
		</>
	);
};
