import React, { SetStateAction } from 'react';
import Button from '../../../components/Button';

type ErrorPopupProps = {
	error: string | undefined;
	setError: React.Dispatch<SetStateAction<string | undefined>>;
};

const ErrorPopup = ({ error, setError }: ErrorPopupProps) => {
	return (
		<button
			className="fixed top-0 left-o w-full h-screen bg-black bg-opacity-50 flex justify-center items-center"
			onClick={() => setError('')}
		>
			<div className="p-8 rounded-md bg-yellowed flex flex-col items-center justify-center">
				<p className="mb-6">{error}</p>
				<Button onClick={() => setError('')}>Close</Button>
			</div>
		</button>
	);
};

export default ErrorPopup;
