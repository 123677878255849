import React, { useMemo } from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';

const LivingRoomShowActions = () => {
	const record = useRecordContext();
	const showEditButton = useMemo(() => record?.hasStarted !== true, [record]);
	return (
		<TopToolbar>
			{showEditButton && <EditButton record={record} />}
		</TopToolbar>
	);
};

export default LivingRoomShowActions;
