import React, { useContext, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';
import Button from '../../../../components/Button';

// Base button component with gradient support
const GradientButton = ({ 
  children, 
  isSelected, 
  onClick, 
  className = '' 
}) => (
  <button
    className={`
      rounded-md py-2 px-6 text-xl mb-2 font-button
      transition-all duration-300 
      ${isSelected ? 'gradient-selected' : 'bg-black text-white hover:bg-white hover:text-black border border-transparent hover:border-black'}
      ${className}
    `}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

const Choices = ({ 
  inputName, 
  choices, 
  setValue 
}: {
  inputName: InputName;
  choices: string[];
  setValue: UseFormSetValue<IntakeFormData>;
}) => {
  const { currentIndex } = useContext(IntakeContext);
  const { scroll } = useScroll();
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  const handleChoiceClick = (choice: string) => {
    if (inputName) {
      setSelectedChoice(choice);
      setValue(inputName, choice);
      scroll('down', currentIndex);
    }
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (selectedChoice) {
      scroll('down', currentIndex);
    }
  };

  return (
    <div className="flex flex-col items-start mt-5">
      {choices.map((choice) => (
        <GradientButton
          key={choice}
          isSelected={selectedChoice === choice}
          onClick={() => handleChoiceClick(choice)}
        >
          {choice}
        </GradientButton>
      ))}
      <div className="mt-8 flex justify-left">
                <Button
                    type="button"
                    className="bg-gray-900 text-white px-8 py-2 rounded"
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>
        </div>
  );
};

export default Choices;
