import React from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputName } from '../public/Intake/intake.data';
import { IntakeFormData } from '../public/Intake/IntakePage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string;
	className?: string;
	fullWidth?: boolean;
	register?: UseFormRegister<IntakeFormData>;
	name?: InputName;
	refIndex?: number;
}

export type InputValidations<T> = { [K in keyof T]: RegisterOptions };
interface IInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	name?: InputName;
}

const Input = ({
	label,
	fullWidth,
	children,
	className,
	disabled,
	register,
	name,
	refIndex,
	...props
}: IInputProps) => {
	const registeredFieldProps = name && register && { ...register(name) };

	return (
		<div
			className={`flex flex-col items-start mb-6${fullWidth ? ' w-full' : ''}`}
		>
			<span className="text-xs mb-6 text-gray-400 text-left">
				{label}
			</span>
			<input
				id={`input-${refIndex?.toString()}`}
				className={`
    border-b border-t-0 border-l-0 border-r-0 
    py-2 px-0 w-full 
    outline-none main-input
    focus:outline-none focus:ring-0
    transition-all duration-200 ease-in-out
    ${disabled && 'opacity-75'} 
    ${className}
  `}
				disabled={disabled ?? false}
				{...registeredFieldProps}
				{...props}
			/>

		</div>
	);
};

export default Input;
