import { QueryHookOptions, useQuery } from '@apollo/client';
import {
	GetSupportRequestQuery,
	IGetSupportRequestQueryData,
	IGetSupportRequestQueryVariables,
} from '../graphql/queries/getSupportRequest';

/**
 * Get support request query
 * @param options
 * @returns
 */
export const useGetSupportRequestQuery = (
	options?: QueryHookOptions<
		IGetSupportRequestQueryData,
		IGetSupportRequestQueryVariables
	>,
) =>
	useQuery<IGetSupportRequestQueryData, IGetSupportRequestQueryVariables>(
		GetSupportRequestQuery,
		options,
	);
