import gql from 'graphql-tag';

export const UpdateUserMutation = gql`
	mutation updateUser($id: ID!, $input: UserUpdateInput!) {
		userUpdate(id: $id, input: $input) {
			id
		}
	}
`;

export interface IUpdateUserMutationVariables {
	id: string;
	input: {
		password?: string;
		clickedActivationLink?: boolean;
		currentPassword?: string;
		termsAgreed?: boolean;
	};
}

export interface IUpdateUserMutationData {
	userUpdate: {
		id: string;
	};
}
