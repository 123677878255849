import { gql } from 'urql';
import { IQueries } from './queries';

const fragments: IQueries = {
	users: gql`
		fragment UserDetails on User {
			id
			firstName
			lastName
			activatedByAdmin
			email
			username
			bio
			avatar
			dob
			phone
			comment
			hasComment
			role
			address
			state
			locked
			active
			subscription
			subscriptionActivatedAt
			accountActivatedAt
			created
			firstLogin
			updated
			timeout
			mobileOS
			relationship
			children
			ageOfChildren
			contributor
			income
			lastActivity
			clickedActivationLink
			firstLogin
			referred
			enterprise
			focus {
				milestone
			}
			ethnicity
			generation
			pet
			city
			status
			country
			occupation
			occupationType
			employment
			companyName
			companySize
			companyType
			companyIndustry
			companyRole
			companyFunction
			education
			profiles
			howCanUplevylHelpYou
			howCanUplevylElevateYou
			howCanYouHelpUplevyl
			professionalGrowthImportance
			personalGrowthImportance
			financialGrowthImportance
			activeParticipationImportance
			womenSupportImportance
			resume {
				name
				url
			}
			additionalFormInfo {
				motivations
				digitalImpactVision
				contributeViaWorkshops
				firstName
				lastName
				email
				location
				currentTitle
				companyName
				companyAnnualRevenue
				highestEducationLevel
				hearAboutProgram
				aiSkillsRatings
				aiTopicsInterest
				additionalInterests
				linkedInProfile
				socialHandle
  			}
		}
	`,
	livingRooms: gql`
		fragment LivingRoomDetails on LivingRoom {
			id
			title
			header
			description
			start
			end
			meetingUrl
			recorded
			small
			capacity
			created
			updated
			milestones
			pillars
			hasStarted
			registrations {
				user {
					id
					firstName
					lastName
				}
				livingRoom {
					id
				}
				role
			}
		}
	`,
	reports: gql`
		fragment ReportDetails on Report {
			id
			created
			reason
			status
			madeBy {
				id
			}
			user {
				id
			}
			livingRoom {
				id
			}
		}
	`,
	milestones: gql`
		fragment MilestoneDetails on Milestone {
			id
			name
			pillar {
				name
			}
		}
	`,
	logs: gql`
		fragment LogDetails on Log {
			id
			message
			context
			level
			created
			updated
		}
	`,
	polls: gql`
		fragment PollDetails on Poll {
			id
			created
			question
			choices
			results {
				answer
				percentage
			}
			title
			updated
			backgroundColour
			schedule {
				start
				end
			}
		}
	`,
	schedules: gql`
		fragment ScheduleDetails on ScheduleUnion {
			... on Poll {
				id
				title
				schedule {
					start
					end
				}
				__typename
			}
			... on FreeText {
				id
				title
				schedule {
					start
					end
				}
				__typename
			}
			... on Quote {
				id
				title
				schedule {
					start
					end
				}
				__typename
			}
		}
	`,
	freeTexts: gql`
		fragment FreeTextDetails on FreeText {
			id
			backgroundColour
			imageSubTitle
			imageTitle
			imageFormat
			imageURL
			created
			updated
			title
			description
			schedule {
				start
				end
			}
		}
	`,
	quotes: gql`
		fragment QuoteDetails on Quote {
			author
			backgroundColour
			showQuotationMark
			created
			id
			quote
			title
			updated
			schedule {
				start
				end
			}
		}
	`,
};

export default fragments;
