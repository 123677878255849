import React, { useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	ListProps,
	DateField,
	EditButton,
	BooleanField,
	UrlField,
	PaginationProps,
	Pagination,
	useListContext,
	useNotify,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import { exportFile, exportFilter } from '../../helpers';
import TextArrayField from '../components/TextArrayField';
import LivingRoomActionsButtons from './components/living-room.actions';
import LivingRoomFilter from './components/living-room.filter';

type ILivingRoomListProps = ListProps;

const LivingRoomPagination = (props: PaginationProps) => (
	<Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const Aside = (props: any) => {
	const { setFilter } = props;
	const { filterValues } = useListContext();
	setFilter(filterValues);
	return null;
};

export const LivingRoomList = (props: ILivingRoomListProps) => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const [filter, setFilter] = useState<any>();
	const { mutate: exportRooms, data } = useMutation<{
		data: Record<string, any>;
	}>(
		() =>
			dataProvider.livingRoomExportCsv({
				filter: exportFilter(filter),
			}),
		{
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const exporter = async () => {
		exportRooms();
	};

	useEffect(() => {
		if (data) exportFile(data?.data, 'rooms');
	}, [data]);

	return (
		<List
			sort={{
				field: 'created',
				order: 'desc',
			}}
			aside={<Aside setFilter={setFilter} />}
			filters={<LivingRoomFilter />}
			exporter={exporter}
			pagination={<LivingRoomPagination />}
			bulkActionButtons={<LivingRoomActionsButtons />}
			empty={false}
			{...props}
		>
			<Datagrid rowClick="show">
				<TextField source="title" />
				<UrlField source="url" />
				<BooleanField source="recorded" />
				<DateField source="start" showTime />
				<DateField source="end" showTime />
				{/* <TextArrayField source="milestones" /> */}
				<TextArrayField source="pillars" />
				{!filter?.past && <EditButton />}
			</Datagrid>
		</List>
	);
};
