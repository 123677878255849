/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import {
	useRefresh,
	useNotify,
	SimpleForm,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import { Button, TextField } from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useState } from 'react';
import Confirm from '../../components/Confirm';

interface UserImportCsvProps {
	resource?: string;
}

const UserImportCsv = ({ resource = 'custom' }: UserImportCsvProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [url, setUrl] = useState<string>('');
	const [enterpriseName, setEnterpriseName] = useState<string>('');
	const refresh = useRefresh();
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const { mutate: importUsers, isLoading } = useMutation(
		() =>
			dataProvider.userImportCsv({
				url,
				enterpriseName,
			}),
		{
			// undoable: true,
			onSuccess: () => {
				refresh();
				notify('Imported users');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		importUsers();
		setOpen(false);
	};

	return (
		<>
			<Button
				disabled={isLoading}
				onClick={handleClick}
				variant="contained"
				startIcon={<ImportExportIcon />}
			>
				Import Users
			</Button>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title="Import users from a CSV file"
				content="Please enter the direct URL of a CSV file. Note that the format of the column names etc must be the exact same as previous, else the system cannot detect the correct details"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			>
				<SimpleForm toolbar={<></>}>
					<TextField
						label="User CSV direct URL"
						fullWidth
						multiline
						required
						value={url}
						onChange={(e) => setUrl(e.target.value)}
					/>
					<TextField
						label="Enterprise Name (optional)"
						fullWidth
						multiline
						value={enterpriseName}
						onChange={(e) => setEnterpriseName(e.target.value)}
					/>
				</SimpleForm>
			</Confirm>
		</>
	);
};

export default UserImportCsv;
