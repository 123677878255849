export const getPrettyErrorMessage = (msg: string) => {
	return msg.replace(/\[([A-Z_])*\](:)?(\s)?/i, '');
};

export const matchSuggestion = (filterValue: string, choice: any) => {
	return (
		((choice.id as string)?.toLowerCase().match(filterValue?.toLowerCase())
			?.length ?? 0) > 0
	);
};

export const cleanUserDetails = (obj: any) => {
	const result: any = {};
	const keys = Object.keys(obj);

	keys.forEach((t) => {
		if (
			obj[t] !== null &&
			// obj[t] !== 0 &&
			t !== '__typename'
		) {
			result[t] = obj[t];
		}
	});
	return result;
};

export const exportFile = (data: any, entity: string) => {
	if (data) {
		const pom = document.createElement('a');
		const csvContent = data;
		const blob = new Blob([csvContent], {
			type: 'text/csv;charset=utf-8;',
		});
		const url = URL.createObjectURL(blob);
		pom.href = url;
		pom.setAttribute('download', `${entity}-${Date.now()}.csv`);
		pom.click();
	}
};

export const exportFilter = (
	filter: { [s: string]: unknown } | ArrayLike<unknown>,
) => {
	// Pull out search query
	let fieldFilters: {
		field: string;
		value: any;
	}[] = [];

	let queryFilter;
	if (filter && Object.entries(filter)) {
		fieldFilters = Object.entries(filter)
			.map((entry: [string, any]) => {
				// eslint-disable-next-line
				let [field, value] = entry;
				if (value && typeof value === 'object' && value.id) {
					value = value as { id: string };
					value = value.id;
				} else if (value === true) {
					value = 'true';
				} else if (value === false) {
					value = 'false';
				} else if (!Array.isArray(value)) {
					value = [value];
				}
				if (field === 'query') {
					const [query] = value;
					queryFilter = query;
				}

				return {
					field: entry[0],
					value,
				};
			})
			.filter((field) => field)
			.filter((field) => field.field !== 'query') as {
			field: string;
			value: string;
		}[];
	}

	return {
		query: queryFilter,
		fields: fieldFilters,
	};
};
