import { gql } from '@apollo/client';

export const GetPaymentQuery = gql`
	query payment($subscriptionId: String!) {
		payment(subscriptionId: $subscriptionId) {
			paymentId
			userSubscriptionObject {
				paymentIntentId
				subscriptionId
			}
		}
	}
`;

export const updatePaymentStatus = gql`
	query paymentStatusUpdate($paymentIntentId: String!) {
		paymentStatusUpdate(paymentIntentId: $paymentIntentId) {
			paymentStatus
		}
	}
`;

export interface IGetPaymentQueryData {
	payment: {
		paymentId: string;
		userSubscriptionObject: UserSubscriptions;
	};
}

export interface IGetUpdateQueryData {
	paymentStatusUpdate: UserSubscriptions;
}

export interface IGetPaymentQueryVariables {
	subscriptionId: string;
}

export interface IUpdatePaymentStatusVariable {
	paymentIntentId: string;
}

export interface UserSubscriptions {
	id: string;
	subscriptionId: string;
	userId: string;
	startDate: Date | null;
	endDate: Date | null;
	paymentIntentId: string;
	paymentStatus: string;
	created: Date;
	updated: Date;
	deleted: Date;
}
