import React from 'react';
import { Tab, Container, Typography, Paper } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { AuthorList } from '../authorsList';
import { AuthorWiseGroupedChart } from '../charts/authorWiseSession';
import { SeesionsMonthlyChart } from '../charts/compareChart';
import { PillarDropdown } from '../pillarDropdown';

const card2 = {
	width: '450px',
	height: '400px',
	padding: '10px',
	marginRight: '120px',
	marginTop: '10px',
	marginLeft: '10px',
	marginBottom: '10px',
};
const card3 = {
	width: '450px',
	height: '400px',
	padding: '10px',
	margin: '10px',
};
const card4 = {
	width: '1030px',
	height: '470px',
	padding: '10px',
	display: 'flex',
	margin: '10px',
};

export const AuthorsSessionContent = () => {
	const [index, setIndex] = React.useState('author-list-data');
	const authorHandleChange = (
		event: React.SyntheticEvent,
		newIndex: string,
	) => {
		setIndex(newIndex);
	};
	return (
		<>
			<TabContext value={index}>
				<TabList onChange={authorHandleChange} aria-label="author-tabs">
					<Tab label="Authors" value="author-list-data" />
					<Tab
						label="Author wise Graphical Analytics"
						value="authors-chart"
					/>
				</TabList>
				<TabPanel value="author-list-data">
					<Container
						style={{
							width: '1000px',
							height: '600px',
							padding: '10px',
							overflowY: 'auto',
							float: 'left',
							backgroundColor: 'white',
							borderRadius: '5px',
						}}
					>
						<Typography
							variant="h5"
							component="h2"
							style={{
								marginBottom: '20px',
							}}
						>
							Authors List
						</Typography>
						<AuthorList />
					</Container>
				</TabPanel>
				<TabPanel value="authors-chart">
					<div
						style={{
							display: 'flex',
						}}
					>
						<Paper elevation={3} style={card2}>
							<Typography variant="h5" component="h2">
								Pillar wise Sessions
							</Typography>
							<AuthorWiseGroupedChart />
						</Paper>
						<Paper elevation={3} style={card3}>
							<Typography variant="h5" component="h2">
								Most Sessions Happened in 2021
							</Typography>
							<SeesionsMonthlyChart />
						</Paper>
					</div>
					<Paper elevation={3} style={card4}>
						<PillarDropdown />
					</Paper>
				</TabPanel>
			</TabContext>
		</>
	);
};
