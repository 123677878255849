import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
	GetUserCheckoutQuery,
	IGetUserCheckoutQueryData,
	IGetUserCheckoutQueryVariables,
} from '../graphql/queries/getUserCheckout';

/**
 * Get user checkout information from invite token
 * @param options
 * @returns
 */
export const useGetUserCheckoutQuery = (
	options?: QueryHookOptions<
		IGetUserCheckoutQueryData,
		IGetUserCheckoutQueryVariables
	>,
) =>
	useLazyQuery<IGetUserCheckoutQueryData, IGetUserCheckoutQueryVariables>(
		GetUserCheckoutQuery,
		options,
	);
