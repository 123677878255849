import {
	BulkDeleteWithConfirmButton,
	BulkExportButton,
} from 'ra-ui-materialui';
import React, { useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { exportFile } from '../../../helpers';

const LivingRoomActionsButtons = (props: any) => {
	const { selectedIds } = props;
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const { mutate: exportUsers, data } = useMutation<{
		data: Record<string, any>;
	}>(
		() =>
			dataProvider.livingRoomExportCsv({
				ids: selectedIds,
			}),
		{
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	useEffect(() => {
		if (data) exportFile(data?.data, 'rooms');
	}, [data]);

	const exporter = async () => {
		exportUsers();
	};

	return (
		<>
			<BulkDeleteWithConfirmButton {...props} />
			<BulkExportButton {...props} exporter={exporter} />
		</>
	);
};

export default LivingRoomActionsButtons;
