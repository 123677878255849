import { gql } from '@apollo/client';
import {
	UserGeneration,
	UserEducation,
} from '../types/intake.types';

export const CreateUserMutation = gql`
	mutation CreateUser($input: UserCreateInput!) {
		userCreate(input: $input) {
			id
			username
		}
	}
`;

export interface ICreateUserArgs {
	input: {
		firstName: string;
		email: string;
		lastName: string;
		education?: UserEducation;
		phone?: string;
		profiles?: string;
		termsAgreed: true;
		generation: UserGeneration;
		howCanUplevylElevateYou?: string[];
		inviteeToken?: string;
		inviteeUsername?: string;
		additionalFormInfo?: {
			motivations?: string[];
			digitalImpactVision?: string[];
			contributeViaWorkshops?: boolean;
			location?: string;
			currentTitle?: string;
			companyName?: string;
			companyAnnualRevenue?: string;
			highestEducationLevel?: string;
			hearAboutProgram?: string;
			aiSkillsRatings?: number[];
			aiTopicsInterest?: string;
			additionalInterests?: string;
			linkedInProfile?: string;
			socialHandle?: string;
		};
	};
}


export interface ICreateUserMutationData {
	userCreate: {
		id: string;
		username: string;
	};
}
