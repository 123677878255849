import React from 'react';
import { useQuery } from 'urql';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Resource } from 'react-admin';
import ArticleIcon from '@mui/icons-material/VideoCall';
import { CURRENT_LIVING_ROOMS } from './sessionsQuery';
import { LivingRoomList } from '../living-room.list';
import { LivingRoomShow } from '../living-room.show';
import { LivingRoomCreate } from '../living-room.create';
import { LivingRoomEdit } from '../living-room.edit';

const CurrentLivingRoomData = () => {
	const [result] = useQuery({
		query: CURRENT_LIVING_ROOMS,
		variables: {
			limit: 10,
		},
	});

	const { fetching, error, data } = result;

	if (fetching)
		return (
			<Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
				<CircularProgress color="inherit" />
			</Stack>
		);
	if (error) return <p>Error: {error.message}</p>;

	const tCurrentRooms = data.livingRooms.pageInfo.total;
	if (tCurrentRooms === 0) {
		return (
			<Resource
				name="livingRooms"
				options={{
					label: 'Living Rooms',
				}}
				show={LivingRoomShow}
				list={LivingRoomList}
				create={LivingRoomCreate}
				edit={LivingRoomEdit}
				icon={ArticleIcon}
			/>
		);
	}
	return (
		<>
			<Resource
				name="livingRooms"
				options={{
					label: 'Living Rooms',
				}}
				show={LivingRoomShow}
				list={LivingRoomList}
				create={LivingRoomCreate}
				edit={LivingRoomEdit}
				icon={ArticleIcon}
			/>
		</>
	);
};

export default CurrentLivingRoomData;
