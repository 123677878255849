import gql from 'graphql-tag';
import { ISupportReply } from '../queries/getSupportRequest';

export const SubmitSupportReplyMutation = gql`
	mutation createSupport($id: ID!, $input: SupportReplyInput!) {
		supportReply(id: $id, input: $input) {
			id
			replies {
				created
				body
				staff {
					name
				}
			}
		}
	}
`;

export interface ISubmitSupportReplyMutationVariables {
	id: string;
	input: {
		body: string;
	};
}

export interface ISubmitSupportReplyMutationData {
	supportReply: {
		id: string;
		replies: ISupportReply[];
	};
}
