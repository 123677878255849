/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Container from '../../components/Container';
import DownloadButton from '../../components/DownloadButton';
import Header from '../../components/Header';

const Download = () => {
	return (
		<Container>
			<Header title="Download Uplevyl" />
			<p className="font-light my-5">
				Clicking the button below will take you to appstore, where
				further details can be found about our application.
			</p>
			<DownloadButton />
		</Container>
	);
};

export default Download;
