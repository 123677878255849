import React from 'react';
import Button from '../../../../components/Button';

type ConsentSectionProps = {
  label: string[];
  onSubmit: () => void;
};

const ConsentSection = ({ label, onSubmit }: ConsentSectionProps) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="flex flex-col w-full mt-[-32px]"> {/* Applied negative margin */}
      <div className="mb-6">
        <h2 className="text-xl font-button text-center">
          <div className="flex flex-col space-y-1">
            {label.map((line) => (
              <span 
                key={`consent-${line.trim().substring(0, 20)}`}
                className="block"
              >
                {line}
              </span>
            ))}
          </div>
        </h2>
      </div>

      <div className="flex justify-center">
        <Button
          type="button"
          onClick={handleSubmit}
          className="text-lg"
        >
          Submit Application
        </Button>
      </div>
    </div>
  );
};

export default ConsentSection;