import React from 'react';
import {
	Edit,
	EditProps,
	DateTimeInput,
	TextInput,
	SimpleForm,
} from 'react-admin';
import {
	RichTextInput,
	FormatButtons,
	RichTextInputToolbar,
	LevelSelect,
	ListButtons,
	ClearButtons,
} from 'ra-input-rich-text';
import ColorDropDownInput from '../components/ColorDropdownInput';
import ImageFormatDropDownInput from '../components/ImageFormatDropdownInput';

export const FreeTextEdit = (props: EditProps) => {
	const transform = (data: any) => {
		const newData = data;
		newData.start = newData.schedule.start;
		newData.end = newData.schedule.end;
		delete newData.schedule;
		return newData;
	};

	return (
		<Edit {...props} transform={transform}>
			<SimpleForm>
				<TextInput
					source="title"
					required
					helperText="FreeText's Title"
				/>
				<RichTextInput
					toolbar={
						<RichTextInputToolbar>
							<LevelSelect />
							<FormatButtons />
							<ListButtons />
							<ClearButtons />
						</RichTextInputToolbar>
					}
					source="description"
				/>
				<ColorDropDownInput
					helperText="Background Colour of the Post"
					source="backgroundColour"
					label="Background Color"
				/>
				<TextInput
					source="imageTitle"
					label="Image Title"
					helperText="Image Title"
				/>
				<ImageFormatDropDownInput
					helperText="Format of the Image"
					source="imageFormat"
					label="Image Format"
				/>
				<TextInput
					source="imageSubTitle"
					label="Image Subtitle"
					helperText="Image Subtitle"
				/>
				<TextInput
					source="imageURL"
					label="Image URL"
					helperText="Poll's Image URL"
				/>
				<DateTimeInput
					label="Start"
					source="schedule.start"
					required
					helperText="FreeText's Scheduled Start Time"
				/>
				<DateTimeInput
					label="End"
					source="schedule.end"
					required
					helperText="FreeText's Scheduled End Time"
				/>
			</SimpleForm>
		</Edit>
	);
};
