import { DocumentNode } from '@apollo/client';
import one from './one';
import list from './list';
import many from './many';

export interface IQueries {
	users: DocumentNode | DocumentNode[];
	livingRooms: DocumentNode | DocumentNode[];
	milestones: DocumentNode | DocumentNode[];
	logs: DocumentNode | DocumentNode[];
	reports: DocumentNode | DocumentNode[];
	polls: DocumentNode | DocumentNode[];
	schedules: DocumentNode | DocumentNode[];
	freeTexts: DocumentNode | DocumentNode[];
	quotes: DocumentNode | DocumentNode[];
}

// eslint-disable-next-line
export default { one, list, many };
