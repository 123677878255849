import React from 'react';
import { Treemap, Tooltip } from 'recharts';
import { allmilestonesCount, TotalMilestonesCount } from '../sessionsQuery';

const CustomizedContent = (props: any) => {
	const {
		root,
		depth,
		x,
		y,
		width,
		height,
		index,
		colors,
		name,
		size,
		value,
	} = props;
	console.log(value);

	return (
		<>
			<g>
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					style={{
						fill:
							depth < 2
								? colors[
										Math.floor(
											(index / root.children.length) * 6,
										)
								  ]
								: 'none',
						stroke: '#fff',
						strokeWidth: 2 / (depth + 1e-10),
						strokeOpacity: 1 / (depth + 1e-10),
					}}
				/>
				{depth === 1 && value > 0 ? (
					<text
						x={x + width / 2}
						y={y + height / 2 + 7}
						textAnchor="middle"
						fill="#fff"
						fontSize={14}
						style={{ fontFamily: 'Roboto', fontWeight: 5 }}
					>
						{`${name} (${size})`}
					</text>
				) : null}
			</g>
		</>
	);
};

export const AllChartMilestone = () => {
	TotalMilestonesCount();
	const data = [
		{
			name: 'Career Coaching',
			size: allmilestonesCount.career_Coaching,
		},
		{
			name: 'Leadership and Peak Performance',
			size: allmilestonesCount.leadership_Peak_performance,
		},
		{
			name: 'Diversity, Equity and Inclusion',
			size: allmilestonesCount.diversity_Equity_Inclusion,
		},
		{
			name: 'Performance Reviews and Promotions',
			size: allmilestonesCount.performance_Reviews_Promotions,
		},
		{
			name: 'Career Pivots and Job Loss',
			size: allmilestonesCount.career_Pivots_Job_Loss,
		},
		{
			name: 'Fierce after 50',
			size: allmilestonesCount.fierce_after_50,
		},
		{
			name: 'Networking',
			size: allmilestonesCount.networking,
		},
		{
			name: 'Career Search',
			size: allmilestonesCount.career_Search,
		},
		{
			name: 'Entrepreneurship',
			size: allmilestonesCount.entrepreneurship,
		},
		{
			name: 'Mentorship',
			size: allmilestonesCount.mentorship,
		},
		{
			name: 'Mental Health and Wellbeing',
			size: allmilestonesCount.mental_Health_Wellbeing,
		},
		{
			name: 'Spiritual Wellness',
			size: allmilestonesCount.spiritual_Wellness,
		},
		{
			name: 'Family and Home',
			size: allmilestonesCount.family_Home,
		},
		{
			name: 'Health and Beauty',
			size: allmilestonesCount.health_Beauty,
		},
		{
			name: 'Giving & Impact',
			size: allmilestonesCount.giving_Impact,
		},
		{
			name: 'Food & Culture',
			size: allmilestonesCount.food_Culture,
		},
		{
			name: 'The Gender Lens',
			size: allmilestonesCount.gender_Lens,
		},
		{
			name: 'Marriage & Relationships',
			size: allmilestonesCount.marriage_Relationships,
		},
		{
			name: 'Aging & Caregiving',
			size: allmilestonesCount.aging_Caregiving,
		},
		{
			name: 'Midlife & Menopause',
			size: allmilestonesCount.midlife_Menopause,
		},
		{
			name: 'Adulting',
			size: allmilestonesCount.Adulting,
		},
		{
			name: 'Family Planning & Pregnancy',
			size: allmilestonesCount.family_Planning_Pregnancy,
		},
		{
			name: 'Personal Growth',
			size: allmilestonesCount.personal_Growth,
		},
		{
			name: 'Friendship',
			size: allmilestonesCount.friendship,
		},
		{
			name: 'Breakups and Divorce',
			size: allmilestonesCount.breakups_Divorce,
		},
		{
			name: 'Family, Divorce & Education',
			size: allmilestonesCount.family_Divorce_Education,
		},
		{
			name: 'Impact & ESG Investing',
			size: allmilestonesCount.impact_ESG_Investing,
		},
		{
			name: 'Financial Independence & Investing',
			size: allmilestonesCount.financial_Independence_Investing,
		},
		{
			name: 'Secure Aging & Retirement',
			size: allmilestonesCount.secure_Aging_Retirement,
		},
		{
			name: 'Homeownership',
			size: allmilestonesCount.homeownership,
		},
		{
			name: 'Crypto & More',
			size: allmilestonesCount.crypto_More,
		},
		{
			name: 'Taxes & Insurance',
			size: allmilestonesCount.taxes_Insurance,
		},
		{
			name: 'Me, Myself & Money',
			size: allmilestonesCount.me_Myself_Money,
		},
		{
			name: 'Budgeting & Spending',
			size: allmilestonesCount.budgeting_Spending,
		},
		{
			name: 'Establishing & Managing Credit',
			size: allmilestonesCount.establishing_Managing_Credit,
		},
		{
			name: 'Managing Debt',
			size: allmilestonesCount.managing_debt,
		},
	];
	const COLORS = [
		'#8889DD',
		'#9597E4',
		'#8DC77B',
		'#A5D297',
		'#E2CF45',
		'#F8C12D',
	];

	return (
		<>
			<div
				style={{
					display: 'flex',
				}}
			>
				<Treemap
					width={920}
					height={400}
					data={data}
					dataKey="size"
					aspectRatio={4 / 3}
					stroke="#fff"
					fill="#8884d8"
					content={<CustomizedContent colors={COLORS} />}
				>
					<Tooltip />
				</Treemap>
			</div>
		</>
	);
};

export const ProfessionalChart = () => {
	console.log(allmilestonesCount);
	TotalMilestonesCount();
	console.log(allmilestonesCount);
	const data = [
		{
			name: 'Career Coaching',
			size: allmilestonesCount.career_Coaching,
		},
		{
			name: 'Leadership and Peak Performance',
			size: allmilestonesCount.leadership_Peak_performance,
		},
		{
			name: 'Diversity, Equity and Inclusion',
			size: allmilestonesCount.diversity_Equity_Inclusion,
		},
		{
			name: 'Performance Reviews and Promotions',
			size: allmilestonesCount.performance_Reviews_Promotions,
		},
		{
			name: 'Career Pivots and Job Loss',
			size: allmilestonesCount.career_Pivots_Job_Loss,
		},
		{
			name: 'Fierce after 50',
			size: allmilestonesCount.fierce_after_50,
		},
		{
			name: 'Networking',
			size: allmilestonesCount.networking,
		},
		{
			name: 'Career Search',
			size: allmilestonesCount.career_Search,
		},
		{
			name: 'Entrepreneurship',
			size: allmilestonesCount.entrepreneurship,
		},
		{
			name: 'Mentorship',
			size: allmilestonesCount.mentorship,
		},
	];
	const COLORS = [
		'#8889DD',
		'#9597E4',
		'#8DC77B',
		'#A5D297',
		'#E2CF45',
		'#F8C12D',
	];

	return (
		<>
			<div
				style={{
					display: 'flex',
				}}
			>
				<Treemap
					width={920}
					height={400}
					data={data}
					dataKey="size"
					aspectRatio={4 / 3}
					stroke="#fff"
					fill="#8884d8"
					content={<CustomizedContent colors={COLORS} />}
				>
					<Tooltip />
				</Treemap>
			</div>
		</>
	);
};

export const PersonalChart = () => {
	console.log(allmilestonesCount);
	TotalMilestonesCount();
	console.log(allmilestonesCount);
	const data = [
		{
			name: 'Mental Health and Wellbeing',
			size: allmilestonesCount.mental_Health_Wellbeing,
		},
		{
			name: 'Spiritual Wellness',
			size: allmilestonesCount.spiritual_Wellness,
		},
		{
			name: 'Family and Home',
			size: allmilestonesCount.family_Home,
		},
		{
			name: 'Health and Beauty',
			size: allmilestonesCount.health_Beauty,
		},
		{
			name: 'Giving & Impact',
			size: allmilestonesCount.giving_Impact,
		},
		{
			name: 'Food & Culture',
			size: allmilestonesCount.food_Culture,
		},
		{
			name: 'The Gender Lens',
			size: allmilestonesCount.gender_Lens,
		},
		{
			name: 'Marriage & Relationships',
			size: allmilestonesCount.marriage_Relationships,
		},
		{
			name: 'Aging & Caregiving',
			size: allmilestonesCount.aging_Caregiving,
		},
		{
			name: 'Midlife & Menopause',
			size: allmilestonesCount.midlife_Menopause,
		},
		{
			name: 'Adulting',
			size: allmilestonesCount.Adulting,
		},
		{
			name: 'Family Planning & Pregnancy',
			size: allmilestonesCount.family_Planning_Pregnancy,
		},
		{
			name: 'Personal Growth',
			size: allmilestonesCount.personal_Growth,
		},
		{
			name: 'Friendship',
			size: allmilestonesCount.friendship,
		},
		{
			name: 'Breakups and Divorce',
			size: allmilestonesCount.breakups_Divorce,
		},
	];
	const COLORS = [
		'#8889DD',
		'#9597E4',
		'#8DC77B',
		'#A5D297',
		'#E2CF45',
		'#F8C12D',
	];

	return (
		<>
			<div
				style={{
					display: 'flex',
				}}
			>
				<Treemap
					width={920}
					height={400}
					data={data}
					dataKey="size"
					aspectRatio={4 / 3}
					stroke="#fff"
					fill="#8884d8"
					content={<CustomizedContent colors={COLORS} />}
				>
					{/* <Tooltip /> */}
				</Treemap>
			</div>
		</>
	);
};

export const FinancialChart = () => {
	console.log(allmilestonesCount);
	TotalMilestonesCount();
	console.log(allmilestonesCount);
	const data = [
		{
			name: 'Family, Divorce & Education',
			size: allmilestonesCount.family_Divorce_Education,
		},
		{
			name: 'Impact & ESG Investing',
			size: allmilestonesCount.impact_ESG_Investing,
		},
		{
			name: 'Financial Independence & Investing',
			size: allmilestonesCount.financial_Independence_Investing,
		},
		{
			name: 'Secure Aging & Retirement',
			size: allmilestonesCount.secure_Aging_Retirement,
		},
		{
			name: 'Homeownership',
			size: allmilestonesCount.homeownership,
		},
		{
			name: 'Crypto & More',
			size: allmilestonesCount.crypto_More,
		},
		{
			name: 'Taxes & Insurance',
			size: allmilestonesCount.taxes_Insurance,
		},
		{
			name: 'Me, Myself & Money',
			size: allmilestonesCount.me_Myself_Money,
		},
		{
			name: 'Budgeting & Spending',
			size: allmilestonesCount.budgeting_Spending,
		},
		{
			name: 'Establishing & Managing Credit',
			size: allmilestonesCount.establishing_Managing_Credit,
		},
		{
			name: 'Managing Debt',
			size: allmilestonesCount.managing_debt,
		},
	];
	const COLORS = [
		'#8889DD',
		'#9597E4',
		'#8DC77B',
		'#A5D297',
		'#E2CF45',
		'#F8C12D',
	];

	return (
		<>
			<div
				style={{
					display: 'flex',
				}}
			>
				<Treemap
					width={920}
					height={400}
					data={data}
					dataKey="size"
					aspectRatio={4 / 3}
					stroke="#fff"
					fill="#8884d8"
					content={<CustomizedContent colors={COLORS} />}
				>
					<Tooltip />
				</Treemap>
			</div>
		</>
	);
};
