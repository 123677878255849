import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
// import Button from '../../components/Button';
// import { useGetBillingPortalQuery } from './hooks/useGetBillingPortalQuery';
import { useGetMeQuery } from './hooks/useGetMeQuery';
import { UserSubscriptionEnum } from '../../resources/users/user.options';
// import PaymentRedirect from '../Activate/PaymentRedirect';
import Header from '../../components/Header';
import DownloadButton from '../../components/DownloadButton';

const Account = () => {
	const navigate = useNavigate();

	const { data, loading } = useGetMeQuery({
		context: {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const [errorMessage, setErrorMessage] = useState<string>();
	const [accessToken, setAccessToken] = useState<string | null>(null);

	// const [
	// 	getBillingPortal,
	// 	{
	// 		data: billingPortalData,
	// 		// loading: billingPortalLoading,
	// 		error: billingPortalError,
	// 	},
	// ] = useGetBillingPortalQuery();

	// const redirectToBillingPortal = async () => {
	// 	getBillingPortal({
	// 		context: {
	// 			headers: {
	// 				Authorization: `Bearer ${accessToken}`,
	// 			},
	// 		},
	// 	});

	// 	if (billingPortalError)
	// 		return setErrorMessage(billingPortalError.message);

	// 	// // Success, direct onto next page for payment
	// 	// // setShowPayment(true);
	// };

	// useEffect(() => {
	// 	if (!billingPortalData?.paymentCreatePortalLink?.url) return;
	// 	window.location.href = billingPortalData.paymentCreatePortalLink.url;
	// }, [billingPortalData]);

	useEffect(() => {
		const accessTokenStored = localStorage.getItem('accessToken');
		if (!accessTokenStored) navigate('/login');
		setAccessToken(accessTokenStored);
	}, [accessToken, navigate]);

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			<Container>
				{loading ? (
					<h3 className="text-lg">
						We&apos;re fetching your information, one sec...
					</h3>
				) : (
					<>
						<Header title={`Hello again, ${data?.me.firstName}!`} />
						<p className="font-light mt-5">
							Your membership is currently{' '}
							<span className="font-bold">
								{data?.me.subscription ===
								UserSubscriptionEnum.ACTIVE
									? 'active'
									: 'inactive'}
							</span>
							.
						</p>
						<p className="font-light my-5">
							To gain access, please download the app to proceed
							further.
						</p>
						<DownloadButton />
						{/* Temporarily removed as subscriptions removed */}
						{/* <p className="font-light my-5 mb-10">
							You can{' '}
							{data?.me.subscription ===
							UserSubscriptionEnum.ACTIVE
								? 'manage your membership and update your payment details via the button below'
								: 'reactivate your membership by selecting a package below'}
							.
						</p> */}
						{/* {data?.me.subscription ===
						UserSubscriptionEnum.ACTIVE ? (
							<Button
								onClick={() => redirectToBillingPortal()}
								disabled={billingPortalLoading}
							>
								{!billingPortalLoading
									? 'Manage Membership'
									: 'Loading...'}
							</Button>
						) : (
							accessToken &&
							data?.me && (
								<PaymentRedirect
									allowFreeTrial={
										!data?.me.hasUsedFreeTrial ?? false
									}
									showExplainer={false}
									name={data?.me.firstName}
									accessToken={accessToken}
								/>
							)
						)} */}
					</>
				)}
			</Container>
		</main>
	);
};

export default Account;
