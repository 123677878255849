import React from 'react';
import {
	ArrayField,
	BooleanField,
	Datagrid,
	DateField,
	FunctionField,
	ImageField,
	NumberField,
	ReferenceField,
	RichTextField,
	Show,
	ShowProps,
	Tab,
	TabbedShowLayout,
	TextField,
	UrlField,
} from 'react-admin';
import { Chip } from '@mui/material';
import ReserveSeat from './components/reserve.seat';
import LivingRoomTitle from './components/living-room.title';
import RemoveButton from './components/remove.button';
import LivingRoomShowActions from './components/living-room-show.actions';
import TextArrayField from '../components/TextArrayField';

export const LivingRoomShow = (props: ShowProps) => (
	<Show
		title={<LivingRoomTitle />}
		actions={<LivingRoomShowActions />}
		{...props}
	>
		<TabbedShowLayout>
			<Tab label="Living Room">
				<TextField
					source="title"
					label=""
					style={{
						fontSize: '2rem',
						fontWeight: 'bold',
					}}
				/>
				<ImageField source="header" label="" />
				<ArrayField label="Milestones">
					<FunctionField
						label="Milestones"
						render={(record: any) =>
							record?.milestones.map((milestone: string) => (
								<Chip
									label={milestone.split(':')[1]}
									key={milestone}
									sx={{
										maxWidth: 'fit-content',
										margin: '2px',
									}}
								/>
							))
						}
					/>
				</ArrayField>
				<TextArrayField source="pillars" />
				<UrlField source="url" />
				<DateField source="start" showTime />
				<DateField source="end" showTime />
				<BooleanField source="small" label="Panellist Living Room" />
				<BooleanField source="recorded" />
				<NumberField source="capacity" />
				<RichTextField source="description" />
			</Tab>
			<Tab label="Registrations">
				<ArrayField source="registrations">
					<Datagrid>
						<TextField source="user.firstName" label="First Name" />
						<TextField source="user.lastName" label="Last Name" />
						<ReferenceField
							label="Email"
							source="user.id"
							reference="users"
							link="show"
						>
							<TextField source="email" />
						</ReferenceField>
						<TextField source="role" />
						<RemoveButton />
					</Datagrid>
				</ArrayField>
				<ReserveSeat />
			</Tab>
		</TabbedShowLayout>
	</Show>
);
