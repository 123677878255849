import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ScheduleCreatePage = () => {
	const navigate = useNavigate();

	return (
		<Box>
			<Card
				sx={{
					mt: 2,
					width: 500,
				}}
			>
				<CardContent>
					<Typography variant="subtitle1">
						Choose a post layout:
					</Typography>
					<div className="flex flex-col mt-4">
						<Button
							sx={{
								mb: 2,
								mt: 2,
							}}
							variant="contained"
							onClick={() => {
								navigate('/admin/schedule/create-poll');
							}}
						>
							Poll
						</Button>
						<Button
							sx={{
								mb: 2,
							}}
							variant="contained"
							onClick={() => {
								navigate('/admin/schedule/create-quote');
							}}
						>
							Quote
						</Button>
						<Button
							sx={{
								mb: 2,
							}}
							variant="contained"
							onClick={() => {
								navigate('/admin/schedule/create-free-text');
							}}
						>
							Free Text
						</Button>
					</div>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ScheduleCreatePage;
