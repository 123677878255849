import { useQuery } from 'urql';
import React from 'react';

export const PAST_LIVING_ROOMS = `
query getLivingRooms($limit: Float!) {
    pastLivingRooms(limit: $limit) {
      edges {
        node {
          ...LivingRoomDetails
        }
      }
      pageInfo {
        total
      }
    }
  }

  fragment LivingRoomDetails on LivingRoom {
    id
    title
    start
    end
    pillars
  }
`;

export const CURRENT_LIVING_ROOMS = `
  query getLivingRooms($limit: Float!){
    livingRooms(limit: $limit){
      edges {
        node {
          ...LivingRoomDetails
        }
      }
      pageInfo {
        total
      }
    }
  }

  fragment LivingRoomDetails on LivingRoom {
    id
    title
    start
    end
  }
`;

export const TOTAL_LIVING_ROOMS = `
query getLivingRooms($limit: Float!){
  pastLivingRooms(limit: 10){
    edges{
      node{
        ...LivingRoomDetails
      }
    }
    pageInfo{
      total
    }
  }
  livingRooms(limit: $limit){
    edges{
      node{
        ...LivingRoomDetails
      }
    }
    pageInfo{
      total
    }
  }
}

fragment LivingRoomDetails on LivingRoom{
  id
  start
  end
  pillars
  milestones
  registrations{
    created
  }
}
`;

// export const PastLivingRoomCount = () => {
// 	const [result] = useQuery({
// 		query: PAST_LIVING_ROOMS,
// 		variables: {
// 			perPage: 10,
// 			page: 1,
// 			sort: null,
// 			filter: null,
// 		},
// 	});
// 	const { fetching, error, data } = result;
// 	if (fetching) return <p>Loading...</p>;
// 	if (error) return <p>Error: {error.message}</p>;

// 	const totalPastRooms: number = data.pastLivingRooms.pageInfo.total;

// 	return totalPastRooms;
// };

// export const CurrentLivingRoomCount = () => {
// 	const [result] = useQuery({
// 		query: CURRENT_LIVING_ROOMS,
// 		variables: {
// 			perPage: 10,
// 			page: 1,
// 			sort: null,
// 			filter: null,
// 		},
// 	});
// 	const { fetching, error, data } = result;
//   console.log(data);
// 	if (fetching) return <p>Loading...</p>;
// 	if (error) return <p>Error: {error.message}</p>;

// 	const totalCurrentRooms = data.pastLivingRooms.pageInfo.total;

// 	return totalCurrentRooms;
// };

export const TotalLivingCount = () => {
	const [pastLivingRoomsResult] = useQuery({
		query: `
		query PastLivingRooms($limit: Float!) {
		  pastLivingRooms(limit: $limit) {
			pageInfo {
			  total
			}
		  }
		}
	  `,
		variables: { limit: 10 },
	});

	const [currentLivingRoomsResult] = useQuery({
		query: `
		query CurrentLivingRooms($limit: Float!) {
		  livingRooms(limit: $limit) {
			pageInfo {
			  total
			}
		  }
		}
	  `,
		variables: { limit: 10 },
	});

	const pastLivingRoomsCount =
		pastLivingRoomsResult.data?.pastLivingRooms.pageInfo.total ?? 0;
	const currentLivingRoomsCount =
		currentLivingRoomsResult.data?.livingRooms.pageInfo.total ?? 0;

	const totalLivingRoomsCount =
		pastLivingRoomsCount + currentLivingRoomsCount;

	if (pastLivingRoomsResult.fetching || currentLivingRoomsResult.fetching) {
		return <p>Loading...</p>;
	}

	if (pastLivingRoomsResult.error || currentLivingRoomsResult.error) {
		return (
			<p>
				Error:{' '}
				{pastLivingRoomsResult.error?.message ||
					currentLivingRoomsResult.error?.message}
			</p>
		);
	}

	return <p>{totalLivingRoomsCount}</p>;
};

export const pillarCounts = {
	Financial2021: 0,
	Professional2021: 0,
	Personal2021: 0,
	Financial2022: 0,
	Professional2022: 0,
	Personal2022: 0,
	Financial2023: 0,
	Professional2023: 0,
	Personal2023: 0,
};
let pillarsQuery = false;
export const PillarsCount = () => {
	const [result] = useQuery({
		query: TOTAL_LIVING_ROOMS,
		variables: {
			limit: 25,
		},
	});

	const { fetching, error, data } = result;

	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	const pastlivingRooms = data.pastLivingRooms.edges?.map(
		(edge) => edge.node,
	);
	const livingRooms = data.livingRooms.edges?.map((edge) => edge.node);
	if (pillarsQuery === false) {
		pillarsQuery = true;
		pastlivingRooms.map((room) => {
			const year = new Date(room.start).getFullYear();
			if (year === 2021) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2021 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2021 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2021 += 1;
				}
			}
			if (year === 2022) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2022 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2022 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2022 += 1;
				}
			}
			if (year === 2023) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2023 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2023 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2023 += 1;
				}
			}
			return null;
		});
		livingRooms.map((room) => {
			const year = new Date(room.start).getFullYear();
			if (year === 2021) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2021 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2021 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2021 += 1;
				}
			}
			if (year === 2022) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2022 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2022 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2022 += 1;
				}
			}
			if (year === 2023) {
				if (room.pillars[0] === 'Financial') {
					pillarCounts.Financial2023 += 1;
				}
				if (room.pillars[0] === 'Professional') {
					pillarCounts.Professional2023 += 1;
				}
				if (room.pillars[0] === 'Personal') {
					pillarCounts.Personal2023 += 1;
				}
			}
			return null;
		});
	}
};

export const monthCount = {
	jan2021: 0,
	feb2021: 0,
	mar2021: 0,
	apr2021: 0,
	may2021: 0,
	jun2021: 0,
	jul2021: 0,
	aug2021: 0,
	sep2021: 0,
	oct2021: 0,
	nov2021: 0,
	dec2021: 0,
	jan2022: 0,
	feb2022: 0,
	mar2022: 0,
	apr2022: 0,
	may2022: 0,
	jun2022: 0,
	jul2022: 0,
	aug2022: 0,
	sep2022: 0,
	oct2022: 0,
	nov2022: 0,
	dec2022: 0,
};
let sessionsQuery = false;
export const SessionCount = () => {
	const [result] = useQuery({
		query: TOTAL_LIVING_ROOMS,
		variables: {
			limit: 25,
		},
	});

	const { fetching, error, data } = result;

	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	const pastlivingRooms = data.pastLivingRooms.edges?.map(
		(edge) => edge.node,
	);
	const livingRooms = data.livingRooms.edges?.map((edge) => edge.node);
	if (sessionsQuery === false) {
		sessionsQuery = true;
		pastlivingRooms.map((room) => {
			const year = new Date(room.start).getFullYear();
			const month = new Date(room.start).getMonth();
			if (year === 2021) {
				if (month === 1) {
					monthCount.jan2021 += 1;
				}
				if (month === 2) {
					monthCount.feb2021 += 1;
				}
				if (month === 3) {
					monthCount.mar2021 += 1;
				}
				if (month === 4) {
					monthCount.apr2021 += 1;
				}
				if (month === 5) {
					monthCount.may2021 += 1;
				}
				if (month === 6) {
					monthCount.jun2021 += 1;
				}
				if (month === 7) {
					monthCount.jul2021 += 1;
				}
				if (month === 8) {
					monthCount.aug2021 += 1;
				}
				if (month === 9) {
					monthCount.sep2021 += 1;
				}
				if (month === 10) {
					monthCount.oct2021 += 1;
				}
				if (month === 11) {
					monthCount.nov2021 += 1;
				}
				if (month === 12) {
					monthCount.dec2021 += 1;
				}
			}
			if (year === 2022) {
				if (month === 1) {
					monthCount.jan2022 += 1;
				}
				if (month === 2) {
					monthCount.feb2022 += 1;
				}
				if (month === 3) {
					monthCount.mar2022 += 1;
				}
				if (month === 4) {
					monthCount.apr2022 += 1;
				}
				if (month === 5) {
					monthCount.may2022 += 1;
				}
				if (month === 6) {
					monthCount.jun2022 += 1;
				}
				if (month === 7) {
					monthCount.jul2022 += 1;
				}
				if (month === 8) {
					monthCount.aug2022 += 1;
				}
				if (month === 9) {
					monthCount.sep2022 += 1;
				}
				if (month === 10) {
					monthCount.oct2022 += 1;
				}
				if (month === 11) {
					monthCount.nov2022 += 1;
				}
				if (month === 12) {
					monthCount.dec2022 += 1;
				}
			}
			return null;
		});
		livingRooms.map((room) => {
			const year = new Date(room.start).getFullYear();
			const month = new Date(room.start).getMonth();
			console.log(room.start, month);
			if (year === 2021) {
				if (month === 1) {
					monthCount.jan2021 += 1;
				}
				if (month === 2) {
					monthCount.feb2021 += 1;
				}
				if (month === 3) {
					monthCount.mar2021 += 1;
				}
				if (month === 4) {
					monthCount.apr2021 += 1;
				}
				if (month === 5) {
					monthCount.may2021 += 1;
				}
				if (month === 6) {
					monthCount.jun2021 += 1;
				}
				if (month === 7) {
					monthCount.jul2021 += 1;
				}
				if (month === 8) {
					monthCount.aug2021 += 1;
				}
				if (month === 9) {
					monthCount.sep2021 += 1;
				}
				if (month === 10) {
					monthCount.oct2021 += 1;
				}
				if (month === 11) {
					monthCount.nov2021 += 1;
				}
				if (month === 12) {
					monthCount.dec2021 += 1;
				}
			}
			if (year === 2022) {
				if (month === 1) {
					monthCount.jan2022 += 1;
				}
				if (month === 2) {
					monthCount.feb2022 += 1;
				}
				if (month === 3) {
					monthCount.mar2022 += 1;
				}
				if (month === 4) {
					monthCount.apr2022 += 1;
				}
				if (month === 5) {
					monthCount.may2022 += 1;
				}
				if (month === 6) {
					monthCount.jun2022 += 1;
				}
				if (month === 7) {
					monthCount.jul2022 += 1;
				}
				if (month === 8) {
					monthCount.aug2022 += 1;
				}
				if (month === 9) {
					monthCount.sep2022 += 1;
				}
				if (month === 10) {
					monthCount.oct2022 += 1;
				}
				if (month === 11) {
					monthCount.nov2022 += 1;
				}
				if (month === 12) {
					monthCount.dec2022 += 1;
				}
			}
			return null;
		});
	}
};

export const allmilestonesCount = {
	career_Coaching: 0,
	leadership_Peak_performance: 0,
	diversity_Equity_Inclusion: 0,
	performance_Reviews_Promotions: 0,
	career_Pivots_Job_Loss: 0,
	fierce_after_50: 0,
	networking: 0,
	career_Search: 0,
	entrepreneurship: 0,
	mentorship: 0,
	mental_Health_Wellbeing: 0,
	spiritual_Wellness: 0,
	family_Home: 0,
	health_Beauty: 0,
	giving_Impact: 0,
	food_Culture: 0,
	gender_Lens: 0,
	marriage_Relationships: 0,
	aging_Caregiving: 0,
	midlife_Menopause: 0,
	Adulting: 0,
	family_Planning_Pregnancy: 0,
	personal_Growth: 0,
	friendship: 0,
	breakups_Divorce: 0,
	family_Divorce_Education: 0,
	impact_ESG_Investing: 0,
	financial_Independence_Investing: 0,
	secure_Aging_Retirement: 0,
	homeownership: 0,
	crypto_More: 0,
	taxes_Insurance: 0,
	me_Myself_Money: 0,
	budgeting_Spending: 0,
	establishing_Managing_Credit: 0,
	managing_debt: 0,
};
let milestonesQuery = false;
export const TotalMilestonesCount = () => {
	const [result] = useQuery({
		query: TOTAL_LIVING_ROOMS,
		variables: {
			limit: 25,
		},
	});

	const { fetching, error, data } = result;

	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	const pastlivingRooms = data.pastLivingRooms.edges?.map(
		(edge) => edge.node,
	);
	const livingRooms = data.livingRooms.edges?.map((edge) => edge.node);
	if (milestonesQuery === false) {
		milestonesQuery = true;
		pastlivingRooms.map((room) => {
			// const year = new Date(room.start).getFullYear();
			if (room.milestones[0] === 'Career Coaching') {
				allmilestonesCount.career_Coaching += 1;
			}
			if (
				room.milestones[0] ===
				'Leadership & Peak Performance asdasd asdasd asdasd asdasd asdasd asdasd'
			) {
				allmilestonesCount.leadership_Peak_performance += 1;
			}
			if (room.milestones[0] === 'Diversity, Equity and Inclusion') {
				allmilestonesCount.diversity_Equity_Inclusion += 1;
			}
			if (room.milestones[0] === 'Performance Reviews and Promotions') {
				allmilestonesCount.performance_Reviews_Promotions += 1;
			}
			if (room.milestones[0] === 'Career Pivots and Job Loss') {
				allmilestonesCount.career_Pivots_Job_Loss += 1;
			}
			if (room.milestones[0] === 'Fierce after 50') {
				allmilestonesCount.fierce_after_50 += 1;
			}
			if (room.milestones[0] === 'Networking') {
				allmilestonesCount.networking += 1;
			}
			if (room.milestones[0] === 'Career Search') {
				allmilestonesCount.career_Search += 1;
			}
			if (room.milestones[0] === 'Entrepreneurship') {
				allmilestonesCount.entrepreneurship += 1;
			}
			if (room.milestones[0] === 'Mentorship') {
				allmilestonesCount.mentorship += 1;
			}
			if (room.milestones[0] === 'Mental Health and Wellbeing') {
				allmilestonesCount.mental_Health_Wellbeing += 1;
			}
			if (room.milestones[0] === 'Spiritual Wellness') {
				allmilestonesCount.spiritual_Wellness += 1;
			}
			if (room.milestones[0] === 'Family and Home') {
				allmilestonesCount.family_Home += 1;
			}
			if (room.milestones[0] === 'Health and Beauty') {
				allmilestonesCount.health_Beauty += 1;
			}
			if (room.milestones[0] === 'Giving & Impact') {
				allmilestonesCount.giving_Impact += 1;
			}
			if (room.milestones[0] === 'Food & Culture') {
				allmilestonesCount.food_Culture += 1;
			}
			if (room.milestones[0] === 'The Gender Lens') {
				allmilestonesCount.gender_Lens += 1;
			}
			if (room.milestones[0] === 'Marriage & Relationships') {
				allmilestonesCount.marriage_Relationships += 1;
			}
			if (room.milestones[0] === 'Aging & Caregiving') {
				allmilestonesCount.aging_Caregiving += 1;
			}
			if (room.milestones[0] === 'Midlife & Menopause') {
				allmilestonesCount.midlife_Menopause += 1;
			}
			if (room.milestones[0] === 'Adulting') {
				allmilestonesCount.Adulting += 1;
			}
			if (room.milestones[0] === 'Family Planning & Pregnancy') {
				allmilestonesCount.family_Planning_Pregnancy += 1;
			}
			if (room.milestones[0] === 'Personal Growth') {
				allmilestonesCount.personal_Growth += 1;
			}
			if (room.milestones[0] === 'Friendship') {
				allmilestonesCount.friendship += 1;
			}
			if (room.milestones[0] === 'Breakups and Divorce') {
				allmilestonesCount.breakups_Divorce += 1;
			}
			if (room.milestones[0] === 'Family, Divorce & Education') {
				allmilestonesCount.family_Divorce_Education += 1;
			}
			if (room.milestones[0] === 'Impact & ESG Investing') {
				allmilestonesCount.impact_ESG_Investing += 1;
			}
			if (room.milestones[0] === 'Financial Independence & Investing') {
				allmilestonesCount.financial_Independence_Investing += 1;
			}
			if (room.milestones[0] === 'Secure Aging & Retirement') {
				allmilestonesCount.secure_Aging_Retirement += 1;
			}
			if (room.milestones[0] === 'Homeownership') {
				allmilestonesCount.homeownership += 1;
			}
			if (room.milestones[0] === 'Crypto & More') {
				allmilestonesCount.crypto_More += 1;
			}
			if (room.milestones[0] === 'Taxes & Insurance') {
				allmilestonesCount.taxes_Insurance += 1;
			}
			if (room.milestones[0] === 'Me, Myself & Money') {
				allmilestonesCount.me_Myself_Money += 1;
			}
			if (room.milestones[0] === 'Budgeting & Spending') {
				allmilestonesCount.budgeting_Spending += 1;
			}
			if (room.milestones[0] === 'Establishing & Managing Credit') {
				allmilestonesCount.establishing_Managing_Credit += 1;
			}
			if (room.milestones[0] === 'Managing Debt') {
				allmilestonesCount.managing_debt += 1;
			}

			return null;
		});
		livingRooms.map((room) => {
			// const year = new Date(room.start).getFullYear();
			if (room.milestones[0] === 'Career Coaching') {
				allmilestonesCount.career_Coaching += 1;
			}
			if (
				room.milestones[0] ===
				'Leadership and Peak performance Coaching'
			) {
				allmilestonesCount.leadership_Peak_performance += 1;
			}
			if (room.milestones[0] === 'Diversity, Equity and Inclusion') {
				allmilestonesCount.diversity_Equity_Inclusion += 1;
			}
			if (room.milestones[0] === ' Performance Reviews and Promotions') {
				allmilestonesCount.performance_Reviews_Promotions += 1;
			}
			if (room.milestones[0] === ' Career Pivots and Job Loss') {
				allmilestonesCount.career_Pivots_Job_Loss += 1;
			}
			if (room.milestones[0] === 'Fierce after 50') {
				allmilestonesCount.fierce_after_50 += 1;
			}
			if (room.milestones[0] === ' Networking') {
				allmilestonesCount.networking += 1;
			}
			if (room.milestones[0] === ' Career Search') {
				allmilestonesCount.career_Search += 1;
			}
			if (room.milestones[0] === 'Entrepreneurship') {
				allmilestonesCount.entrepreneurship += 1;
			}
			if (room.milestones[0] === 'Mentorship') {
				allmilestonesCount.mentorship += 1;
			}
			if (room.milestones[0] === 'Mental Health and Wellbeing') {
				allmilestonesCount.mental_Health_Wellbeing += 1;
			}
			if (room.milestones[0] === ' Spiritual Wellness') {
				allmilestonesCount.spiritual_Wellness += 1;
			}
			if (room.milestones[0] === 'Family and Home') {
				allmilestonesCount.family_Home += 1;
			}
			if (room.milestones[0] === 'Health and Beauty') {
				allmilestonesCount.health_Beauty += 1;
			}
			if (room.milestones[0] === 'Giving & Impact') {
				allmilestonesCount.giving_Impact += 1;
			}
			if (room.milestones[0] === 'Food & Culture') {
				allmilestonesCount.food_Culture += 1;
			}
			if (room.milestones[0] === 'The Gender Lens') {
				allmilestonesCount.gender_Lens += 1;
			}
			if (room.milestones[0] === 'Marriage & Relationships') {
				allmilestonesCount.marriage_Relationships += 1;
			}
			if (room.milestones[0] === 'Aging & Caregiving') {
				allmilestonesCount.aging_Caregiving += 1;
			}
			if (room.milestones[0] === 'Midlife & Menopause') {
				allmilestonesCount.midlife_Menopause += 1;
			}
			if (room.milestones[0] === 'Adulting') {
				allmilestonesCount.Adulting += 1;
			}
			if (room.milestones[0] === 'Family Planning & Pregnancy') {
				allmilestonesCount.family_Planning_Pregnancy += 1;
			}
			if (room.milestones[0] === ' Personal Growth') {
				allmilestonesCount.personal_Growth += 1;
			}
			if (room.milestones[0] === 'Friendship') {
				allmilestonesCount.friendship += 1;
			}
			if (room.milestones[0] === ' Breakups and Divorce') {
				allmilestonesCount.breakups_Divorce += 1;
			}
			if (room.milestones[0] === 'Family, Divorce & Education') {
				allmilestonesCount.family_Divorce_Education += 1;
			}
			if (room.milestones[0] === 'Impact & ESG Investing') {
				allmilestonesCount.impact_ESG_Investing += 1;
			}
			if (room.milestones[0] === 'Financial Independence & Investing') {
				allmilestonesCount.financial_Independence_Investing += 1;
			}
			if (room.milestones[0] === 'Secure Aging & Retirement') {
				allmilestonesCount.secure_Aging_Retirement += 1;
			}
			if (room.milestones[0] === 'Homeownership') {
				allmilestonesCount.homeownership += 1;
			}
			if (room.milestones[0] === 'Crypto & More') {
				allmilestonesCount.crypto_More += 1;
			}
			if (room.milestones[0] === 'Taxes & Insurance') {
				allmilestonesCount.taxes_Insurance += 1;
			}
			if (room.milestones[0] === 'Me, Myself & Money') {
				allmilestonesCount.me_Myself_Money += 1;
			}
			if (room.milestones[0] === ' Budgeting & Spending') {
				allmilestonesCount.budgeting_Spending += 1;
			}
			if (room.milestones[0] === 'Establishing & Managing Credit') {
				allmilestonesCount.establishing_Managing_Credit += 1;
			}
			if (room.milestones[0] === ' Managing Debt') {
				allmilestonesCount.managing_debt += 1;
			}
			return null;
		});
	}
};

export const TOTAL_AUTHORS = `
query getAuthors($limit: Float!) {
    authors(limit: $limit) {
      edges {
        node {
          ...AuthorDetails
          
        }
      }
      pageInfo {
        total
      }
    }
  }

  fragment AuthorDetails on Author {
    bio
    name
		profile
		externalID
    pillar{
    	name
    }
    links{
    	facebook
      linkedin
      website
      instagram  
    }
  }
`;

export const TotalAuthorsCount = () => {
	const [result] = useQuery({
		query: TOTAL_AUTHORS,
		variables: {
			limit: 300,
		},
	});

	const { fetching, error, data } = result;
	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;
	const AuthorsCount = data.authors.pageInfo.total;
	return <p>{AuthorsCount}</p>;
};

export const authorsPillarCounts = {
	Financial: 0,
	Professional: 0,
	Personal: 0,
};
let authorsQuery = false;
export const AuthorsPillarsCount = () => {
	const [result] = useQuery({
		query: TOTAL_AUTHORS,
		variables: {
			limit: 300,
		},
	});

	const { fetching, error, data } = result;

	if (fetching) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;
	if (authorsQuery === false) {
		authorsQuery = true;
		data.authors.edges.map((edge) => {
			if (edge.node?.pillar) {
				if (edge.node?.pillar?.name === 'Financial') {
					authorsPillarCounts.Financial += 1;
				}
				if (edge.node?.pillar?.name === 'Professional') {
					authorsPillarCounts.Professional += 1;
				}
				if (edge.node?.pillar?.name === 'Personal') {
					authorsPillarCounts.Personal += 1;
				}
			}
			return null;
		});
	}
};
