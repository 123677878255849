import { QueryHookOptions, useQuery } from '@apollo/client';
import {
	GetUserInfoQuery,
	IGetUserInfoQueryData,
	IGetUserInfoQueryVariables,
} from '../graphql/queries';

/**
 * Get user information from invite token
 * @param options
 * @returns
 */
export const useGetUserInfoQuery = (
	options?: QueryHookOptions<
		IGetUserInfoQueryData,
		IGetUserInfoQueryVariables
	>,
) =>
	useQuery<IGetUserInfoQueryData, IGetUserInfoQueryVariables>(
		GetUserInfoQuery,
		options,
	);
