/* eslint-disable */
import React from 'react';
import {
	AutocompleteArrayInput,
	BooleanInput,
	Create,
	CreateProps,
	DateTimeInput,
	NumberInput,
	ReferenceArrayInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import { matchSuggestion } from '../../helpers';
import { MilestoneRow } from './components/living-room-milestone-row';

type ILivingRoomCreateProps = CreateProps;

export const LivingRoomCreate = (props: ILivingRoomCreateProps) => (
	<Create {...props} title="Schedule Living Room">
		<SimpleForm sx={{ maxWidth: 500 }}>
			<TextInput source="title" required fullWidth />
			{/* <TextInput source="header" /> */}
			<ReferenceArrayInput
				source="milestones"
				reference="milestones"
				label="Milestones"
			>
				<AutocompleteArrayInput
					optionText={<MilestoneRow />}
					inputText={(option) => option.id}
					matchSuggestion={matchSuggestion}
					source="id"
					fullWidth
				/>
			</ReferenceArrayInput>
			<DateTimeInput source="start" required />
			<DateTimeInput source="end" required />
			<BooleanInput
				source="small"
				label="Panellist Living Room"
				fullWidth
			/>
			<BooleanInput source="recorded" />

			<NumberInput source="capacity" required min={1} />
			<TextInput source="description" required multiline fullWidth />
			<ReferenceArrayInput source="hosts" reference="users" label="Hosts">
				<AutocompleteArrayInput
					optionText="email"
					source="query"
					fullWidth
					// initialInputValue="hosts"
				/>
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);
