import * as React from 'react';
import { useState } from 'react';
import {
	Button,
	useNotify,
	useRefresh,
	SimpleForm,
	ReferenceInput,
	AutocompleteInput,
	SelectInput,
	useDataProvider,
	useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { enumToSelection } from '../../users/user.options';

const LivingRoomRegistrionRoles = ['HOST', 'PANELIST', 'ATTENDEE'];

interface IReserveSeatProps {
	resource?: string;
}

const ReserveSeat = ({ resource = 'livingRooms' }: IReserveSeatProps) => {
	const record = useRecordContext();

	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();

	const [userId, setUserId] = useState<string | null | undefined>('');
	const [role, setRole] = useState<string | null | undefined>('ATTENDEE');

	const { mutate: addRegistration, isLoading } = useMutation(
		() =>
			dataProvider.addRegistration({
				id: record?.id,
				input: {
					userId,
					role,
				},
			}),
		{
			onSuccess: () => {
				refresh();
				notify('User added to living room');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	if (record?.hasStarted) return null;
	return (
		<>
			<SimpleForm
				title="Register User"
				toolbar={<></>}
				sx={{ maxWidth: 500 }}
			>
				<Typography variant="caption">Register a User</Typography>
				<ReferenceInput
					source="users"
					reference="users"
					label="Users to reserve seats for"
				>
					<AutocompleteInput
						onChange={(event) => setUserId(event)}
						optionText="email"
						source="query"
						fullWidth
					/>
				</ReferenceInput>
				<SelectInput
					source="role"
					choices={enumToSelection(LivingRoomRegistrionRoles)}
					required
					defaultValue="ATTENDEE"
					inputProps={{
						onChange: (e) => {
							setRole((e.target as HTMLInputElement).value);
						},
					}}
					fullWidth
				/>
				<Button
					label="Reserve"
					onClick={() => addRegistration()}
					disabled={isLoading}
					variant="contained"
					size="large"
				>
					<AddIcon />
				</Button>
			</SimpleForm>
		</>
	);
};

export default ReserveSeat;
