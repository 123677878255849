import * as React from 'react';
import { Box, Card, CardActions, Typography } from '@mui/material';
import BroadcastNotificationButton from '../resources/components/AdminBroadcast';
import UserImportCsv from '../resources/components/UserImportCsv';
import DownloadAssetButton from '../resources/components/DownloadAsset';

// import publishArticleImage from './welcome_illustration.svg';

const Welcome = () => {
	return (
		<Card
			sx={{
				background: (theme) =>
					theme.palette.mode === 'dark'
						? '#535353'
						: `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

				color: '#fdf7ed',
				padding: '20px',
				marginTop: 2,
				marginBottom: '1em',
			}}
		>
			<Box flex="1">
				<Typography variant="h5" component="h2" gutterBottom>
					Welcome to the Uplevyl Admin Panel
				</Typography>
				<Box maxWidth="40em">
					<Typography variant="body1" component="p" gutterBottom>
						Use the buttons below to perform quick actions, or
						select a page from the sidebar
					</Typography>
				</Box>
				<CardActions
					sx={{
						padding: { xs: 0, xl: null },
						flexWrap: { xs: 'wrap', xl: null },
						'& button': {
							marginTop: { xs: '1em', xl: null },
							marginLeft: { xs: '0!important', xl: null },
							marginRight: { xs: '1em', xl: null },
						},
					}}
				>
					<BroadcastNotificationButton />
					<UserImportCsv />
					<DownloadAssetButton />
				</CardActions>
			</Box>
		</Card>
	);
};

export default Welcome;
