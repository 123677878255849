/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useRecordContext } from 'react-admin';

const UserRefLink = () => {
	const record = useRecordContext();
	const link = record
		? `https://${window.location.hostname}#invitee=${record.username}`
		: '';
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<label
				className="MuiFormLabel-root MuiInputLabel-root mb-1"
				style={{ fontSize: '14px' }}
			>
				<span>User&apos;s referral link</span>
			</label>
			<a
				href={link}
				target="_blank"
				rel="noreferrer"
				style={{ color: '#4f3cc9', fontSize: '0.875rem' }}
			>
				{link}
			</a>
		</div>
	);
};

export default UserRefLink;
