import React from 'react';
import { SelectInput, Edit, EditProps, SimpleForm } from 'react-admin';
import ReportTitle from './components/report.title';

type IReportEditProps = EditProps;

export const ReportEdit = (props: IReportEditProps) => (
	<Edit
		title={<ReportTitle />}
		transform={(input) => ({
			id: input.id,
			status: input.status,
		})}
		{...props}
	>
		<SimpleForm>
			<SelectInput
				source="status"
				choices={[
					{
						id: 'PENDING',
						name: 'Pending',
					},
					{
						id: 'ACTIONED',
						name: 'Actioned',
					},
					{ id: 'IGNORED', name: 'Ignored' },
				]}
			/>
		</SimpleForm>
	</Edit>
);
