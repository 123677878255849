import React from 'react';
import {
	Chart as ChartJS,
	BarElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	ArcElement,
} from 'chart.js';
import { Paper, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import './charts.css';

ChartJS.register(
	BarElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	ArcElement,
);

const card3 = {
	width: '520px',
	height: '400px',
	padding: '10px',
	margin: '10px',
};

interface PieChartProps {
	data: { label: string; value: number }[];
	width: number;
	height: number;
}

const PieChart: React.FC<PieChartProps> = ({ data, width, height }) => {
	const chartData = {
		labels: data.map((d) => d.label),
		datasets: [
			{
				data: data.map((d) => d.value),
				backgroundColor: ['#c4d8d0', '#f5e6d3', '#f5e6d3', '#f5e6d3'],
			},
		],
	};

	const handleClick = (evt: any, item: any) => {
		if (item.length > 0) {
			const label = chartData.labels[item[0].index];
			let url = '';

			switch (label) {
				case 'Active':
					url =
						'/admin/users?page=1&perPage=25&filter=%7B"active"%3Atrue%7D';
					break;
				case 'Inactive':
					url =
						'/admin/users?filter=%7B"active"%3Afalse%7D&order=desc&page=1&perPage=25&sort=created';
					break;
				default:
					break;
			}

			if (url !== '') {
				window.open(url, '_blank');
			}
		}
	};

	return (
		<Paper elevation={3} style={card3}>
			<Typography variant="h5" component="h2">
				Active and Inactive Members
			</Typography>
			<div style={{ position: 'relative' }}>
				<Doughnut
					data={chartData}
					width={width}
					height={height}
					options={{
						maintainAspectRatio: false,
						cutout: '10%',
						radius: '90%',
						plugins: {
							legend: {
								display: true,
								position: 'left',
							},
						},
						onClick: handleClick,
					}}
				/>
			</div>
		</Paper>
	);
};

export default PieChart;
