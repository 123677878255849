import { Chip } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

type TextArrayFieldProps = {
	source: number | string;
	label?: string;
	addLabel?: boolean;
};

const TextArrayField = ({ source }: TextArrayFieldProps) => {
	const record = useRecordContext();
	return (
		<div style={{ display: 'flex' }}>
			{record?.[source]?.map(
				(item: any) =>
					item !== 'undefined' && (
						<Chip
							label={item}
							key={item}
							sx={{ maxWidth: 'fit-content', margin: '2px' }}
						/>
					),
			)}
		</div>
	);
};

TextArrayField.defaultProps = { addLabel: true };

export default TextArrayField;
