import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

datadogRum.init({
	applicationId: process.env.REACT_APP_DD_APP_ID ?? '',
	clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? '',
	site: process.env.REACT_APP_DD_SITE ?? '',
	service: process.env.REACT_APP_DD_SERVICE ?? 'web-app',

	// Specify a version number to identify the deployed version of your application in Datadog
	// version: '1.0.0',
	sampleRate: 100,
	premiumSampleRate: 100,
	trackInteractions: true,
	defaultPrivacyLevel: 'allow',
});

datadogRum.startSessionReplayRecording();

(async () => {
	console.log('I am herer', process.env.REACT_APP_LD_ID);
	const LDProvider = await asyncWithLDProvider({
		clientSideID: process.env.REACT_APP_LD_ID,
	});
	ReactDOM.render(
		<LDProvider>
			<App />
		</LDProvider>,
		document.getElementById('root'),
	);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
