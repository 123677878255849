import { DocumentNode } from 'graphql';
import { gql } from 'urql';
import { IMutations } from '.';
import fragments from '../fragments';

const create: Omit<IMutations, 'polls' | 'quotes' | 'freeTexts'> = {
	users: gql`
		mutation createUser($input: AdminCreateUserInput!) {
			adminUserCreate(input: $input) {
				id
				firstName
				lastName
				email
				dob
				phone
				comment
				role
				contributor
				enterprise
			}
		}
	`,
	reports: gql`
		${fragments.reports as DocumentNode}
		mutation createReport($input: ReportCreateInput!) {
			reportCreate(input: $input) {
				...ReportDetails
			}
		}
	`,
	livingRooms: gql`
		${fragments.livingRooms as DocumentNode}
		mutation createLivingRoom($input: LivingRoomCreateInput!) {
			livingRoomCreate(input: $input) {
				...LivingRoomDetails
			}
		}
	`,
};

export default create;
