import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase.config';
import {
	IAnalyticsService,
	ScreenViewParameters,
} from './analytics-service.interface';

export class FirebaseAnalytics implements IAnalyticsService {
	constructor() {
		console.log('Firebase initialised');
	}

	async logEvent(
		name: string,
		params?: { [key: string]: any } | undefined,
	): Promise<void> {
		return logEvent(analytics, name, params);
	}

	async logScreenView(params: ScreenViewParameters): Promise<void> {
		return logEvent(analytics, 'screen_view' as string, params);
	}
}
