import React from 'react';
import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

type FormattedTextArrayFieldProps = {
    source: string;
    label?: string;
    separator?: 'comma' | 'newline';
};

const FormattedTextArrayField = ({
    source,
    label,
    separator = 'comma',
}: FormattedTextArrayFieldProps) => {
    const record = useRecordContext();

    console.log(record)
    if (!record || !record.additionalFormInfo[source]) {
        return <Typography>No data available</Typography>;
    }


    const dataArray = record.additionalFormInfo[source];
    const formattedText =
        separator === 'newline'
            ? dataArray.join('\n')
            : dataArray.join(', ');

    return (
        <div style={{ whiteSpace: separator === 'newline' ? 'pre-line' : 'normal' }}>
            <Typography variant="subtitle2" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="body1">{formattedText}</Typography>
        </div>
    );
};

export default FormattedTextArrayField;
