import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface DropDownProps {
	data: { label: string; value: string }[];
	width: number;
	label: string;
	size: string;
}

const DropDown: React.FC<DropDownProps> = ({ data, label, width }) => {
	const [value, setValue] = React.useState('');

	const handleChange = (event: SelectChangeEvent) => {
		setValue(event.target.value);
	};

	return (
		<FormControl
			sx={{ m: 1, minWidth: width }}
			size="medium"
			style={{
				marginTop: '20px',
			}}
		>
			<InputLabel id="demo-select-small">{label}</InputLabel>
			<Select
				labelId="demo-select-small"
				id="demo-select-small"
				value={value}
				label={label}
				onChange={handleChange}
			>
				{data.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default DropDown;
