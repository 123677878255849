import React from 'react';

type HeaderProps = {
	title: string;
	smallerText?: boolean;
};

const Header = ({ title, smallerText }: HeaderProps) => {
	return (
		<>
			<h1
				className={`text-${
					smallerText ? '3' : '4'
				}xl mb-2 font-bold font-header text-left`}
				style={{ fontSize: '2.2rem' }}
			>
				{title}
			</h1>
			<hr className="border border-oldYellow w-full mb-3" />
		</>
	);
};

export default Header;
