import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import {
	ArrayInput,
	Button,
	DateTimeInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	Title,
	useDataProvider,
	useNotify,
	useRefresh,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ColorDropDownInput from '../../components/ColorDropdownInput';

type PollCreateInput = {
	title?: string;
	question?: string;
	backgroundColour?: string;
	choices?: string[];
	start?: Date;
	end?: Date;
};

const PollCreatePage = () => {
	const [variables, setVariables] = useState<PollCreateInput>({});

	const refresh = useRefresh();
	const notify = useNotify();
	const navigate = useNavigate();
	const dataProvider = useDataProvider();

	const { mutate: pollCreate } = useMutation<any, any, any>(
		(input) => dataProvider.pollCreate({ input }),
		{
			// undoable: true,
			onSuccess: (result: any) => {
				refresh();
				if (result?.data?.id) {
					navigate(`/admin/polls/${result?.data?.id}/show`);
				}
				notify('Element Created');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const handleSubmit = async (value: FieldValues) =>
		pollCreate({
			...value,
			choices: (value.choices as { choice: string }[]).map(
				(choice) => choice.choice as string,
			),
		});

	return (
		<Card sx={{ mt: 2 }}>
			<Title title="Create Poll" />
			<CardContent>
				<SimpleForm onSubmit={handleSubmit}>
					<TextInput
						required
						helperText="Poll's Title (e.g. `Poll of the Week`)"
						source="title"
					/>
					<TextInput
						required
						helperText="Poll's Question (e.g. `What do you like most about Uplevyl?`)"
						source="question"
					/>
					<ColorDropDownInput
						helperText="Background Colour of the Post (e.g. Finance Green)"
						source="backgroundColour"
						isRequired
						label="Background Color"
					/>

					<DateTimeInput
						required
						helperText="Poll's Scheduled Start Time"
						source="start"
						label="Start Time"
					/>

					<DateTimeInput
						required
						helperText="Poll's Scheduled End Time"
						source="end"
						label="End Time"
					/>

					<ArrayInput source="choices" label="Choices">
						<SimpleFormIterator
							className="mt-5 w-1/4"
							removeButton={
								<Button
									// Again another nightmarey function just to get the index of the row that's being removed, as we're not in the standard react admin resource contest
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();

										let button = (e.target as any)
											.parentNode;

										if (
											button.classList.contains(
												'MuiButton-label',
											)
										)
											// Nested element inside button was clicked, go to button parent
											button = button.parentNode;

										const iteratorAction =
											button.parentNode;

										// tried joining array as dont know the value of the classList to use exact indexing
										const inputClasses =
											iteratorAction.previousSibling
												.lastChild.classList.value;

										if (!inputClasses) return;

										// Different regex to below
										const regexResult =
											/(.*choices\[)([0-9]*)(\])(.*)?/gm.exec(
												inputClasses,
											);

										if (
											!regexResult ||
											regexResult.length < 2
										)
											return;

										const removeIndex = parseInt(
											regexResult[2],
											10,
										);

										setVariables({
											...variables,
											choices: (
												variables.choices ?? []
											).filter(
												(_choice, index) =>
													index !== removeIndex,
											),
										});
									}}
									color="secondary"
									label="Remove"
									style={{
										color: 'rgba(0, 0, 0, 0.87)',
										fontFamily:
											'"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif',
										fontWeight: 500,
										lineHeight: 1.75,
										textTransform: 'uppercase',
										marginTop: 11,
									}}
								/>
							}
						>
							<TextInput
								helperText="Poll Choices"
								required
								source="choice"
								label="Choice"
							/>
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleForm>
			</CardContent>
		</Card>
	);
};

export default PollCreatePage;
