import React from 'react';
import {
	Edit,
	EditProps,
	DateTimeInput,
	TextInput,
	SimpleForm,
	ArrayInput,
	SimpleFormIterator,
} from 'react-admin';
import ColorDropDownInput from '../components/ColorDropdownInput';

export const PollEdit = (props: EditProps) => {
	const transform = (data: any) => {
		const newData = data;
		newData.start = newData.schedule.start;
		newData.end = newData.schedule.end;
		delete newData.results;
		delete newData.schedule;
		return newData;
	};

	return (
		<Edit {...props} transform={transform}>
			<SimpleForm>
				<TextInput source="title" required helperText="Poll's Title" />
				<TextInput
					source="question"
					required
					helperText="Poll's Question"
				/>
				<ColorDropDownInput
					isRequired
					helperText="Background Colour of the Post"
					source="backgroundColour"
					label="Background Color"
				/>
				<DateTimeInput
					label="Start"
					source="schedule.start"
					required
					helperText="Poll's Scheduled Start Time"
				/>
				<DateTimeInput
					label="End"
					source="schedule.end"
					required
					helperText="Poll's Scheduled End Time"
				/>
				<ArrayInput source="choices">
					<SimpleFormIterator>
						<TextInput label="Choice" source="" />
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Edit>
	);
};
