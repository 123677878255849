import React from 'react';
import { Box, Tab, Container, Typography } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import PastLivingRoomData from '../past-living-room';
import CurrentLivingRoomData from '../current-living-room';
import { PillarsGroupedChart } from '../charts/pillarsgroupedChart';
import { SeesionsMonthlyChart } from '../charts/compareChart';
import { AllChartMilestone } from '../charts/milestonesTreeChart';

const card2 = {
	width: '450px',
	height: '400px',
	padding: '10px',
	margin: '10px',
};
const card3 = {
	width: '450px',
	height: '400px',
	padding: '10px',
	margin: '10px',
};
const card4 = {
	width: '1100px',
	height: '700px',
	padding: '0px',
	display: 'flex',
	margin: '10px',
	backgroundColor: '#edfce8',
	fontFamily: 'Montserrat',
};

export const AttendeesSessionContent = () => {
	const [value, setValue] = React.useState('1');
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	return (
		<>
			<Container
				style={{
					display: 'flex',
				}}
			>
				<Container
					style={{
						width: '250px',
						height: '400px',
						padding: '10px',
						margin: '10px',
						overflowY: 'auto',
						overflowX: 'auto',
					}}
				>
					<Typography variant="h5" component="h2">
						Living Rooms
					</Typography>
					<TabContext value={value}>
						<TabList
							onChange={handleChange}
							aria-label="living rooms"
						>
							<Tab label="Current" value="1" />
							<Tab label="Past" value="2" />
						</TabList>

						<TabPanel value="1">
							<CurrentLivingRoomData />
						</TabPanel>
						<TabPanel value="2">
							<PastLivingRoomData />
						</TabPanel>
					</TabContext>
				</Container>
				<Container style={card2}>
					<Typography variant="h5" component="h2">
						Pillar wise Sessions
					</Typography>
					<PillarsGroupedChart />
				</Container>
				<Container style={card3}>
					<Typography variant="h5" component="h2">
						Most Sessions Happened in 2021
					</Typography>
					<SeesionsMonthlyChart />
				</Container>
			</Container>
			<Box style={card4}>
				<AllChartMilestone />
			</Box>
		</>
	);
};
