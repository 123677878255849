import gql from 'graphql-tag';

export const SubmitSupportRequestMutation = gql`
	mutation createSupport($input: SupportCreateInput!) {
		supportCreate(input: $input) {
			id
			body
			subject
			email
			created
		}
	}
`;

export interface ISubmitSupportRequestMutationVariables {
	input: {
		body: string;
		subject: string;
		email: string;
		name?: string;
	};
}

export interface ISubmitSupportRequestMutationData {
	supportCreate: {
		id: string;
		body: string;
		subject: string;
		email: string;
		name?: string;
		created: Date;
	};
}
