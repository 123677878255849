import { RaRecord as Record } from 'ra-core';
import { IFormatter } from './formatter.interface';

export class BaseFormatter implements IFormatter {
	/**
	 * Get One item formatter
	 * @param data Data
	 * @returns Fomatted single item
	 */
	public one<D extends Record>(data: D): { data: Record } {
		return {
			data,
		};
	}

	/**
	 * Get List item formatter
	 * @param data Data
	 * @returns Fomatted list of items
	 */
	public list<T extends Record>(_data: T): { data: Record[]; total: number } {
		const data = _data.edges.map((edge: { node: any }) => edge.node);

		return {
			data,
			total: _data ? _data.pageInfo.total : 0,
		};
	}

	/**
	 * Get Many item formatter
	 * @param _data Data
	 * @returns Fomatted many items
	 */
	public many<T extends Record>(_data: T): { data: Record[] } {
		const { data } = this.list(_data);
		return { data };
	}

	/**
	 * Create item formatter
	 * @param data Input data
	 * @returns Output data
	 */
	public create<D extends Record, O extends Record>(data: D): O {
		return data as unknown as O;
	}

	/**
	 * Update item formatter
	 * @param data Input data
	 * @returns Output data
	 */
	public update<D extends Record, O extends Record>(data: D): O {
		return data as unknown as O;
	}

	/**
	 * Delete item formatter
	 * @param data Input data
	 * @returns Output data
	 */
	public delete<D extends Record, O extends Record>(data: D): O {
		return data as unknown as O;
	}
}
