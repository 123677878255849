import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import {
	ArrayInput,
	BooleanInput,
	DateInput,
	DateTimeInput,
	Edit,
	EditProps,
	FormTab,
	ImageField,
	ImageInput,
	NumberInput,
	PasswordInput,
	SelectInput,
	SimpleFormIterator,
	TabbedForm,
	TextInput,
} from 'react-admin';
import { countryCodes } from '../countryCodes';

import UserTitle from './components/user.title';
import {
	enumToSelection,
	UserCompanyFunction,
	UserCompanyIndustry,
	UserCompanyRole,
	UserCompanyType,
	UserEducation,
	UserEmploymentStatus,
	UserEthnicity,
	UserGeneration,
	UserPet,
	UserMobileOSOptions,
	UserRelationship,
	UserOccupation,
	UserOccupationType,
} from './user.options';
import { useUserAvatarSignedUrlMutation } from '../../public/Avatar/UserAvatarSignedUrlMutation';
import { uploadFile } from '../../public/Avatar/upload';

const useStyles = makeStyles({
	section: { paddingTop: '1rem', paddingBottom: '1rem' },
});

type IUserEditProps = EditProps;

export const UserEdit = (props: IUserEditProps) => {
	const classes = useStyles();
	const [signedUrl, setSignedUrl] = useState(null);
	const [, setError] = useState('');

	const [getSignedUrl, signedUrlData] = useUserAvatarSignedUrlMutation();
	let newAvatarUrl = signedUrlData?.data?.userAvatarSignedUrl.url;

	const handleAvatarChange = async (file: File) => {
		// Error Handling
		if (file.size && file.size >= 1024 * 1024 * 5 /* 5MB */) {
			setError(
				'This image is too large. It should be no larger than 5MB.',
			);
			throw new Error('Image too large');
		}
		if (
			file.type !== 'image/jpg' &&
			file.type !== 'image/jpeg' &&
			file.type !== 'image/png'
		) {
			setError(
				'This app only accepts JPEG or PNG images, please choose the correct file type and try again.',
			);
			throw new Error('Invalid file type');
		}

		// Start Uploading
		try {
			const signedUrlResponse = await getSignedUrl({
				variables: {
					fileType: file.type ?? '',
				},
			});
			await uploadFile(
				file,
				signedUrlResponse?.data?.userAvatarSignedUrl.signedUrl ?? '',
			);
			newAvatarUrl = signedUrlResponse?.data?.userAvatarSignedUrl.url;
			setSignedUrl(newAvatarUrl);
		} catch (err) {
			setError('An error occurred while uploading the image.');
			throw err;
		}
	};
	const transform = (data: any) => {
		const newData = data;
		newData.howCanUplevylElevateYou =
			newData.howCanUplevylElevateYou &&
			newData.howCanUplevylElevateYou.length > 0
				? newData.howCanUplevylElevateYou.filter(
						(reason: string) => reason && reason.length > 0,
				  )
				: [];

		newData.howCanUplevylHelpYou =
			newData.howCanUplevylHelpYou &&
			newData.howCanUplevylHelpYou.length > 0
				? newData.howCanUplevylHelpYou.filter(
						(reason: string) => reason && reason.length > 0,
				  )
				: [];

		newData.howCanYouHelpUplevyl =
			newData.howCanYouHelpUplevyl &&
			newData.howCanYouHelpUplevyl.length > 0
				? newData.howCanYouHelpUplevyl.filter(
						(reason: string) => reason && reason.length > 0,
				  )
				: [];

		newData.ageOfChildren =
			newData.ageOfChildren && newData.ageOfChildren.length > 0
				? newData.ageOfChildren
						.map((age: { age: string }) => age.age)
						.filter((age: string) => age && age.length > 0)
				: [];
		newData.avatar = newAvatarUrl;
		delete newData.referred;
		delete newData.hasComment;
		delete newData.subscriptionActivatedAt;
		delete newData.accountActivatedAt;
		delete newData.focus;
		delete newData.firstLogin;
		delete newData.lastActivity;
		delete newData.username;
		return newData;
	};

	return (
		<Edit title={<UserTitle />} {...props} transform={transform}>
			<TabbedForm>
				<FormTab label="Details">
					<TextInput source="id" disabled />
					<TextInput source="firstName" />
					<TextInput source="lastName" />
					<TextInput source="email" />
					<DateInput source="dob" />
					<div style={{ width: '950px' }}>
						<SelectInput
							source="countryCode"
							choices={countryCodes}
							style={{ marginRight: 3 }}
						/>
						<TextInput id="phone" source="phone" type="phone" />
					</div>
					<TextInput source="comment" />
					<PasswordInput source="password" />
					<BooleanInput source="contributor" />
					<BooleanInput source="enterprise" />
					<SelectInput
						source="role"
						choices={[
							{ id: 'CUSTOMER', name: 'Customer' },
							{ id: 'STAFF', name: 'Staff' },
							{ id: 'ADMIN', name: 'Admin' },
							{ id: 'MANAGER', name: 'Manager' },
						]}
					/>
				</FormTab>
				<FormTab label="Account Controls">
					<div className={classes.section}>
						<Typography variant="body2">Lock Account</Typography>
						<BooleanInput
							source="locked"
							helperText="Stops the user from logging in"
						/>
					</div>

					<div className={classes.section}>
						<Typography variant="body2">Login Timeout</Typography>
						<DateTimeInput
							source="timeout"
							helperText="Date user is allowed to login"
						/>
					</div>
				</FormTab>
				<FormTab label="Personal">
					<TextInput source="bio" label="Bio" multiline />
					<div style={{ width: '400px' }}>
						<ImageInput
							source="avatar"
							accept="image/*"
							onChange={handleAvatarChange}
							multiple={false}
						/>
						{signedUrl ? (
							<img src={signedUrl} alt="Selected Avatar" />
						) : (
							<ImageField
								source="avatar"
								label="Avatar"
								emptyText="–"
							/>
						)}
					</div>
					<SelectInput
						source="mobileOS"
						choices={[
							{
								id: UserMobileOSOptions[0],
								name: 'iOS',
							},
							{
								id: UserMobileOSOptions[1],
								name: 'Android',
							},
							{
								id: UserMobileOSOptions[2],
								name: 'Other',
							},
						]}
						label="Mobile Operating System"
					/>
					<TextInput source="city" label="City/Town" />
					<TextInput source="state" label="State" />
					<TextInput source="country" label="Country" />
					<SelectInput
						source="relationship"
						choices={enumToSelection(UserRelationship)}
					/>
					<NumberInput source="children" />
					<ArrayInput source="ageOfChildren" label="Age Of Children">
						<SimpleFormIterator className="mt-5">
							<TextInput source="age" label="Age" />
						</SimpleFormIterator>
					</ArrayInput>
					<TextInput source="income" />
					<SelectInput
						source="ethnicity"
						choices={enumToSelection(UserEthnicity)}
					/>
					<SelectInput
						source="generation"
						choices={enumToSelection(UserGeneration)}
					/>
					<SelectInput
						source="pet"
						label="Pet"
						choices={enumToSelection(UserPet)}
					/>
				</FormTab>
				<FormTab label="Professional">
					<SelectInput
						source="employment"
						label="Employment Status"
						choices={enumToSelection(UserEmploymentStatus)}
					/>
					<TextInput source="companyName" label="Organisation Name" />
					<TextInput source="companySize" label="Organisation Size" />
					<SelectInput
						source="companyType"
						label="Type of Organisation"
						choices={enumToSelection(UserCompanyType)}
					/>
					<SelectInput
						source="companyIndustry"
						label="Industry"
						choices={enumToSelection(UserCompanyIndustry)}
					/>
					<SelectInput
						source="companyRole"
						label="Role"
						choices={enumToSelection(UserCompanyRole)}
					/>
					<SelectInput
						source="occupation"
						label="Occupation (Role)"
						choices={enumToSelection(UserOccupation)}
					/>
					<SelectInput
						source="occupationType"
						label="Occupation Type (Industry)"
						choices={enumToSelection(UserOccupationType)}
					/>
					<SelectInput
						source="companyFunction"
						label="Function"
						choices={enumToSelection(UserCompanyFunction)}
					/>
					<SelectInput
						source="education"
						label="Education"
						choices={enumToSelection(UserEducation)}
					/>
					<TextInput
						source="profiles"
						label="Workplace / LinkedIn Profile"
					/>
				</FormTab>
				<FormTab label="Platform">
					<ArrayInput
						source="howCanUplevylElevateYou"
						label="How can Uplevyl help you elevate your life?"
					>
						<SimpleFormIterator className="mt-5">
							<TextInput source="" label="Reason" />
						</SimpleFormIterator>
					</ArrayInput>
					<ArrayInput
						source="howCanUplevylHelpYou"
						label="How can Uplevyl help you?"
					>
						<SimpleFormIterator className="mt-5">
							<TextInput source="" label="Reason" />
						</SimpleFormIterator>
					</ArrayInput>
					<ArrayInput
						source="howCanYouHelpUplevyl"
						label="How can you help other Uplevylers?"
					>
						<SimpleFormIterator className="mt-5">
							<TextInput source="" label="Reason" />
						</SimpleFormIterator>
					</ArrayInput>
					<NumberInput source="professionalGrowthImportance" />
					<NumberInput source="personalGrowthImportance" />
					<NumberInput source="financialGrowthImportance" />
					<NumberInput source="activeParticipationImportance" />
					<NumberInput source="womenSupportImportance" />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
