import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	ResetPasswordMutation,
	IResetPasswordMutationData,
	IResetPasswordMutationVariables,
} from '../graphql/mutations/resetPassword';

/**
 * Reset password via code sent to user's phone
 * @param options
 * @returns
 */
export const useResetPasswordMutation = (
	options?: MutationHookOptions<
		IResetPasswordMutationData,
		IResetPasswordMutationVariables
	>,
) =>
	useMutation<IResetPasswordMutationData, IResetPasswordMutationVariables>(
		ResetPasswordMutation,
		options,
	);
