/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable no-loop-func */
import {
	ApolloClient as _ApolloClient,
	ApolloLink,
	createHttpLink,
	DocumentNode,
	fromPromise,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import auth from '../mutations/auth';
import { TokenService } from '../token.service';
import { IClient } from './client.interface';

const STATUS = 'uplevyl:authStatus';

type AuthenticationStatus = 'authenticated' | 'pending';
export class ApolloClient implements IClient {
	private client: _ApolloClient<NormalizedCacheObject>;

	private isRefreshing = false;

	private pendingRequests: any[] = [];

	constructor(
		private baseURL: string,
		private readonly tokenService: TokenService,
	) {
		this.client = this.createClient();
	}

	getClient<_ApolloClient>(): _ApolloClient {
		return this.client as unknown as _ApolloClient;
	}

	async mutate<D = Record<string, any>, V = Record<string, any>>(
		query: DocumentNode,
		variables?: V,
	): Promise<D | undefined> {
		const response = await this.client.mutate<D, V>({
			mutation: query,
			variables,
		});

		if (response.errors && response.errors.length > 0)
			throw response.errors[0];

		return response.data ?? undefined;
	}

	async query<D = Record<string, any>, V = Record<string, any>>(
		query: DocumentNode,
		variables?: V,
	): Promise<D | undefined> {
		const response = await this.client.query<D, V>({
			query,
			variables,
		});

		if (response.errors && response.errors.length > 0)
			throw response.errors[0];

		return response.data ?? undefined;
	}

	login(token: string): void {
		this.tokenService.token = token;
	}

	logout(): void {
		this.tokenService.removeToken();
	}

	/**
	 * Is the user logged in (or token refresh in flight)
	 * @description Can be edited by user as in LS
	 */
	public isLoggedInOrPending() {
		const status = localStorage.getItem(
			STATUS,
		) as AuthenticationStatus | null;
		return status === 'authenticated' || status === 'pending';
	}

	private setStatus(status: AuthenticationStatus | null) {
		if (!status) return localStorage.removeItem(STATUS);
		localStorage.setItem(STATUS, status);
	}

	/**
	 * Refresh access token using refresh token
	 */
	public async refreshTokens(): Promise<string> {
		this.setStatus('pending');
		const response = await this.client.mutate({
			mutation: auth.refresh,
			fetchPolicy: 'no-cache',
		});
		if (response.data?.refresh?.accessToken) {
			this.setStatus('authenticated');
			return response.data.refresh.accessToken;
		}
		this.setStatus(null);
		throw new Error();
	}

	/**
	 * Create Apollo client
	 */
	private createClient = () => {
		const httpLink = createHttpLink({
			uri: this.baseURL,
			credentials: 'include',
		});
		const authLink = this.createContext();
		const errorLink = this.createErrorLink();
		return new _ApolloClient({
			link: ApolloLink.from([authLink, errorLink, httpLink]),
			cache: new InMemoryCache(),
			credentials: 'include',
			defaultOptions: {
				watchQuery: {
					fetchPolicy: 'no-cache',
				},
				query: {
					fetchPolicy: 'no-cache',
				},
			},
		});
	};

	private createContext() {
		return setContext(async (_, { headers }) => {
			const accessToken = this.tokenService.token;
			return {
				headers: {
					...headers,
					'X-Frame-Options': 'Deny',
					'X-XSS-Protection': 1,
					'X-Content-Type-Options': 'nosniff',
					'Referrer-Policy': 'same-origin',
					authorization: accessToken ? `Bearer ${accessToken}` : '',
				},
			};
		});
	}

	private createErrorLink() {
		return onError(({ graphQLErrors, operation, forward }) => {
			console.log('Errors:', graphQLErrors);
			if (graphQLErrors) {
				for (const err of graphQLErrors) {
					switch (err.extensions?.code) {
						case 'UNAUTHENTICATED':
							let forward$;

							console.log('Unauthenticated, refreshing');

							if (!this.isRefreshing) {
								this.isRefreshing = true;
								forward$ = fromPromise(
									this.refreshTokens()
										.then((accessToken: string) => {
											// Store the new tokens for your auth link
											this.resolvePendingRequests();
											this.tokenService.token =
												accessToken;
											return accessToken;
										})
										.catch((error) => {
											this.pendingRequests = [];

											// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
											this.tokenService.removeToken();
										})
										.finally(() => {
											this.isRefreshing = false;
										}),
								).filter((value) => Boolean(value));
							} else {
								// Will only emit once the Promise is resolved
								forward$ = fromPromise(
									new Promise<void>((resolve) => {
										this.pendingRequests.push(() =>
											resolve(),
										);
									}),
								);
							}
							forward$?.flatMap((accessToken) => {
								const oldHeaders =
									operation.getContext().headers;
								// modify the operation context with a new token
								operation.setContext({
									headers: {
										...oldHeaders,
										authorization: `Bearer ${accessToken}`,
									},
								});

								// retry the request, returning the new observable
								return forward(operation);
							});
					}
				}
			}
		});
	}

	private resolvePendingRequests() {
		this.pendingRequests.map((callback) => callback());
		this.pendingRequests = [];
	}
}
