import { gql } from '@apollo/client';
import { IQueries } from '.';
import fragments from '../fragments';

const one: IQueries = {
	// Split complex queries up into multiple
	users: [
		gql`
			${fragments.users}
			query getUser($id: ID!) {
				user(id: $id) {
					...UserDetails
				}
			}
		`,
		gql`
			query getUserInvitedBy($id: ID!) {
				user(id: $id) {
					id
					invitedBy {
						id
						email
						firstName
						lastName
					}
				}
			}
		`,
		gql`
			query getUserRegistrations($id: ID!) {
				user(id: $id) {
					id
					registrations(limit: 250) {
						edges {
							node {
								livingRoom {
									id
									start
								}
								user {
									id
								}
								role
							}
						}
					}
				}
			}
		`,
		gql`
			query getUserAuditLog($id: ID!) {
				user(id: $id) {
					id
					auditLog(limit: 20) {
						edges {
							node {
								id
								updated
								previousUserDetails {
									id
									firstName
									lastName
								}
								updatedUserDetails {
									firstName
									lastName
									email
									bio
									dob
									phone
									comment
									role
									address
									state
									locked
									active
									subscription
									subscriptionActivatedAt
									accountActivatedAt
									created
									firstLogin
									updated
									mobileOS
									relationship
									children
									ageOfChildren
									contributor
									enterprise
									income
									clickedActivationLink
									firstLogin
									ethnicity
									generation
									pet
									employment
									companyName
									companySize
									companyType
									companyIndustry
									companyRole
									companyFunction
									education
									profiles
									howCanUplevylHelpYou
									howCanUplevylElevateYou
									howCanYouHelpUplevyl
									professionalGrowthImportance
									personalGrowthImportance
									financialGrowthImportance
									activeParticipationImportance
									womenSupportImportance
								}
							}
						}
					}
				}
			}
		`,
	],
	livingRooms: gql`
		${fragments.livingRooms}
		query getLivingRoom($id: ID!) {
			livingRoom(id: $id) {
				...LivingRoomDetails
			}
		}
	`,
	milestones: gql`
		${fragments.milestones}
		query getMilestone($name: String, $pillar: String) {
			milestones(name: $name, pillar: $pillar) {
				...MilestoneDetails
			}
		}
	`,
	reports: gql`
		${fragments.reports}
		query getReport($id: ID!) {
			report(id: $id) {
				...ReportDetails
			}
		}
	`,
	polls: gql`
		${fragments.polls}
		query getPoll($id: ID!) {
			poll(id: $id) {
				...PollDetails
			}
		}
	`,
	logs: gql`
		${fragments.logs}
		query getLog($id: ID!) {
			log(id: $id) {
				${fragments.logs}
			}
		}
	`,
	freeTexts: gql`
		${fragments.freeTexts}
		query getFreeText($id: ID!) {
			freeText(id: $id) {
				...FreeTextDetails
			}
		}
	`,
	quotes: gql`
		${fragments.quotes}
		query getQuote($id: ID!) {
			quote(id: $id) {
				...QuoteDetails
			}
		}
	`,
	schedules: gql`
		query getSchedule($id: ID!) {
			schedule(id: $id) {
				id
				type
				entityID
			}
		}
	`,
};
export default one;
