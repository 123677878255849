import React, { useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { getPrettyErrorMessage } from '../../../helpers';
import { useResetPasswordMutation } from './hooks/useResetPassword';

interface EnterPasswordProps {
	verification: string | undefined;
	phone: string | undefined;
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnterPassword = ({
	verification,
	phone,
	setSuccess,
}: EnterPasswordProps) => {
	const [resetPassword, { loading }] = useResetPasswordMutation();

	const [password, setPassword] = useState<string>('');

	const [confirmPassword, setConfirmPassword] = useState<string>('');

	const [errorMessage, setErrorMessage] = useState<string>();

	const handleSubmit = async () => {
		if (!verification || !phone) return;
		if (!password || !confirmPassword)
			return setErrorMessage('Please fill in both password fields');
		if (password.length < 8 || confirmPassword.length < 8)
			return setErrorMessage(
				'Passwords should be at least 8 characters & contain a mix of letters (uppercase and lowercase), numbers, and symbols',
			);
		if (password !== confirmPassword)
			return setErrorMessage('Passwords do not match');

		try {
			const { errors, data } = await resetPassword({
				variables: {
					phone,
					verification,
					password,
				},
			});

			if (errors?.[0]) return setErrorMessage(errors[0].message);

			if (data?.resetPassword?.accessToken) {
				setErrorMessage(undefined);
				setSuccess(true);
			}
		} catch (err) {
			if (err?.message)
				setErrorMessage(getPrettyErrorMessage(err.message));
		}
	};

	return (
		<>
			<p className="font-light mt-2">Please enter your new password.</p>
			<form
				className="flex flex-col w-full mt-10"
				onSubmit={(e) => {
					e.preventDefault();
					return handleSubmit();
				}}
			>
				<Input
					label="New Password"
					type="password"
					className="mb-4 opacity-75"
					disabled={loading ?? false}
					value={password}
					placeholder="Pick your new password"
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Input
					label="Confirm New Password"
					type="password"
					className="mb-4 opacity-75"
					disabled={loading ?? false}
					value={confirmPassword}
					placeholder="Retype your new password"
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>

				<p className="text-sm text-red-400 mb-4">{errorMessage}</p>

				<Button type="submit" value="submit" disabled={loading}>
					{loading ? 'Resetting...' : 'Reset Password'}
				</Button>
			</form>
		</>
	);
};

export default EnterPassword;
