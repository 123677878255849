import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	VerifyCodeMutation,
	IVerifyCodeMutationData,
	IVerifyCodeMutationVariables,
} from '../graphql/mutations/verifyCode';

/**
 * Verify code for resetting password
 * @param options
 * @returns
 */
export const useVerifyCodeMutation = (
	options?: MutationHookOptions<
		IVerifyCodeMutationData,
		IVerifyCodeMutationVariables
	>,
) =>
	useMutation<IVerifyCodeMutationData, IVerifyCodeMutationVariables>(
		VerifyCodeMutation,
		options,
	);
