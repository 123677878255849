import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { IAnalyticsService } from '../../../analytics/analytics-service.interface';
import { FirebaseAnalytics } from '../../../analytics/firebase-analytics.service';

export const useAnalyticsService = () => {
	const [analyticsService, setAnalyticsService] =
		useState<IAnalyticsService | null>(null);

	const flags = useFlags();

	useEffect(() => {
		if (flags.extendedAnalyticsEvents)
			return setAnalyticsService(new FirebaseAnalytics());
	}, [flags]);

	return analyticsService;
};
