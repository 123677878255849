const ACCESS_TOKEN = 'com.uplevyl.accessToken';

export class TokenService {
	private _accessToken: string | undefined;

	get token() {
		if (this._accessToken) return this._accessToken;

		this._accessToken = localStorage.getItem(ACCESS_TOKEN) ?? undefined;

		return this._accessToken;
	}

	set token(token: string | undefined) {
		if (token === undefined) {
			this.removeToken();
			return;
		}

		localStorage.setItem(ACCESS_TOKEN, token);
		this._accessToken = token;
	}

	/**
	 * Clear token
	 */
	public removeToken() {
		this._accessToken = undefined;
		localStorage.removeItem(ACCESS_TOKEN);
	}
}
