import { MutationHookOptions, gql, useMutation } from '@apollo/client';

export const UserAvatarSignedUrlMutation = gql`
	mutation UserAvatarSignedUrl($fileType: String!) {
		userAvatarSignedUrl(fileType: $fileType) {
			signedUrl
			url
		}
	}
`;

export interface IUserAvatarSignedUrlVariables {
	fileType: string;
}
export interface IUserAvatarSignedUrlData {
	userAvatarSignedUrl: {
		url;
		signedUrl;
	};
}

export const useUserAvatarSignedUrlMutation = (
	options?: MutationHookOptions<
		IUserAvatarSignedUrlData,
		IUserAvatarSignedUrlVariables
	>,
) =>
	useMutation<IUserAvatarSignedUrlData, IUserAvatarSignedUrlVariables>(
		UserAvatarSignedUrlMutation,
		options,
	);
