import React from 'react';
import {
	Chart as ChartJS,
	BarElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { pillarCounts, PillarsCount } from '../sessionsQuery';

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

export const PillarsGroupedChart = () => {
	PillarsCount();
	const info = {
		labels: ['Professional', 'Personal', 'Financial'],
		datasets: [
			{
				label: '2021',
				backgroundColor: 'rgba(75,192,192,0.2)',
				borderColor: 'rgba(75,192,192,1)',
				borderWidth: 1,
				data: [
					pillarCounts.Professional2021,
					pillarCounts.Personal2021,
					pillarCounts.Financial2021,
				],
			},
			{
				label: '2022',
				backgroundColor: 'rgba(58, 110, 199, 0.2)',
				borderColor: 'rgba(58, 110, 199, 1)',
				borderWidth: 1,
				data: [
					pillarCounts.Professional2022,
					pillarCounts.Personal2022,
					pillarCounts.Financial2022,
				],
			},
			{
				label: '2023',
				backgroundColor: 'rgba(246, 122, 38, 0.2)',
				borderColor: 'rgba(246, 122, 38, 1)',
				borderWidth: 1,
				data: [
					pillarCounts.Professional2023,
					pillarCounts.Personal2023,
					pillarCounts.Financial2023,
				],
			},
		],
	};
	return (
		<div className="App">
			<Bar
				data={info}
				width={360}
				height={320}
				options={{
					maintainAspectRatio: false,
				}}
			/>
		</div>
	);
};
