import { gql } from '@apollo/client';

export const RequestCodeMutation = gql`
	mutation requestCode($phone: String!) {
		requestCode(phone: $phone)
	}
`;

export interface IRequestCodeMutationVariables {
	phone: string;
}

export interface IRequestCodeMutationData {
	requestCode: boolean;
}
