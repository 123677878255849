import React from 'react';
import {
	Edit,
	EditProps,
	DateTimeInput,
	TextInput,
	SimpleForm,
	BooleanInput,
} from 'react-admin';
import ColorDropDownInput from '../components/ColorDropdownInput';

export const QuoteEdit = (props: EditProps) => {
	const transform = (data: any) => {
		const newData = data;
		newData.start = newData.schedule.start;
		newData.end = newData.schedule.end;
		delete newData.schedule;
		return newData;
	};

	return (
		<Edit {...props} transform={transform}>
			<SimpleForm>
				<TextInput source="title" required helperText="Quote's Title" />
				<TextInput source="quote" required helperText="Quote" />
				<TextInput
					source="author"
					required
					helperText="Quote's Author"
				/>
				<ColorDropDownInput
					helperText="Background Colour of the Post"
					source="backgroundColour"
					label="Background Color"
				/>

				<BooleanInput
					source="showQuotationMark"
					label="Show Quotation Marks"
				/>
				<DateTimeInput
					label="Start"
					source="schedule.start"
					required
					helperText="Quote's Scheduled Start Time"
				/>
				<DateTimeInput
					label="End"
					source="schedule.end"
					required
					helperText="Quote's Scheduled End Time"
				/>
			</SimpleForm>
		</Edit>
	);
};
