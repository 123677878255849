import { gql } from '@apollo/client';

export const GetSupportRequestQuery = gql`
	query supportReply($id: ID!) {
		support(id: $id) {
			id
			name
			body
			subject
			email
			created
			replies {
				created
				body
				staff {
					name
				}
			}
		}
	}
`;

export interface IGetSupportRequestQueryVariables {
	id: string;
}

export interface ISupportReply {
	body: string;
	created: Date;
	staff?: {
		name: string;
	};
}

export interface IGetSupportRequestQueryData {
	support: {
		id: string;
		subject: string;
		email: string;
		name?: string;
		body: string;
		replies?: ISupportReply[];
		created: Date;
	};
}
