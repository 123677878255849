import * as React from 'react';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import MemberIcon from '@mui/icons-material/Person2';
import { stringify } from 'querystring';
import TotalCard from './TotalCard';

interface Props {
	type: 'activated' | 'unactivated';
	value?: number;
}

const TotalUsers = ({ value, type }: Props) => {
	return (
		<TotalCard
			to={`/admin/users?${stringify({
				page: 1,
				perPage: 25,
				filter: JSON.stringify({
					active: type === 'activated',
				}),
			})}`}
			icon={type === 'activated' ? MemberIcon : CustomerIcon}
			title={
				type === 'activated'
					? 'Activated Members'
					: 'Unactivated Members'
			}
			value={value}
		/>
	);
};

export default TotalUsers;
