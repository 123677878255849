export const uploadFile = async (file: any, url: string) => {
	const response = await fetch(url, {
		method: 'PUT',
		mode: 'cors',
		credentials: 'same-origin',
		headers: {
			'Content-Type': file.type,
			'Access-Control-Allow-Origin': '*',
		},
		referrer: 'no-referrer',
		body: file,
	});
	return response;
};
