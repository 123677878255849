
import gql from 'graphql-tag';
import * as Urql from "urql";

// #region ActivateUserAccount starts here
export const ACTIVATE_USER_ACCOUNT = gql`mutation ActivateUserAccount($token: String!, $email: String!, $password: String!) { userActivateAccount(token: $token, email: $email, password: $password) { user { id email status } accessToken } }`;
export const useActivateUserAccount = () => {
    return Urql.useMutation(ACTIVATE_USER_ACCOUNT);
  }
// #endregion ActivateUserAccount end here

