import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	ISubmitSupportRequestMutationData,
	ISubmitSupportRequestMutationVariables,
	SubmitSupportRequestMutation,
} from '../graphql/mutations/submitSupportRequest';

/**
 * Submit a support request mutation
 * @param options
 * @returns Mutation hook
 */
export const useSubmitSupportRequestMutation = (
	options?: MutationHookOptions<
		ISubmitSupportRequestMutationData,
		ISubmitSupportRequestMutationVariables
	>,
) =>
	useMutation<
		ISubmitSupportRequestMutationData,
		ISubmitSupportRequestMutationVariables
	>(SubmitSupportRequestMutation);
