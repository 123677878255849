import { gql } from '@apollo/client';

export const BasicLoginMutation = gql`
	mutation basicLogin($email: String!, $password: String!) {
		basicLogin(email: $email, password: $password) {
			accessToken
			refreshToken
		}
	}
`;

export interface IBasicLoginMutationVariables {
	email: string;
	password: string;
}

export interface IBasicLoginMutationData {
	basicLogin: {
		accessToken: string;
		refreshToken: string;
	};
}
