import React from 'react';
import { Show, ShowProps, Tab, TabbedShowLayout, TextField } from 'react-admin';

export const AuthorDataShow = (props: ShowProps) => (
	<Show>
		<TabbedShowLayout>
			<Tab label="Authors data">
				<TextField
					source="name"
					label=""
					style={{
						fontSize: '2rem',
						fontWeight: 'bold',
					}}
				/>
				<TextField
					source="bio"
					label=""
					style={{
						fontSize: '2rem',
						fontWeight: 'bold',
					}}
				/>
			</Tab>
		</TabbedShowLayout>
	</Show>
);
