import React from 'react';
import {
	Show,
	ShowProps,
	TextField,
	SimpleShowLayout,
	DateField,
	BooleanField,
} from 'react-admin';

export const QuoteShow = (props: ShowProps) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" label="ID" />
			<TextField source="title" />
			<TextField source="quote" />
			<TextField source="author" />
			<TextField source="backgroundColour" />
			<BooleanField
				source="showQuotationMark"
				label="Show Quotation Marks"
			/>
			<DateField label="Start" source="schedule.start" showTime />
			<DateField label="End" source="schedule.end" showTime />
			<DateField source="created" showTime />
		</SimpleShowLayout>
	</Show>
);
