const capitaliseFirst = (value: string) =>
	value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const enumToSelection = (values: string[]) =>
	values.map((value) => ({
		id: value.toUpperCase(),
		name: value
			.split('_')
			.map((word) => capitaliseFirst(word))
			.join(' '),
	}));

export const UserMobileOSOptions = ['APPLE', 'ANDROID', 'OTHER'];

export const UserRelationship = [
	'SINGLE',
	'MARRIED',
	'PARTNER',
	'DATING_IN_A_RELATIONSHIP',
	'DIVORCED',
	'WIDOWED',
	'SEPARATED',
	'OTHER',
];

export const UserEthnicity = [
	'WHITE_CAUCASIAN',
	'BLACK_AFRICAN_AMERICAN',
	'MIDDLE_EASTERN_OR_NORTH_AFRICAN',
	'NORTH_AFRICAN',
	'AFRICAN_AMERICAN',
	'HISPANIC',
	'LATINA_OR_SPANISH',
	'ASIAN',
	'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
	'NATIVE_HAWAIIAN',
	'MULTIRACIAL',
	'OTHER',
];

export const UserPet = ['DOG', 'CAT', 'OTHER', 'NONE'];

export const UserGeneration = ['BABY_BOOMER', 'GEN_X', 'MILLENNIAL', 'GEN_Z'];

export const UserEmploymentStatus = [
	'FULL_TIME_EMPLOYED_TEMPORARY',
	'FULL_TIME_EMPLOYED_PERMANENT',
	'PART_TIME_EMPLOYED_TEMPORARY',
	'PART_TIME_EMPLOYED_PERMANENT',
	'STUDENT',
	'UNEMPLOYED',
	'OTHER',
];

export const UserCompanySize = [
	'START_UP',
	'SMALL',
	'MEDIUM',
	'LARGE',
	'X_LARGE',
];

export const UserCompanyType = ['FOR_PROFIT', 'NOT_FOR_PROFIT', 'GOVERNMENT'];

export const UserCompanyIndustry = [
	'AGRICULTURE',
	'FORESTRY',
	'FISHING_AND_HUNTING',
	'MINING',
	'QUARRYING_AND_OIL_AND_GAS_EXTRACTION',
	'UTILITIES',
	'CONSTRUCTION',
	'MANUFACTURING',
	'WHOLESALE_TRADE',
	'RETAIL_TRADE',
	'TRANSPORTATION_AND_WAREHOUSING',
	'INFORMATION',
	'FINANCE_AND_INSURANCE',
	'REAL_ESTATE_RENTAL_AND_LEASING',
	'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
	'MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES',
	'ADMINISTRATIVE_AND_SUPPORT',
	'WASTE_MANAGEMENT_SERVICES',
	'EDUCATIONAL_SERVICES',
	'HEALTHCARE_AND_SOCIAL_ASSISTANCE',
	'ARTS_AND_CULTURE',
	'ENTERTAINMENT_AND_RECREATION',
	'PUBLIC_ADMINISTRATION',
	'OTHER',
];

export const UserCompanyRole = [
	'OWNER_PARTNER',
	'SENIOR_EXECUTIVE',
	'MIDDLE_MANAGEMENT',
	'JUNIOR_MANAGEMENT',
	'TRAINED_PROFESSIONAL',
	'CONSULTANT',
	'TEMPORARY_EMPLOYEE',
	'RESEARCHER',
	'SELF_EMPLOYED',
	'ADMINISTRATIVE_STAFF',
	'STUDENT',
	'OTHER',
	'EXECUTIVE',
];

export const UserCompanyFunction = [
	'MANAGEMENT',
	'BUSINESS_AND_FINANCIAL_OPERATIONS',
	'COMPUTER_AND_MATHEMATICS',
	'ARCHITECTURE_AND_ENGINEERING',
	'LIFE_PHYSICAL_AND_SOCIAL_SCIENCE',
	'EDUCATIONAL_INSTRUCTION_AND_LIBRARY',
	'COMMUNITY_AND_SOCIAL_SERVICE',
	'LEGAL',
	'ARTS',
	'DESIGN',
	'ENTERTAINMENT',
	'SPORTS_AND_MEDIA',
	'HEALTHCARE_PRACTITIONERS_AND_TECHNICAL',
	'HEALTHCARE_SUPPORT',
	'PROTECTIVE_SERVICE',
	'FOOD_PREPARATION_AND_HOSPITALITY',
	'PERSONAL_CARE_AND_SERVICE',
	'SALES_AND_MARKETING',
	'OFFICE_AND_ADMINISTRATIVE_SUPPORT',
	'FARMING',
	'FISHING_AND_FORESTRY',
	'CONSTRUCTION_AND_EXTRACTION',
	'PRODUCTION',
	'INSTALLATION',
	'MAINTENANCE_AND_REPAIR',
	'TRANSPORTATION_AND_MATERIAL_MOVING',
	'MILITARY',
	'PR_ADVERISING_AND_COMMUNICATIONS',
	'TECHNOLOGY',
	'CONSULTING_SERVICES',
	'OTHER',
];

export const UserEducation = [
	'SOME_OR_NO_COLLEGE',
	'ASSOCIATES_DEGREE_OR_APPRENTICESHIP',
	'BACHELORS_DEGREE',
	'MASTERS_DEGREE',
	'DOCTORAL_OR_PROFESSIONAL_DEGREE',
];

export const UserSubscription = ['ACTIVE', 'INACTIVE'];

export const UserOccupation = [
	'ACCOUNTANT',
	'ARMED_FORCES_OFFICER',
	'ARTIST',
	'ACTOR',
	'ARCHITEECT',
	'ATHLETE',
	'AUTHOR',
	'BANKER',
	'CHIEF_EXECUTIVE',
	'COMPUTER_PROFESSIONAL',
	'DENTIST',
	'DIRECTOR',
	'EDUCATOR',
	'ENGINEER',
	'FINANCIAL_ANALYST',
	'GRPAHIC_DESIGNER',
	'HEALTHCARE_WORKER',
	'JOURNALIST_AND_EDITOR',
	'LAWYER',
	'MANAGEMENT_CONSULTANT',
	'MANAGER',
	'MARKET_RESEARCH_ANALYST',
	'CIVIL_SERVICE_OFFICER',
	'PSYCHOLOGIST_THERAPIST',
	'SCIENTIST_RESEARCHER',
	'SYSTEM_ANALYST',
	'VETERINARIAN',
	'OTHER',
];

export const UserOccupationType = [
	'ADMINISTRATION_AND_BUSINESS',
	'ADVERTISING_AND_MEDIA',
	'MARKETING_COMMUNICATIONS',
	'AGRICULTURE',
	'ARTS_AND_ENTERTAINMENT',
	'CONSTRUCTION',
	'EDUCATION',
	'ENERGY',
	'ENTREPRENEURSHIP',
	'ENGINEERING_AND_MANUFACTURING',
	'FINANCE_INSURANCE',
	'FITNESS_NUTRITION',
	'FREELANCING',
	'GENDER_POLICIES',
	'GOVERNMENT',
	'HEALTHCARE_SOCIAL_ASSISTANCE',
	'HOSPITALITY',
	'HUMAN_RESOURCES',
	'LAW',
	'MILITARY',
	'NON-PROFIT',
	'REAL_ESTATE',
	'RETAIL',
	'SPORTS',
	'TECHNOLOGY',
	'TRANSPORTATION_UTILITIES',
	'OTHER',
];

export enum UserSubscriptionEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}
