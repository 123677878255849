import React from 'react';
import Container from '../../components/Container';

const BillingError = () => {
	return (
		<Container>
			<h1 className="text-2xl mb-2 font-bold">Payment Cancelled</h1>
			<p className="font-light my-5">
				Something went wrong on your end before being able to complete
				the membership
			</p>
			<p className="font-light">
				Perhaps you&apos;d like to{' '}
				<a href="/invite" className="font-bold underline">
					try again
				</a>
				?
			</p>
		</Container>
	);
};

export default BillingError;
