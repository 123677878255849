import React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { matchSuggestion } from '../../helpers';
import { MilestoneRow } from './components/living-room-milestone-row';

const LivingRoomMilestones = () => {
	return (
		<ReferenceArrayInput
			source="milestones"
			reference="milestones"
			label="Milestones"
		>
			<AutocompleteArrayInput
				optionText={<MilestoneRow />}
				inputText={(option) => option.id}
				matchSuggestion={matchSuggestion}
				source="id"
				fullWidth
			/>
		</ReferenceArrayInput>
	);
};

export default LivingRoomMilestones;
