import React from 'react';
import { useQuery } from 'urql';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TOTAL_AUTHORS } from './components/sessionsQuery';

export const AuthorDataList = () => {
	const [result] = useQuery({
		query: TOTAL_AUTHORS,
		variables: {
			limit: 100,
		},
	});
	const { fetching, error, data } = result;

	if (fetching)
		return (
			<Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
				<CircularProgress color="inherit" />
			</Stack>
		);
	if (error) return <p>Error: {error.message}</p>;
	const authors = data.authors.edges?.map((edge) => edge.node) || [];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Author Name',
			width: 600,
			headerClassName: 'super-app-theme--header',
		},
		{
			field: 'image',
			headerName: 'Profile',
			width: 550,
			headerClassName: 'super-app-theme--header',
			renderCell: (params) => (
				<img
					src={params.value}
					alt="pro-pic"
					width={100}
					height={100}
				/>
			),
		},
	];

	const rows = authors.map((room, index) => ({
		id: index + 1,
		name: room.name,
		image: room.profile,
		bio: room.bio,
	}));
	return (
		<>
			<Box
				sx={{
					height: 300,
					width: '100%',
					'& .super-app-theme--header': {
						fontWeight: 'bold',
					},
				}}
			>
				<DataGrid
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
					pageSizeOptions={[10]}
					checkboxSelection
					disableRowSelectionOnClick
					autoHeight
				/>
			</Box>
		</>
	);
};
