import React, { useState } from 'react';
import Container from '../../../components/Container';
import ResetSuccess from './ResetSuccess';
import EnterCode from './EnterCode';
import EnterPassword from './EnterPassword';
import EnterPhone from './EnterPhone';
import Header from '../../../components/Header';

const ForgotPassword = () => {
	const [phone, setPhone] = useState<string>('');

	const [enterCode, setEnterCode] = useState<boolean>(false);
	const [enterPassword, setEnterPassword] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [verification, setVerification] = useState<string>();

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			<Container>
				<Header
					title={`${success ? 'Thank You.' : 'Reset Your Password'}`}
				/>
				{success ? (
					<ResetSuccess />
				) : enterPassword ? (
					<EnterPassword
						setSuccess={setSuccess}
						verification={verification}
						phone={phone}
					/>
				) : enterCode ? (
					<EnterCode
						setVerification={setVerification}
						setEnterPassword={setEnterPassword}
					/>
				) : (
					<EnterPhone
						phone={phone}
						setPhone={setPhone}
						setEnterCode={setEnterCode}
					/>
				)}
			</Container>
		</main>
	);
};

export default ForgotPassword;
