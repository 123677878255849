/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const DownloadButton = () => {
	return (
		<>
			<a
				href="https://apps.apple.com/ae/app/uplevyl/id1572977506"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="cursor-pointer w-40 mt-3"
					src="https://cdn.uplevyl.com/icons/download_appstore.png"
					alt="IOS Download"
				/>
			</a>
			<a
				href="https://play.google.com/store/apps/details?id=com.uplevyl.app"
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="cursor-pointer w-40 mt-3"
					src="https://cdn.uplevyl.com/icons/download_playstore.png"
					alt="IOS Download"
				/>
			</a>
		</>
	);
};

export default DownloadButton;
