import React from 'react';
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin';

const ColorDropDownInput = (props: AutocompleteInputProps) => {
	return (
		<AutocompleteInput
			{...props}
			choices={[
				{ id: '#4B8991', name: 'Professional (Blue)' },
				{ id: '#BB852C', name: 'Personal (Orange)' },
				{ id: '#526B55', name: 'Financial (Green)' },
			]}
		/>
	);
};

export default ColorDropDownInput;
