import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	RequestCodeMutation,
	IRequestCodeMutationData,
	IRequestCodeMutationVariables,
} from '../graphql/mutations/requestCode';

/**
 * Request code for resetting password
 * @param options
 * @returns
 */
export const useRequestCodeMutation = (
	options?: MutationHookOptions<
		IRequestCodeMutationData,
		IRequestCodeMutationVariables
	>,
) =>
	useMutation<IRequestCodeMutationData, IRequestCodeMutationVariables>(
		RequestCodeMutation,
		options,
	);
