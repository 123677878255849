import { TitleProps } from 'react-admin';
import React from 'react';

type IUserTitleProps = TitleProps;
const UserTitle = ({ record }: IUserTitleProps) => {
	return (
		<span>
			{record ? `${record.firstName ?? ''} ${record.lastName ?? ''}` : ''}
		</span>
	);
};

export default UserTitle;
