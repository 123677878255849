import {
	CreateParams,
	DataProvider,
	DeleteManyParams,
	DeleteParams,
	GetListParams,
	GetManyParams,
	GetOneParams,
	UpdateParams,
	HttpError,
	RaRecord,
} from 'react-admin';
import { DocumentNode, GraphQLError } from 'graphql';
import mutations, { IMutations } from './mutations';
import customMutations from './mutations/custom';
import queries, { IQueries } from './queries';
import { FormatterFactory } from './mutations/formatters';
import { IClient } from './client/client.interface';

export const handleErrors = (error: GraphQLError) => {
	if (error.message.includes('UNAUTHENTICATED'))
		return new HttpError(error.message, 401, error);
	return error;
};

const buildDataProvider = (client: IClient): DataProvider =>
	({
		getOne: async (resource: string, params: GetOneParams) => {
			const _queries = combineDocuments(
				queries.one[resource as keyof IQueries],
			);

			const responses = await Promise.all(
				_queries.map(async (query) =>
					client
						.query(
							query,

							params,
						)
						.catch((err) => Promise.reject(handleErrors(err))),
				),
			);

			const rawData = combineResponses(responses);

			const formatter = new FormatterFactory().getFormatter(resource);
			const data = formatter.one(rawData as RaRecord);

			return data;
		},
		getList: async (resource: string, params: GetListParams) => {
			const _queries = combineDocuments(
				queries.list[resource as keyof IQueries],
			);

			const extraParams: Record<string, any> = {};

			// Pull out search query
			let fieldFilters: {
				field: string;
				value: any;
			}[] = [];

			let searchQuery: string | undefined;
			if (params.filter && Object.entries(params.filter)) {
				const { query: _query, q, ...filters } = params.filter;

				// Some inputs use `q` for search queries and cannot be changed, so accept both as a search query
				searchQuery = _query || q;

				fieldFilters = Object.entries(filters)
					.map((entry: [string, any]) => {
						// eslint-disable-next-line
						let [field, value] = entry;
						if (value && typeof value === 'object' && value.id) {
							value = value as { id: string };
							value = value.id;
						} else if (value === true) {
							value = 'true';
						} else if (value === false) {
							value = 'false';
						} else if (!Array.isArray(value)) {
							value = [value];
						}

						// Log start/end
						if (field === 'start' || field === 'end') {
							// eslint-disable-next-line
							if (value[0]) extraParams[field] = value[0];
							return null;
						}

						return {
							field: entry[0],
							value,
						};
					})
					.filter((field) => field) as {
					field: string;
					value: string;
				}[];
			}

			const responses = await Promise.all(
				_queries.map(async (query) =>
					client
						.query(query, {
							...params.pagination,
							sort: params.sort
								? {
										field: params.sort.field,
										order: params.sort.order.toUpperCase(),
								  }
								: undefined,
							filter: params.filter
								? {
										query: searchQuery,
										fields: [
											...fieldFilters,
											...(resource === 'livingRooms'
												? [
														{
															field: 'ignorePillarMilestone',
															value: 'true',
														},
												  ]
												: []),
										],
								  }
								: undefined,
							...extraParams,
						})
						.catch((err) => Promise.reject(handleErrors(err))),
				),
			);

			const rawData = combineResponses(responses);
			const formatter = new FormatterFactory().getFormatter(resource);
			const data = formatter.list(rawData as RaRecord);

			return data;
		},
		getMany: async (resource: string, params: GetManyParams) => {
			const _queries = combineDocuments(
				queries.many[resource as keyof IQueries],
			);

			const responses = await Promise.all(
				_queries.map(async (query) =>
					client
						.query(query, params)
						.catch((err) => Promise.reject(handleErrors(err))),
				),
			);

			const rawData = combineResponses(responses);

			const formatter = new FormatterFactory().getFormatter(resource);
			return formatter.many(rawData as RaRecord);
		},
		getManyReference: async () => {
			return {
				data: [],
				total: 0,
			};
		},
		create: async (resource: string, params: CreateParams) => {
			const formatter = new FormatterFactory().getFormatter(resource);
			const input = formatter.create(params.data);

			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					mutations.create[resource as keyof IMutations],
					{ input },
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		update: async (resource: string, params: UpdateParams<RaRecord>) => {
			const formatter = new FormatterFactory().getFormatter(resource);
			const input = formatter.update(params.data as RaRecord);
			const inputKeys = Object.keys(input);
			
			inputKeys.forEach((key: any) => {
				if (input[key] === '') input[key] = null;
			});

			delete input.additionalFormInfo.__typename;
			delete input.id;
			delete input.created;
			delete input.updated;
			delete input.__typename;

			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					mutations.update[resource as keyof IMutations],
					{
						id: params.id,
						input,
					},
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		updateMany: async () => {
			return Promise.reject(new Error('Update many is not implemented'));
		},
		delete: async (resource: string, params: DeleteParams) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					mutations.deletes[resource as keyof IMutations],
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		deleteMany: async (resource: string, params: DeleteManyParams) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					mutations.deleteMany[resource as keyof IMutations],
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},

		// Custom Mutations
		activate: async (params: DeleteParams) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.activate,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		approveORReject: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.approveORReject,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}
			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		archive: async (params: DeleteParams) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(customMutations.archive, params);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		addRegistration: async (params: {
			id: string;
			input: {
				userId: string;
				role?: string;
			};
		}) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.addRegistration,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		removeRegistration: async (params: {
			id: string;
			input: {
				userId: string;
			};
		}) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.removeRegistration,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		broadcastNotification: async (params: {
			body: string;
			subtitle: string;
			title: string;
		}) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.broadcastNotification,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		downloadAsset: async (params: { id: string }) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.contentRequestMasterAccess,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		userImportCsv: async (params: {
			body: string;
			subtitle: string;
			title: string;
		}) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.userImportCsv,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		userExportCsv: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.query(
					customMutations.userExportCsv,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		livingRoomExportCsv: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.query(
					customMutations.livingRoomExportCsv,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		reportExportCsv: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.query(
					customMutations.reportExportCsv,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		pollCreate: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.pollCreate,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		quoteCreate: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.quoteCreate,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
		freeTextCreate: async (params: any) => {
			let response: Record<string, any> | undefined;
			try {
				response = await client.mutate(
					customMutations.freeTextCreate,
					params,
				);
			} catch (err) {
				return Promise.reject(handleErrors(err));
			}

			const data = response?.[Object.keys(response)[0]];
			return {
				data,
			};
		},
	} as DataProvider);

export default buildDataProvider;

const combineDocuments = (documents: DocumentNode | DocumentNode[]) =>
	([] as DocumentNode[]).concat(documents);

const combineResponses = (responses: (Record<string, any> | undefined)[]) => {
	return responses.reduce((value, response) => {
		if (!response) return value;

		return {
			...value,
			...response[Object.keys(response)[0]],
		};
	}, {});
};
