import React from 'react';
import {
	Create,
	CreateProps,
	DateInput,
	PasswordInput,
	SimpleForm,
	TextInput,
	BooleanInput,
	SelectInput,
} from 'react-admin';
import { countryCodes } from '../countryCodes';

type IUserCreateProps = CreateProps;

export const UserCreate = (props: IUserCreateProps) => {
	return (
		<Create
			{...props}
			transform={(data) => {
				return { ...data, termsAgreed: true };
			}}
			title="Create Member"
		>
			<SimpleForm>
				<TextInput source="firstName" label="First Name" required />
				<TextInput source="lastName" label="Last Name" required />
				<TextInput source="email" type="email" required />
				<PasswordInput source="password" required />
				<DateInput source="dob" required />
				<div style={{ width: '950px' }}>
					<SelectInput
						source="countryCode"
						defaultValue="+1"
						choices={countryCodes}
						style={{ marginRight: 3 }}
					/>
					<TextInput id="phone" source="phone" type="phone" />
				</div>
				<BooleanInput source="contributor" />
				<BooleanInput source="enterprise" />
				<TextInput source="comment" />
				<SelectInput
					source="role"
					choices={[
						{ id: 'CUSTOMER', name: 'Customer' },
						{ id: 'STAFF', name: 'Staff' },
						{ id: 'ADMIN', name: 'Admin' },
						{ id: 'MANAGER', name: 'Manager' },
					]}
					required
				/>
			</SimpleForm>
		</Create>
	);
};
