import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	IUpdateUserMutationData,
	IUpdateUserMutationVariables,
	UpdateUserMutation,
} from '../graphql/mutations';

/**
 * Update a user's details
 * @param options
 * @returns Mutation hook
 */
export const useUpdateUserMutation = (
	options?: MutationHookOptions<
		IUpdateUserMutationData,
		IUpdateUserMutationVariables
	>,
) =>
	useMutation<IUpdateUserMutationData, IUpdateUserMutationVariables>(
		UpdateUserMutation,
	);
