import React from 'react';
import {
	Show,
	ShowProps,
	TextField,
	SimpleShowLayout,
	DateField,
	TabbedShowLayout,
	Tab,
	ArrayField,
	Datagrid,
	FunctionField,
} from 'react-admin';
import TextArrayField from '../components/TextArrayField';

export const PollShow = (props: ShowProps) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TabbedShowLayout>
				<Tab label="Poll">
					<TextField source="id" label="ID" />
					<TextField source="title" />
					<TextField source="question" />
					<TextField source="backgroundColour" />
					<DateField source="created" showTime />
					<DateField source="schedule.start" showTime />
					<DateField source="schedule.end" showTime />
					<TextArrayField source="choices" />
				</Tab>
				<Tab label="Results">
					<ArrayField source="results">
						<Datagrid bulkActionButtons={false}>
							<TextField source="answer" label="Answer" />
							<FunctionField
								source="Percentage"
								render={(record: any) => (
									<TextField
										label="Percentage"
										source="percentage"
										record={{
											id: new Date().getMilliseconds(),
											percentage: `${Math.round(
												(record?.percentage ?? 0) * 100,
											)}%`,
										}}
									/>
								)}
							/>
						</Datagrid>
					</ArrayField>
				</Tab>
			</TabbedShowLayout>
		</SimpleShowLayout>
	</Show>
);
