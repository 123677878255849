import { gql } from '@apollo/client';

export const GetUserInfoQuery = gql`
	query getUserInfo($token: String!) {
		invite(token: $token) {
			id
			email
			firstName
			accessToken
			hasUsedFreeTrial
			clickedActivationLink
		}
	}
`;

export interface IGetUserInfoQueryVariables {
	token: string;
}

export interface IGetUserInfoQueryData {
	invite: {
		id: string;
		email: string;
		firstName: string;
		accessToken: string;
		hasUsedFreeTrial?: boolean;
		clickedActivationLink?: boolean;
	};
}
