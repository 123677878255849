import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const MobileRedirectDeepLinking = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	const contentId = queryParameters.get('content_id');
	const recommendationId = queryParameters.get('recommendation_id');
	const authorId = queryParameters.get('author_id');
	const type = queryParameters.get('type');

	useEffect(() => {
		let url = '';
		if (
			/Android/i.test(navigator.userAgent) ||
			/iPhone|iPad|iPod/i.test(navigator.userAgent)
		) {
			url = `uplevyl://uplevyl/content/post/${contentId}/${uuidv4()}`;
			if (type === 'author') {
				url = `uplevyl://uplevyl/author/${authorId}`;
			}

			const iframe = document.createElement('iframe');
			iframe.style.width = '1px';
			iframe.style.height = '1px';
			iframe.style.display = 'none';

			let success = false;

			iframe.onload = function () {
				success = true;
			};

			iframe.src = url;
			document.body.appendChild(iframe);
			document.body.removeChild(iframe);

			if (success) {
				setTimeout(() => {
					window.location.href = url;
				}, 2000);
			} else {
				setTimeout(() => {
					window.location.href = 'https://uplevyl.com/fallback.html';
				}, 3000);
			}
		} else {
			url = window.location.href.includes('staging')
				? `https://staging.uplevyl.com/SingleEntryScreen/${contentId}/${uuidv4()}`
				: `https://my.uplevyl.com/SingleEntryScreen/${contentId}/${uuidv4()}`;

			if (type === 'author') {
				url = window.location.href.includes('staging')
					? `https://staging.uplevyl.com/ContributorProfile/${authorId}`
					: `https://my.uplevyl.com/ContributorProfile/${authorId}`;
			}
		}

		window.location.href = url;
	}, [contentId, recommendationId, authorId, type]);

	return <></>;
};

export default MobileRedirectDeepLinking;
