import { TitleProps } from 'react-admin';
import React from 'react';
import ArchiveButton from './archive.button';

type ExternalButtonProps = TitleProps;
const ExternalArchiveButton = ({ record }: ExternalButtonProps) => {
	return <ArchiveButton selectedIds={[String(record?.id) ?? '']} single />;
};

export default ExternalArchiveButton;
