/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import uplevyl1 from '../../assets/icons/Uplevyl1.svg';

const ConfirmationPage: React.FC = () => {
    const params = useParams();
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const newLink = `https://${window.location.hostname}#invitee=${params.username}`;

    const handleClick = () => {
        setShowCopied(true);
        navigator.clipboard.writeText(newLink);
        setTimeout(() => {
            setShowCopied(false);
        }, 1000);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-white p-4">
            <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8 text-center">
                <img
                    alt="Uplevyl"
                    className="w-40 sm:w-48 md:w-56 mx-auto mb-6"
                    src={uplevyl1}
                />
                <h1 className="mb-4 text-base sm:text-lg md:text-xl font-bold text-gray-800">
                    Thank You!
                </h1>
                <div className="mb-6 text-sm sm:text-base md:text-center">
                    <p className="mb-4">
                        Your application for Future Forward has been submitted and will now be reviewed.
                        You will receive an email regarding your application status within 5-7 business days.
                    </p>
                    <p className="mb-4">
                        If approved, you will receive an email with detailed instructions with payment details and for logging in and setting up your account so you can start get started in the community right away.
                    </p>
                    <p className="mb-4">
                        If you would like to review the Future Forward program summary again, please <a href="https://www.uplevyl.com/future-forward" className="text-black underline hover:text-gray-600">visit this page</a>.
                        In the meantime, you might like to start Uplevyling right now by listening in to an episode or two from the Uplevyl podcast, <a href="https://go.uplevyl.com/podcast" className="text-black underline hover:text-gray-600">Women. Wisdom. Worth.</a>
                    </p>
                    <p>
                        Thanks again.
                        <br />
                        Uplevyl
                    </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
                    <div 
                        className="w-full sm:w-auto border-black border rounded py-2 px-4 text-sm break-all cursor-pointer text-center"
                        onClick={handleClick}
                    >
                        {newLink}
                    </div>
                    <button
                        className="w-full sm:w-auto text-white py-2 bg-black rounded px-5 cursor-pointer hover:bg-gray-800 transition-colors"
                        onClick={handleClick}
                    >
                        {showCopied ? 'Copied!' : 'Copy'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPage;