import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import {
	ResponsiveContainer,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
} from 'recharts';
import { format, subDays, addDays } from 'date-fns';
import { RaRecord, useGetList } from 'react-admin';

const card3 = {
	width: '520px',
	height: '400px',
	padding: '10px',
	margin: '10px',
};

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date: number): string =>
	new Date(date).toLocaleDateString();

const aggregateUsersByDay = (users: RaRecord[]): { [key: string]: number } =>
	users
		// .filter((order: RaRecord) => !order.active)
		.reduce((acc, curr) => {
			const day = format(new Date(curr.created), 'yyyy-MM-dd');
			if (!acc[day]) {
				acc[day] = 0;
			}
			acc[day] += 1;
			return acc;
		}, {} as { [key: string]: number });

const getRevenuePerDay = (users: RaRecord[]): TotalByDay[] => {
	const daysWithRevenue = aggregateUsersByDay(users);
	return lastMonthDays.map((date) => ({
		date: date.getTime(),
		total: daysWithRevenue[format(new Date(date), 'yyyy-MM-dd')] || 0,
	}));
};

const NewUsersChart = () => {
	const { data: users } = useGetList('users', {
		// filter: { date_gte: aMonthAgo.toISOString() },
		sort: { field: 'created', order: 'DESC' },
		pagination: { page: 1, perPage: 100 },
	});

	if (!users) return null;

	return (
		<>
			<Paper elevation={3} style={card3}>
				<Typography variant="h5" component="h2">
					New users by day
				</Typography>
				<div style={{ width: '100%', height: 300 }}>
					<ResponsiveContainer>
						<AreaChart data={getRevenuePerDay(users)}>
							<defs>
								<linearGradient
									id="colorUv"
									x1="0"
									y1="0"
									x2="0"
									y2="1"
								>
									<stop
										offset="5%"
										stopColor="#8884d8"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="#8884d8"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<XAxis
								dataKey="date"
								name="Date"
								type="number"
								scale="time"
								domain={[
									addDays(aMonthAgo, 1).getTime(),
									new Date().getTime(),
								]}
								tickFormatter={dateFormatter}
							/>
							<YAxis dataKey="total" name="Users" />
							<CartesianGrid strokeDasharray="3 3" />
							<Tooltip
								cursor={{ strokeDasharray: '3 3' }}
								labelFormatter={(label: any) =>
									dateFormatter(label)
								}
							/>
							<Area
								type="monotone"
								dataKey="total"
								stroke="#8884d8"
								strokeWidth={2}
								fill="url(#colorUv)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</Paper>
		</>
	);
};

interface TotalByDay {
	date: number;
	total: number;
}

export default NewUsersChart;
