import { gql } from '@apollo/client';

export const VerifyCodeMutation = gql`
	mutation verifyCode($code: String!) {
		verifyCode(code: $code)
	}
`;

export interface IVerifyCodeMutationVariables {
	code: string;
}

export interface IVerifyCodeMutationData {
	verifyCode: string;
}
