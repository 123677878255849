import {
	useStripe,
	useElements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import React from 'react';
import { useUpdatePaymentQuery } from './hooks/usePaymentQuery';

const CheckoutForm = ({ paymentIntent }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [updatePayment, { loading }] = useUpdatePaymentQuery();
	// Use the hook

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: 'https://www.uplevyl.com/subscription-success.html',
			},
			redirect: 'if_required',
		});

		if (result.error) {
			console.log(result.error.message);
		} else {
			// If payment confirmation was successful, update the payment status on the server
			try {
				// Make the updatePayment query to your server, passing the paymentIntentId
				await updatePayment({
					variables: { paymentIntentId: paymentIntent },
				});
				// Log the server response for debugging
				window.location.href =
					'https://www.uplevyl.com/subscription-success.html'; // Replace with your desired URL
			} catch (error) {
				console.error('Error updating payment status:', error.message);
			}
		}
	};

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<form
				onSubmit={handleSubmit}
				className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
			>
				<div className="mb-4 flex flex-col items-center">
					<img
						src="https://cdn.uplevyl.com/logo/full/blue.png"
						alt="Uplevyl"
						className="mb-4 flex flex-col items-center w-1/6 mb-4"
					/>

					{/* Assuming <PaymentElement /> is a custom component for the payment element */}
					<PaymentElement />
				</div>
				<div className="text-center">
					<button
						type="submit"
						disabled={!stripe || loading} // Disable the button while loading
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					>
						{loading ? 'Submitting...' : 'Submit'}
					</button>
				</div>
			</form>
		</div>
	);
};

export default CheckoutForm;
