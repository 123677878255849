import React, { useContext, useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import IntakeSection from './components/IntakeSection';
import Navigators from './components/Navigators';
import useScroll from './hooks/useScroll';
import sections from './intake.data';
import { useCreateUserMutation } from './hooks/useCreateUserMutation';
import { toEnum, YesNoAvailability } from './types/intake.types';
import ErrorPopup from './components/ErrorPopup';
import { IntakeContext } from './intake.provider';

type IntakePageProps = {
	hash?: string | undefined;
};

export type IntakeFormData = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	generation: string;
	howCanUplevylElevateYou?: string[];
	role: string;
	selection: { selection: string; availability: string };
	[key: string]: YesNoAvailability | boolean | number[] | string | string[] | Record<string, number> | { selection: string; availability: string };

	motivations?: string[];
	digitalImpactVision?: string[];
	contributeViaWorkshops?: boolean;
	location?: string;
	currentTitle?: string;
	companyName?: string;
	companyAnnualRevenue?: string;
	highestEducationLevel?: string;
	hearAboutProgram?: string;
	aiSkillsRatings?: number[];
	aiTopicsInterest?: string;
	additionalInterests?: string;
	linkedInProfile?: string;
	socialHandle?: string;
};



const phoneRegExp =
	/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

	const schema = yup.object().shape({
		firstName: yup.string().required('This is a required field'),
		lastName: yup.string().required('This is a required field'),
		email: yup.string().required('Email is required').email('Email is invalid'),
		contributeViaWorkshops: yup.boolean().required('Please select an option.'),
		phone: yup
			.string()
			.required('Phone number is required')
			.matches(phoneRegExp, 'Phone number is not valid'),
		location: yup.string().required('Location is required'),
		currentTitle: yup.string().required('Current job title or role is required'),
		companyName: yup.string().required('Current company name is required'),
		// companyAnnualRevenue: yup.string().required('Company annual revenue is required'),
		highestEducationLevel: yup.string().required('Highest education level is required'),
		hearAboutProgram: yup.string().required('required'),
		motivations: yup.array().of(yup.string()).required('Motivations are required'),
		digitalImpactVision: yup.array().of(yup.string()).required('Digital impact vision is required'),
		aiSkillsRatings: yup.array().of(yup.number().min(1).max(5)).required('AI skills ratings are required'),
		aiTopicsInterest: yup.string().required('Interest in AI topics is required'),
		additionalInterests: yup.string().required('Additional interests are required'),
		// linkedInProfile: yup.string().required('LinkedIn profile is required'),
		// socialHandle: yup.string().required('Social handle is required'),
	});

const IntakePage = ({ hash }: IntakePageProps) => {
	const { currentIndex } = useContext(IntakeContext);
	const { resetScroll } = useScroll();
	const navigate = useNavigate();
	const [createUser, { loading: submitting, error: createUserError }] =
		useCreateUserMutation();
	const [error, setError] = useState<string | undefined>();

	const {
		register,
		handleSubmit,
		setValue,
		trigger,
		formState: { errors },
		getValues,
	} = useForm<IntakeFormData>({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data: IntakeFormData) => {
		let inviteeToken: string | undefined;
		let inviteeUsername: string | undefined;

		const token = hash?.split('=')[1];
		if (token && isBase64(token)) {
			inviteeToken = token;
		} else if (token) {
			inviteeUsername = token;
		}

		try {
			const { data: createUserData } = await createUser({
				variables: {
					input: {
						email: data.email,
						firstName: data.firstName,
						lastName: data.lastName,
						termsAgreed: true,
						phone: data.phone,
						generation: data.generation ? toEnum(data.generation) : undefined,
						inviteeToken,
						inviteeUsername,
						howCanUplevylElevateYou: data.howCanUplevylElevateYou,
						additionalFormInfo: {
							motivations: data.motivations,
							digitalImpactVision: data.digitalImpactVision,
							contributeViaWorkshops: data.contributeViaWorkshops,
							location: data.location,
							currentTitle: data.currentTitle,
							companyName: data.companyName,

							highestEducationLevel: data.highestEducationLevel,
							hearAboutProgram: data.hearAboutProgram,
							aiSkillsRatings: data.aiSkillsRatings,
							aiTopicsInterest: data.aiTopicsInterest,
							additionalInterests: data.additionalInterests,
							linkedInProfile: data.linkedInProfile? data.linkedInProfile : null,
						},
					},
				},
			});

			ReactGA.event({
				category: 'Form Submission',
				action: 'Future Forward form completion',
				label: `User ${  data.firstName  } ${  data.lastName  } completed the intake form`
			});

			navigate(`/referral/${createUserData?.userCreate.username}`);
		} catch (err) {
			console.log(err);
			setError('An error occurred while submitting the form. Please try again.');
		}
	};

	const handleFormSubmit = () => {
		handleSubmit(onSubmit)();
	};

	useEffect(() => {
		const fireAnalytics = () => {
			const section = sections[currentIndex];
			switch (section.inputName) {
				case 'motivations':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'A - motivations section',
						label: 'User on motivations section' 
					});
					break;
				case 'digitalImpactVision':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'B - digital impact vision section',
						label: 'User on digital impact vision section' 
					});
					break;
				case 'contributeViaWorkshops':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'C - workshops availability section',
						label: 'User on workshops availability section' 
					});
					break;
				case 'firstName':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'D - first name section',
						label: 'User on first name section' 
					});
					break;
				case 'lastName':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'E - last name section',
						label: 'User on last name section' 
					});
					break;
				case 'email':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'F - email section',
						label: 'User on email section' 
					});
					break;
				case 'phone':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'G - phone section',
						label: 'User on phone section' 
					});
					break;
				case 'location':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'H - location section',
						label: 'User on location section' 
					});
					break;
				case 'currentTitle':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'I - job title section',
						label: 'User on job title section' 
					});
					break;
				case 'companyName':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'J - company name section',
						label: 'User on company name section' 
					});
					break;
				// case 'companyAnnualRevenue':
				// 	ReactGA.event({ 
				// 		category: 'Form Navigation',
				// 		action: ' company revenue section',
				// 		label: 'User on company revenue section' 
				// 	});
				// 	break;
				case 'highestEducationLevel':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'K - education level section',
						label: 'User on education level section' 
					});
					break;
				case 'hearAboutProgram':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'L - hear about future forward section',
						label: 'User on hear about future forward section' 
					});
					break;
				case 'aiSkillsRatings':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'M - AI skills ratings section',
						label: 'User on AI skills ratings section' 
					});
					break;
				case 'aiTopicsInterest':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'N - AI topics interest section',
						label: 'User on AI topics interest section' 
					});
					break;
				case 'additionalInterests':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'O - additional interests section',
						label: 'User on additional interests section' 
					});
					break;
				case 'linkedInProfile':
					ReactGA.event({ 
						category: 'Form Navigation',
						action: 'P - LinkedIn profile section',
						label: 'User on LinkedIn profile section' 
					});
					break;
				// case 'socialHandle':
				// 	ReactGA.event({ 
				// 		category: 'Form Navigation',
				// 		action: 'Q - social handle section',
				// 		label: 'User on social handle section' 
				// 	});
				// 	break;
				default:
					break;
			}
		};

		fireAnalytics();
	}, [currentIndex]);

	/**
	 * Check if a string is Base64 encoded or not
	 * @param value String value to check
	 * @returns `true` if base64, `false` if not
	 */
	const isBase64 = (value: string) =>
		/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
			value,
		);

	useEffect(() => {
		if (createUserError)
			setError(
				createUserError.graphQLErrors[0]?.extensions?.message ??
				createUserError.message,
			);
	}, [createUserError]);

	/* eslint-disable */
	useEffect(() => {
		resetScroll();
	}, []);
	
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
	return (
		<>
			{error && <ErrorPopup error={error} setError={setError} />}
			<form onSubmit={handleSubmit(onSubmit)}>
				{sections.map((section, i) => (
					<IntakeSection
						key={section.label.toString()}
						section={section}
						index={i}
						register={register}
						setValue={setValue}
						trigger={trigger}
						errors={errors}
						loading={submitting}
						getValues={getValues}
						onSubmit={handleFormSubmit}
					/>
				))}
				<Navigators />
			</form>
		</>
	);
};

export default IntakePage;
