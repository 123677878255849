import parsePhoneNumber from 'libphonenumber-js';
import { RaRecord } from 'react-admin';
import { BaseFormatter } from './default.formatter';

/* eslint no-param-reassign: "error" */

export class UserFormatter extends BaseFormatter {
	public create<D extends RaRecord, O extends RaRecord>(data: D): O {
		if (data?.countryCode && data?.phone) {
			const countryCode = data?.countryCode;
			(data as RaRecord).phone = `${countryCode}${data.phone}`;
			delete data.countryCode;
		}
		return data as unknown as O;
	}

	public update<D extends RaRecord, O extends RaRecord>(data: D): O {
		if (data?.countryCode && data?.phone) {
			const countryCode = data?.countryCode;
			(data as RaRecord).phone = `${countryCode}${data.phone}`;
			delete data.countryCode;
		}
		if (!data?.phone) {
			(data as RaRecord).phone = '';
			delete data.countryCode;
		}
		delete data.registrations;
		delete data.invitedBy;
		delete data.auditLog;
		delete data.resume;

		return data as unknown as O;
	}

	one(data: any) {
		const registrations = data?.registrations?.edges?.map(
			(edge: any) => edge?.node,
		);

		let countryCode = '+1';
		let phone = '';
		if (data.phone) {
			countryCode = `+${
				parsePhoneNumber(data.phone)?.countryCallingCode
			}`;

			phone = data.phone.slice(countryCode.length);
		}

		const auditLog = data?.auditLog?.edges?.map((edge: any) => edge?.node);

		return {
			data: {
				...data,
				registrations,
				countryCode,
				phone,
				auditLog,
			},
		};
	}
}
/* eslint no-param-reassign: "error" */
