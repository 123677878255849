import React, { useEffect } from 'react';

const MobileRedirect = () => {
	useEffect(() => {
		if (/Android/i.test(navigator.userAgent)) {
			window.location.href =
				'https://play.google.com/store/apps/details?id=com.uplevyl.app';
		} else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			window.location.href =
				'https://apps.apple.com/ae/app/uplevyl/id1572977506';
		} else {
			window.location.href = window.location.href.includes('staging')
				? 'https://staging.uplevyl.com'
				: 'https://my.uplevyl.com';
		}
	}, []);

	return <></>;
};

export default MobileRedirect;
