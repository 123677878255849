import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from './logo.png';

interface IArticle {
	id: string;
	title: string;
	teaser: string;
	publisher: string;
	body: string;
	image: string;
	created: Date;
}

interface IArticleResponse {
	data?: {
		article?: IArticle | null;
	};
}

const PublicArticle = () => {
	const { id } = useParams<{ id?: string }>();
	const [article, setArticle] = useState<IArticle | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		let isMounted = true;
		const getArticle = async () => {
			setLoading(true);

			const res = await fetch(process.env.REACT_APP_API ?? '', {
				headers: {
					accept: '*/*',
					'content-type': 'application/json',
					'sec-fetch-mode': 'cors',
					'sec-fetch-site': 'same-origin',
				},
				referrer: process.env.REACT_APP_API ?? '',
				referrerPolicy: 'strict-origin-when-cross-origin',
				body: `{
                        "operationName":"getArticle",
                        "variables": {
                            "id":"${id}"
                        },
                        "query": "query getArticle($id: String!) {\\n  article(id: $id) {\\n    id\\n    title\\n    teaser\\n    body\\n    created\\n    image\\n    publisher\\n  }\\n}\\n"}`,
				method: 'POST',
				mode: 'cors',
				credentials: 'include',
			});
			const data: IArticleResponse = await res.json();

			if (!isMounted) return;

			if (data.data?.article) {
				setArticle(data.data.article);
			}

			setLoading(false);
		};
		getArticle();

		return () => {
			isMounted = false;
		};
	}, [id]);

	if (loading) return <p>Loading...</p>;

	if (!article) return <p>Article not found</p>;

	return (
		<main>
			<header className="header">
				<a href="https://footballexchange.com">
					<img
						src={Logo}
						alt="Football Exchange"
						className="header__logo"
					/>
				</a>
			</header>
			<div className="article">
				<h1 className="article__title">{article.title}</h1>
				<span className="article__info">
					{new Date(article.created).toLocaleString()} |{' '}
					{article.publisher}
				</span>
				<img
					src={article.image}
					alt={article.teaser}
					className="article__image"
				/>
				<span className="article__teaser">{article.teaser}</span>
				<div
					className="article__body"
					// eslint-disable-next-line
					dangerouslySetInnerHTML={{
						__html: article.body,
					}}
				/>
			</div>
		</main>
	);
};

export default PublicArticle;
