import React from 'react';
import { Resource } from 'react-admin';
import ArticleIcon from '@mui/icons-material/VideoCall';
import { LivingRoomList } from '../living-room.list';
import { LivingRoomShow } from '../living-room.show';
import { LivingRoomCreate } from '../living-room.create';
import { LivingRoomEdit } from '../living-room.edit';

const PastLivingRoomData = () => {
	return (
		<Resource
			name="livingRooms"
			options={{
				label: 'Living Rooms',
			}}
			show={LivingRoomShow}
			list={LivingRoomList}
			create={LivingRoomCreate}
			edit={LivingRoomEdit}
			icon={ArticleIcon}
		/>
	);
};

export default PastLivingRoomData;
