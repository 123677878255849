import React from 'react';
import { gql, useQuery } from 'urql';
import Welcome from './Welcome';
import TotalUsers from './cards/TotalUsers';
import NewUsersChart from './charts/NewUsers';
import MembersTypesChart from './charts/UserTypesChart';

const totalUsersQuery = gql`
	query totalUsers {
		totalUsers {
			activated
			unactivated
		}
	}
`;
const Spacer = () => <span style={{ width: '1em' }} />;
const Dashboard = () => {
	const [users] = useQuery({
		query: totalUsersQuery,
	});

	// const [api, setAPI] = useState<null | boolean>(null);
	// const [dns, setDNS] = useState<null | boolean>(null);
	// const [database, setDatabase] = useState<null | boolean>(null);

	// useEffect(() => {
	// 	const getStatus = async () => {
	// 		if (!process.env.REACT_APP_API) return;
	// 		const fqdn = process.env.REACT_APP_API.replace('graphql', 'status');
	// 		const res = await fetch(fqdn);
	// 		const data = (await res.json()) as {
	// 			status: string;
	// 			info?: {
	// 				dns?: {
	// 					status?: string;
	// 				};
	// 				database?: {
	// 					status?: string;
	// 				};
	// 			};
	// 		};

	// 		const { status, info } = data;

	// 		setAPI(status === 'ok');

	// 		if (info) {
	// 			const { dns: dnsStatus, database: databaseStatus } = info;
	// 			if (dnsStatus) setDNS(dnsStatus.status === 'up');
	// 			if (databaseStatus) setDatabase(databaseStatus.status === 'up');
	// 		}
	// 	};

	// 	const poll = setInterval(getStatus, 2000);

	// 	return () => clearInterval(poll);
	// });
	return (
		<>
			<Welcome />
			<div className="my-4 flex">
				<NewUsersChart />
				<span style={{ width: '12em' }} />
				<MembersTypesChart />
			</div>
			{/* <div className="flex">
				<StatusCard name="API" status={api} icon={<APIIcon />} />
				<Spacer />
				<StatusCard name="DNS" status={dns} icon={<DNSIcon />} />
				<Spacer />
				<StatusCard
					name="Database"
					status={database}
					icon={<DatabaseIcon />}
				/>
			</div> */}
			<div className="flex justify-between">
				<TotalUsers
					value={users?.data?.totalUsers.activated ?? 0}
					type="activated"
				/>
				<Spacer />
				<TotalUsers
					value={users?.data?.totalUsers.unactivated ?? 0}
					type="unactivated"
				/>
			</div>
		</>
	);
};

export default Dashboard;
