import React, { useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { getPrettyErrorMessage } from '../../../helpers';
import { useRequestCodeMutation } from './hooks/useRequestCodeMutation';

interface EnterCodeProps {
	phone: string;
	setPhone: React.Dispatch<React.SetStateAction<string>>;
	setEnterCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnterCode = ({ phone, setPhone, setEnterCode }: EnterCodeProps) => {
	const [requestCode, { loading }] = useRequestCodeMutation();

	const [errorMessage, setErrorMessage] = useState<string>();

	const handleSubmit = async () => {
		if (!phone)
			return setErrorMessage('Invalid phone number or email address');

		try {
			const { errors, data } = await requestCode({
				variables: {
					phone,
				},
			});

			if (errors?.[0]) return setErrorMessage(errors[0].message);

			if (data?.requestCode) {
				setErrorMessage(undefined);
				setEnterCode(true);
			}
		} catch (err) {
			if (err?.message)
				setErrorMessage(getPrettyErrorMessage(err.message));
		}
	};

	return (
		<>
			<p className="font-light mt-2">Not to worry, we&apos;ll sort it.</p>
			<p className="font-light mt-3">
				Please enter the phone number or email associated with your
				account, a code will be sent here.
			</p>
			<form
				className="flex flex-col w-full mt-10"
				onSubmit={(e) => {
					e.preventDefault();
					return handleSubmit();
				}}
			>
				<Input
					label="Email or Phone number (with country code)"
					type="text"
					className="mb-4 opacity-75"
					disabled={loading ?? false}
					value={phone}
					placeholder="Email or Phone Number"
					onChange={(e) => setPhone(e.target.value)}
				/>

				<p className="text-sm text-red-400 mb-4">{errorMessage}</p>

				<Button type="submit" value="submit" disabled={loading}>
					{loading ? 'Sending...' : 'Send Code'}
				</Button>
			</form>
		</>
	);
};

export default EnterCode;
