import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router';
import CheckoutForm from './CheckoutForm';
import { usePaymentQuery } from './hooks/usePaymentQuery';

interface OptionsType {
	clientSecret: string;
}

const StripePayment = () => {
	const navigate = useNavigate();
	const [aks, setAks] = useState(null);
	const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
	const [options, setOptions] = useState<OptionsType | null>(null); // Use null as the initial value
	const params = new URLSearchParams(window.location.search);
	const id = params.get('id');

	const accessToken = localStorage.getItem('accessToken');
	const [getPayment] = usePaymentQuery();
	useEffect(() => {
		const fetchData = async () => {
			if (accessToken) {
				try {
					const aksResponse = await getPayment({
						variables: {
							subscriptionId: id,
						},
						context: {
							headers: {
								Authorization: `Bearer ${accessToken}`,
							},
						},
					});

					if (!aksResponse.data) {
						navigate(`/login?type=payment&id=${id}`);
						return;
					}
					if (!aksResponse.data?.payment?.paymentId) {
						navigate(`/account?type=payment&id=${id}`);
						return;
					}

					setAks(aksResponse.data);

					const stripe = await loadStripe(
						process.env.REACT_APP_STRIPE_PUBLISH ??
							'pk_test_51IudWKLP7KCVbUfVSNxWQY3HwTQIos8EhuCFJ0EZPEcxLVGWF65zNWgByv6OIaTVq2jkGmX6UNe3kJNqw5AVBgCh00RbirkUKg',
					);
					setStripePromise(stripe);
					setOptions({
						clientSecret: aksResponse.data?.payment?.paymentId,
					});
				} catch (error) {
					console.error('Error fetching payment data:', error);
				}
			} else {
				navigate(`/login?type=payment&id=${id}`);
			}
		};

		fetchData();
	}, [getPayment, accessToken, id, navigate]);

	// Check if both aks and stripePromise are available before rendering Elements
	return accessToken && aks && stripePromise && options?.clientSecret ? (
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm
				paymentIntent={
					aks.payment?.userSubscriptionObject.paymentIntentId
				}
			/>
		</Elements>
	) : (
		<p>Loading payment data...</p>
	);
};

export default StripePayment;
