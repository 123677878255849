import { gql } from 'urql';

const auth = {
	currentUser: gql`
		query getCurrentUser {
			me {
				id
				name
			}
		}
	`,
	login: gql`
		mutation login($email: String!, $password: String!) {
			login(email: $email, password: $password) {
				accessToken
				refreshToken
				role
			}
		}
	`,
	refresh: gql`
		mutation refresh {
			refresh {
				accessToken
				refreshToken
			}
		}
	`,
	logout: gql`
		mutation logout {
			logout
		}
	`,
};

export default auth;
