export type UserEnums = UserEducation | UserGeneration | UserMobileOS;

export enum UserEducation {
	SOME_OR_NO_COLLEGE = 'SOME_OR_NO_COLLEGE',
	ASSOCIATES_DEGREE_OR_APPRENTICESHIP = 'ASSOCIATES_DEGREE_OR_APPRENTICESHIP',
	BACHELORS_DEGREE = 'BACHELORS_DEGREE',
	MASTERS_DEGREE = 'MASTERS_DEGREE',
	DOCTORAL_OR_PROFESSIONAL_DEGREE = 'DOCTORAL_OR_PROFESSIONAL_DEGREE',
}

export enum UserGeneration {
	BABY_BOOMER = 'BABY_BOOMER',
	GEN_X = 'GEN_X',
	MILLENNIAL = 'MILLENNIAL',
	GEN_Z = 'GEN_Z',
}

export enum UserMobileOS {
	APPLE = 'APPLE',
	ANDROID = 'ANDROID',
}

export enum YesNoAvailability {

	Yes = 'Yes',
  
	No = 'No',
  
	NotAvailable = 'NotAvailable',
  
  }

export function toEnum<T extends UserEnums>(value: string): T {
	return value
		.toUpperCase()
		.replace(/\s|-|\//g, '_')
		.replace(/&/g, 'AND')
		.replace(/'|,/g, '') as T;
}
