import React from 'react';
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin';

const ImageFormatDropDownInput = (props: AutocompleteInputProps) => {
	return (
		<AutocompleteInput
			{...props}
			choices={[
				{ id: 'SMALL', name: 'Small' },
				{ id: 'MEDIUM', name: 'Medium' },
				{ id: 'LARGE', name: 'Large' },
				{ id: 'EXTRA_LARGE', name: 'Extra Large' },
			]}
		/>
	);
};

export default ImageFormatDropDownInput;
