import React from 'react';
import CustomCarousel from '../../../components/CustomCarousel';

import page1 from '../../../assets/graphs/page1.png';
import page2 from '../../../assets/graphs/page2.png';
import page3 from '../../../assets/graphs/page3.png';

const images = [page1, page2, page3];

const ShowGraphsPage = () => {
	return (
		<div className="w-full h-full p-4">
			{/* <h1 className="text-2xl mb-4">Graphs</h1> */}
			<CustomCarousel images={images} />
		</div>
	);
};

export default ShowGraphsPage;
