import { TitleProps } from 'react-admin';
import React from 'react';
import ActivateButton from './activate.button';

type ExternalButtonProps = TitleProps;
const ExternalActivateButton = ({ record }: ExternalButtonProps) => {
	return <ActivateButton selectedIds={[String(record?.id) ?? '']} single />;
};

export default ExternalActivateButton;
