import React, { useCallback } from 'react';
import {
	List,
	Datagrid,
	TextField,
	ListProps,
	DateField,
	TopToolbar,
	CreateButton,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { ScheduleListEmpty } from './components/schedule-list.empty';
import ScheduleFilter from './schedule.filter';

type IScheduleListProps = ListProps;

export const ScheduleList = (props: IScheduleListProps) => {
	const navigate = useNavigate();

	const ListActions = useCallback(
		(otherProps: any) => (
			<TopToolbar>
				<CreateButton
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						navigate('/admin/schedule/create');
					}}
				/>
			</TopToolbar>
		),
		[navigate],
	);

	return (
		<List
			sort={{
				field: 'created',
				order: 'desc',
			}}
			bulkActionButtons={false}
			filters={<ScheduleFilter />}
			actions={<ListActions />}
			empty={<ScheduleListEmpty />}
			title="Daily Engagement Posts"
			{...props}
		>
			<Datagrid
				rowClick={(id, resource, record) =>
					`/admin/${record.__typename.toLowerCase()}s/${id}/show`
				}
				bulkActionButtons={false}
			>
				<TextField source="title" sortable={false} />
				<TextField source="__typename" label="Type" sortable={false} />
				<DateField label="Start" source="schedule.start" showTime />
				<DateField label="End" source="schedule.end" showTime />
			</Datagrid>
		</List>
	);
};
