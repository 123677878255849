import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITextAreaProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {
	label: string;
	rows?: number;
	className?: string;
	fullWidth?: boolean;
}
const TextArea = ({
	label,
	fullWidth,
	rows,
	children,
	className,
	disabled,
	...props
}: ITextAreaProps) => {
	return (
		<div
			className={`flex flex-col items-start${fullWidth ? ' w-full' : ''}`}
		>
			<span className="text-sm">{label}</span>
			<textarea
				rows={rows ?? 3}
				className={`border border-gray-300 rounded p-2 w-full outline-none focus:border-blue-400 resize-none ${
					disabled && 'opacity-75'
				} ${className}`}
				disabled={disabled ?? false}
				{...props}
			/>
		</div>
	);
};

export default TextArea;
