import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';

interface IClientWrapper {
	children: React.ReactNode;
}

/**
 * Provide a new, basic implementation of Apollo Client outside RA provided client
 * @param param0 Children
 * @returns Wrapper component
 */
const ClientWrapper = ({ children }: IClientWrapper) => {
	const client = new ApolloClient({
		uri: process.env.REACT_APP_API,
		cache: new InMemoryCache(),
	});

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ClientWrapper;
