import React from 'react';
import {
	Chart as ChartJS,
	PointElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { monthCount, SessionCount } from '../sessionsQuery';

ChartJS.register(
	PointElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	LineElement,
);

export const SeesionsMonthlyChart = () => {
	SessionCount();
	const data = {
		labels: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
		datasets: [
			{
				label: '2021',
				data: [
					monthCount.jan2021,
					monthCount.feb2021,
					monthCount.mar2021,
					monthCount.apr2021,
					monthCount.may2021,
					monthCount.jun2021,
					monthCount.jul2021,
					monthCount.aug2021,
					monthCount.sep2021,
					monthCount.oct2021,
					monthCount.nov2021,
					monthCount.dec2021,
				],
				fill: true,
				backgroundColor: 'rgba(75,192,192,0.2)',
				borderColor: 'rgba(75,192,192,1)',
			},
			{
				label: '2022',
				data: [
					monthCount.jan2022,
					monthCount.feb2022,
					monthCount.mar2022,
					monthCount.apr2022,
					monthCount.may2022,
					monthCount.jun2022,
					monthCount.jul2022,
					monthCount.aug2022,
					monthCount.sep2022,
					monthCount.oct2022,
					monthCount.nov2022,
					monthCount.dec2022,
				],
				fill: false,
				borderColor: '#742774',
			},
		],
	};

	return (
		<div className="App">
			<Line
				data={data}
				width={360}
				height={320}
				options={{
					maintainAspectRatio: false,
				}}
			/>
		</div>
	);
};
