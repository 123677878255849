import { BaseFormatter } from './default.formatter';
import { IFormatter } from './formatter.interface';
import { LivingRoomFormatter } from './living-room.formatter';
import { MilestoneFormatter } from './milestone.formatter';
import { UserFormatter } from './user.formatter';

export class FormatterFactory {
	getFormatter(resource: string): IFormatter {
		switch (resource) {
			case 'milestones':
				return new MilestoneFormatter();
			case 'users':
				return new UserFormatter();
			case 'livingRooms':
				return new LivingRoomFormatter();
			default:
				return new BaseFormatter();
		}
	}
}
