/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import {
	useRefresh,
	useNotify,
	SimpleForm,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import { Button, TextField } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { useState } from 'react';
import Confirm from '../../components/Confirm';

interface BroadcastNotificationButtonProps {
	resource?: string;
}

const BroadcastNotificationButton = ({
	resource = 'custom',
}: BroadcastNotificationButtonProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');
	const [subtitle, setSubtitle] = useState<string>('');
	const [body, setBody] = useState<string>('');
	const refresh = useRefresh();
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const { mutate: broadcastNotification, isLoading } = useMutation(
		() =>
			dataProvider.broadcastNotification({
				body,
				title,
				subtitle,
			}),
		{
			onSuccess: () => {
				refresh();
				notify('Broadcasted push notification');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => {
		setTitle('');
		setSubtitle('');
		setBody('');
		setOpen(false);
	};

	const handleConfirm = () => {
		broadcastNotification();
		handleDialogClose();
	};

	return (
		<>
			<Button
				disabled={isLoading}
				onClick={handleClick}
				variant="contained"
				startIcon={<SpeakerNotesIcon />}
			>
				Send Notification
			</Button>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title="Broadcast Notification App Wide"
				content="Are you sure you want to broadcast a push notification notification for all users of the app? Doing such is irreversible"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			>
				<SimpleForm toolbar={<></>}>
					<TextField
						label="Notification Title"
						fullWidth
						multiline
						required
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<TextField
						label="Notification Subtitle"
						fullWidth
						multiline
						required
						value={subtitle}
						onChange={(e) => setSubtitle(e.target.value)}
					/>
					<TextField
						label="Notification Body"
						fullWidth
						multiline
						required
						value={body}
						onChange={(e) => setBody(e.target.value)}
					/>
				</SimpleForm>
			</Confirm>
		</>
	);
};

export default BroadcastNotificationButton;
