import React from 'react';

type DescriptionProps = {
  description: string[];
  type?: string; // Add type from parent section
};

const Description = ({ description, type }: DescriptionProps) => {
  if (description.length === 0) return null;

  const className = type === 'phone' 
    ? 'lg:text-xl text-lg mb-10 text-gray-700 text-left'
    : 'lg:text-xl text-lg my-6 text-gray-700 text-center';

  return (
    <div>
      {description.map((line) => (
        <p className={className} key={line}>
          {line}
        </p>
      ))}
    </div>
  );
};

export default Description;