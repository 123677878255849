import { RaRecord as Record } from 'react-admin';
import { BaseFormatter } from './default.formatter';

/* eslint no-param-reassign: "error" */
export class LivingRoomFormatter extends BaseFormatter {
	public create<D extends Record, O extends Record>(data: D): O {
		if (data.milestones) {
			const milestones = new Set();
			const pillars = new Set();

			(data.milestones as string[]).forEach((id) => {
				const [pillar, milestone] = id.split(':');

				pillars.add(pillar);
				milestones.add(milestone);
			});

			(data.milestones as unknown[]) = [...milestones];
			(data.pillars as unknown[]) = [...pillars];
		}

		return data as unknown as O;
	}

	public update<D extends Record, O extends Record>(data: D): O {
		if (data.milestones) {
			const milestones = new Set();
			const pillars = new Set();

			(data.milestones as string[]).forEach((id) => {
				const [pillar, milestone] = id.split(':');

				pillars.add(pillar);
				milestones.add(milestone);
			});

			(data.milestones as unknown[]) = [...milestones];
			(data.pillars as unknown[]) = [...pillars];
		}

		delete data.hasStarted;
		delete data.url;
		delete data.registrations;
		delete data.meetingUrl;
		if (data.header === '') delete data.header;

		return data as unknown as O;
	}

	one(data: any) {
		if (data.milestones) {
			data.milestones = data.milestones.map(
				(milestone: string, index: number) =>
					`${data?.pillars[index]}:${milestone}`,
			);
		}

		return { data };
	}
}

/* eslint no-param-reassign: "error" */
