/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useBasicLoginMutation } from './hooks/useBasicLoginMutation';
import Header from '../../components/Header';

const Login = () => {
	const navigate = useNavigate();

	const [loginUser, { loading }] = useBasicLoginMutation();

	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>();

	const handleSubmit = async () => {
		if (!password || password.length < 8)
			return setErrorMessage(
				'Your password must be at least 8 characters long',
			);

		try {
			const { data, errors } = await loginUser({
				variables: {
					email,
					password,
				},
			});

			if (errors?.[0]) return setErrorMessage(errors[0].message);

			if (!data?.basicLogin || !data.basicLogin?.accessToken)
				setErrorMessage('Invalid email/password combination');
			else {
				localStorage.setItem(
					'accessToken',
					data?.basicLogin.accessToken,
				);
				const params = new URLSearchParams(window.location.search);
				const id = params.get('id');
				if (id != null) {
					navigate(`/payment?type=payment&id=${id}`);
				} else {
					navigate('/account');
				}
			}
		} catch (err) {
			if (err?.message)
				setErrorMessage(
					err?.message.replace(/\[([A-Z_])*\](:)?(\s)?/i, ''),
				);
		}
	};

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			<Container>
				<Header title="Hello, there!" />
				<p className="font-light">
					Please login to proceed to manage your account
				</p>
				<form
					className="flex flex-col w-full mt-10"
					onSubmit={(e) => {
						e.preventDefault();
						return handleSubmit();
					}}
				>
					<Input
						label="Email"
						type="email"
						className="mb-4"
						disabled={loading ?? false}
						value={email}
						placeholder="Your email"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Input
						label="Password"
						type="password"
						placeholder="Your password"
						className="mb-4"
						disabled={loading ?? false}
						required
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<a
						className="text-black text-xs cursor-pointer text-left underline"
						href="/forgot"
						onClick={(e) => {
							e.preventDefault();
							navigate('/forgot');
						}}
					>
						Forgotten your password?
					</a>
					<p className="text-sm text-red-400 mb-4 mt-3">
						{errorMessage}
					</p>

					<Button
						type="submit"
						value="submit"
						disabled={!password || loading}
					>
						{!loading ? 'Login' : 'Logging in...'}
					</Button>
				</form>
			</Container>
		</main>
	);
};

export default Login;
