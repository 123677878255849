import { DocumentNode } from 'graphql';
import { gql } from 'urql';
import { IMutations } from '.';
import fragments from '../fragments';

const deletes: IMutations = {
	users: gql`
		mutation deleteUser($id: ID!) {
			adminUserDelete(id: $id) {
				id
				firstName
				lastName
				email
				created
				updated
			}
		}
	`,
	reports: gql`
		${fragments.reports as DocumentNode}
		mutation deleteReport($id: ID!) {
			reportDelete(id: $id) {
				...ReportDetails
			}
		}
	`,
	livingRooms: gql`
		${fragments.livingRooms as DocumentNode}
		mutation deleteLivingRoom($id: ID!) {
			livingRoomDelete(id: $id) {
				...LivingRoomDetails
			}
		}
	`,
	quotes: gql`
		mutation deleteQuote($id: ID!) {
			quoteDelete(id: $id) {
				id
				author
				backgroundColour
				created
				quote
				title
				updated
			}
		}
	`,
	freeTexts: gql`
		mutation deleteFreeText($id: ID!) {
			freeTextDelete(id: $id) {
				id
				backgroundColour
				imageSubTitle
				imageTitle
				imageFormat
				imageURL
				created
				updated
				title
				description
			}
		}
	`,
	polls: gql`
		mutation deletePoll($id: ID!) {
			pollDelete(id: $id) {
				id
				created
				question
				choices
				results {
					answer
					percentage
				}
				title
				updated
				backgroundColour
			}
		}
	`,
};

export default deletes;
