import React from 'react';
import { ListProps } from 'react-admin';
import { Tab, Container, Grid } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoFunnelOutline } from 'react-icons/io5';
import './livingRoom.css';
import DropDown from './components/YearDropdown';
import {
	TotalAuthorsCount,
	TotalLivingCount,
} from './components/sessionsQuery';
import { ActiveSessionContent } from './components/Tabs/activeSessions';
import { AttendeesSessionContent } from './components/Tabs/attendeesSession';
import { AuthorsSessionContent } from './components/Tabs/authorsSeesion';

type ILivingRoomListProps = ListProps;

const Spacer = () => <span style={{ width: '1.5em' }} />;

const yearData: { label: string; value: string }[] = [
	{ label: '2021', value: '2021' },
	{ label: '2022', value: '2022' },
	{ label: '2023', value: '2023' },
];

const tabStyles = {
	width: '250px',
	height: '70px',
	padding: '10px',
	display: 'flex',
	marginTop: '10px',
	marginLeft: '10px',
	marginRight: '10px',
	marginBottom: '0px',
	fontFamily: 'Roboto',
	fontWeight: '900',
	fontSize: '16px',
	backgroundColor: 'white',
};

export const LivingRoomPage = (props: ILivingRoomListProps) => {
	const [tab, setTab] = React.useState('11');
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue);
	};

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Container
				style={{
					overflowX: 'hidden',
				}}
			>
				<TabContext value={tab}>
					<Grid>
						<TabList
							onChange={handleChangeTab}
							aria-label="tabs"
							style={{
								height: '72px',
							}}
						>
							<Tab
								style={tabStyles}
								label={
									<span
										style={{
											fontFamily: 'Roboto',
											color: 'black',
										}}
									>
										Total Sessions <br />
										<TotalLivingCount />
									</span>
								}
								value="11"
							/>
							<Tab
								style={tabStyles}
								label={
									<span
										style={{
											fontFamily: 'Roboto',
											color: 'black',
										}}
									>
										Authors <br />
										<TotalAuthorsCount />
									</span>
								}
								value="12"
							/>
							<Tab
								style={tabStyles}
								label={
									<span
										style={{
											fontFamily: 'Roboto',
											color: 'black',
										}}
									>
										Attendees <br />
										200
									</span>
								}
								value="13"
							/>
							<Spacer />
							<div
								style={{
									float: 'right',
									display: 'flex',
								}}
							>
								<div
									style={{
										height: '40px',
										width: '42px',
									}}
								>
									<Button
										id="basic-button"
										aria-controls={
											open ? 'basic-menu' : undefined
										}
										aria-haspopup="true"
										aria-expanded={
											open ? 'true' : undefined
										}
										onClick={handleClick}
										style={{
											marginTop: '10px',
										}}
									>
										<IoFunnelOutline
											size={40}
											style={{
												paddingTop: '20px',
											}}
										/>
									</Button>
									<Menu
										id="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										<MenuItem onClick={handleClose}>
											Monthly
										</MenuItem>
										<MenuItem onClick={handleClose}>
											Quarterly
										</MenuItem>
										<MenuItem onClick={handleClose}>
											Yearly
										</MenuItem>
									</Menu>
								</div>
								<DropDown
									data={yearData}
									width={200}
									label="Time Period"
									size="small"
								/>
							</div>
						</TabList>
					</Grid>
					<TabPanel value="11">
						<ActiveSessionContent />
					</TabPanel>
					<TabPanel value="12">
						<AuthorsSessionContent />
					</TabPanel>
					<TabPanel value="13">
						<AttendeesSessionContent />
					</TabPanel>
				</TabContext>
			</Container>
		</>
	);
};
