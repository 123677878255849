import React from 'react';
import { ISupportReply } from '../graphql/queries/getSupportRequest';

type SupportReplyProps = {
	reply: ISupportReply;
	defaultName?: string;
};

const SupportReply = ({ reply, defaultName }: SupportReplyProps) => {
	return (
		<div className="flex flex-col w-full mb-3">
			<div className="flex flex-row justify-between w-full bg-black text-white rounded-t p-3 items-center">
				<div className="flex flex-row">
					<p className="font-bold">
						{reply?.staff?.name
							? reply.staff.name
							: defaultName ?? 'User'}
					</p>
					{reply?.staff && (
						<div>
							<span className="bg-white rounded text-black font-black text-xs px-1 ml-2">
								STAFF
							</span>
						</div>
					)}
				</div>
				<div>
					<p className="text-sm">
						{new Date(reply.created).toLocaleString()}
					</p>
				</div>
			</div>
			<div className="w-full border border-black rounded-b p-3">
				<p
					className="text-left"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: reply.body.replace('\n', '<br>'),
					}}
				/>
			</div>
		</div>
	);
};

export default SupportReply;
