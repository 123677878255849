import React from 'react';
import { useRecordContext } from 'react-admin';

export const MilestoneRow = () => {
	const record = useRecordContext();

	return (
		<div className="flex flex-col">
			<p className="text-xs text-gray-400">{record?.name}</p>
			<p>{record?.id.toString().split(':')[1]}</p>
		</div>
	);
};
