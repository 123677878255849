import { gql } from '@apollo/client';
import { UserSubscriptionEnum } from '../../../../resources/users/user.options';

export const GetMeInfoQuery = gql`
	query GetMeInfo {
		me {
			id
			firstName
			email
			subscription
			hasUsedFreeTrial
		}
	}
`;

export interface IGetMeInfoQueryData {
	me: {
		id: string;
		email: string;
		firstName: string;
		subscription: UserSubscriptionEnum;
		hasUsedFreeTrial?: boolean;
	};
}
