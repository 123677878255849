import * as React from 'react';
import { FC, useCallback, MouseEventHandler } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ActionCheck from '@mui/icons-material/CheckCircle';
import AlertError from '@mui/icons-material/ErrorOutline';
import classnames from 'classnames';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
	(theme) => ({
		iconPaddingStyle: {
			paddingRight: '0.5em',
		},
	}),
	{ name: 'RaConfirm' },
);

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
const Confirm: FC<ConfirmProps> = (props) => {
	const {
		isOpen,
		loading,
		title,
		content,
		confirm,
		cancel,
		ConfirmIcon,
		CancelIcon,
		onClose,
		onConfirm,
		translateOptions = {},
		children,
	} = props;
	const classes = useStyles(props);
	const translate = useTranslate();

	const handleConfirm = useCallback(
		(e) => {
			e.stopPropagation();
			onConfirm(e);
		},
		[onConfirm],
	);

	const handleClick = useCallback((e) => {
		e.stopPropagation();
	}, []);

	return (
		<Dialog
			open={!!isOpen}
			onClose={onClose}
			onClick={handleClick}
			aria-labelledby="alert-dialog-title"
		>
			<DialogTitle id="alert-dialog-title">
				{translate(title, { _: title, ...translateOptions })}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{translate(content, {
						_: content,
						...translateOptions,
					})}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button disabled={loading} onClick={onClose}>
					<CancelIcon className={classes.iconPaddingStyle} />
					{translate(cancel ?? '', { _: cancel })}
				</Button>
				<Button
					disabled={loading}
					onClick={handleConfirm}
					className={classnames(
						'ra-confirm',
						// , {
						// 	[classes.confirmWarning]: confirmColor === 'warning',
						// 	[classes.confirmPrimary]: confirmColor === 'primary',
						// }
					)}
					autoFocus
				>
					<ConfirmIcon className={classes.iconPaddingStyle} />
					{translate(confirm ?? '', { _: confirm })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export interface ConfirmProps {
	cancel?: string;
	confirm?: string;
	children?: React.ReactNode;
	confirmColor?: string;
	CancelIcon?: any;
	ConfirmIcon?: any;
	content: string;
	isOpen?: boolean;
	loading?: boolean;
	onClose: MouseEventHandler;
	onConfirm: MouseEventHandler;
	title: string;
	translateOptions?: Record<string, unknown>;
}

Confirm.defaultProps = {
	cancel: 'ra.action.cancel',
	confirm: 'ra.action.confirm',
	confirmColor: 'primary',
	ConfirmIcon: ActionCheck,
	CancelIcon: AlertError,
	isOpen: false,
};

export default Confirm;
