import React, { memo, useState } from 'react';
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface ICreateAccount {
	data: any;
	handleSubmit: (password: string) => any;
	errorMessage: string;
}
const CreateAccount = ({data, handleSubmit, errorMessage}: ICreateAccount) => {
	const [formData, setFormData] = useState<any>({
		email: data.email,
		currentPassword: '',
	});
	const [errors, setErrors]: any = useState({});
	const [showPassword, setShowPassword] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const validateForm = () => {
		const newErrors: any = {};

		// Email Validation
		if (!/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = 'Please enter a valid email address.';
		}

		// Password Validation (minimum 8 characters)
		if (!formData.currentPassword || formData.currentPassword.length < 8) {
			newErrors.currentPassword =
				'Password must be at least 8 characters long.';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmitPassword = (e) => {
		e.preventDefault();
		if (validateForm()) {
			// Proceed with form submission
			handleSubmit(formData.currentPassword);
		}
	};
	const togglePasswordVisibility = () => {
		setShowPassword((prev) => !prev);
	  };

	return (
		<div className="bg-white p-6 rounded-lg h-dvh px-8 md:px-24 py-8">
			<div className="flex justify-center mb-8 md:mb-4">
				<img
					src="/assets/logo.png"
					alt="Logo"
					className="w-44 md:w-56"
				/>
			</div>
			<div className="flex flex-col items-center mt-5 mb-50 justify-center rounded rounded-lg md-custom-border">
				<div className="flex w-full md:w-2/4 justify-center mb-4">
					<h1 className="text-black font-abril text-4xl mb-8 md:md-0">
						Create Account
					</h1>
				</div>
				<form className="w-full md:w-2/4" onSubmit={handleSubmitPassword}>
					<div className="flex flex-col space-y-4 w-full">
						{/* Email Address Field */}
						<div className="flex flex-col space-y-3">
							<span className="text-black font-kreon text-lg">
								Email Address
							</span>
							<input
								className="h-[45px] text-base py-3 px-0 account-setting-input"
								placeholder="Email Address"
								name="email"
								type="email"
								disabled
								value={formData.email}
								onChange={handleInputChange}
							/>
							{errors.email && (
								<p className="text-red-500 text-sm">
									{errors.email}
								</p>
							)}
						</div>
				
						{/* Label */}
						<span className="text-black font-kreon text-lg">Password</span>

						{/* Password Input */}
						<div className="relative">
							<input
							className="h-[45px] w-full text-base py-3 px-3 pr-10 account-setting-input"
							placeholder="Create Password"
							name="currentPassword"
							type={showPassword ? "text" : "password"}
							value={formData.currentPassword}
							onChange={handleInputChange}
							/>
							{/* Eye Icon */}
							<button
								type="button"
								className="absolute inset-y-0 right-3 flex items-center"
								onClick={togglePasswordVisibility}
								aria-label={showPassword ? "Hide password" : "Show password"}
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</button>
						</div>
						{errors.currentPassword && (
							<p className="text-red-500 text-sm">
								{errors.currentPassword}
							</p>
						)}

						{/* Submit Button */}
						<p className="text-sm text-red-400 mb-4 mt-2">{errorMessage}</p>
						<div className="flex justify-center space-x-4 mt-6 mb-6">
							<button
								type="submit"
								className="w-full text-lg h-[50px] bg-black text-white font-semibold p-2 rounded shadow-lg hover:from-blue-600 hover:to-blue-800 transition duration-200"
							>
								Continue
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
export default memo(CreateAccount);
