import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import {
	BooleanInput,
	DateTimeInput,
	SimpleForm,
	TextInput,
	Title,
	useDataProvider,
	useNotify,
	useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ColorDropDownInput from '../../components/ColorDropdownInput';

type PollCreateInput = {
	author?: string;
	quote?: string;
	backgroundColour?: string;
	showQuotationMark?: boolean;
	title?: string[];
	start?: Date;
	end?: Date;
};

const QuoteCreatePage = () => {
	const [variables, setVariables] = useState<PollCreateInput>({
		showQuotationMark: false,
	});

	const refresh = useRefresh();
	const notify = useNotify();
	const navigate = useNavigate();
	const dataProvider = useDataProvider();

	const { mutate: quoteCreate } = useMutation(
		() => dataProvider.quoteCreate({ input: variables }),
		{
			// undoable: true,
			onSuccess: (result: any) => {
				refresh();
				if (result?.data?.id) {
					navigate(`/admin/quotes/${result?.data?.id}/show`);
				}
				notify('Element Created');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	return (
		<Card sx={{ mt: 2 }}>
			<Title title="Create Quote" />
			<CardContent>
				<SimpleForm
					onSubmit={() => {
						quoteCreate();
					}}
				>
					<TextInput
						helperText="Quote's Title (e.g. `Quote of the Week`)"
						source="title"
						required
						onChange={(e) => {
							setVariables({
								...variables,
								title: e.target.value,
							});
						}}
					/>

					<TextInput
						helperText="Quote e.g. `Women are said to be...`"
						source="quote"
						required
						onChange={(e) => {
							setVariables({
								...variables,
								quote: e.target.value,
							});
						}}
					/>

					<TextInput
						helperText="Quote's Author (e.g. `Jane Doe`)"
						source="author"
						required
						onChange={(e) => {
							setVariables({
								...variables,
								author: e.target.value,
							});
						}}
					/>

					<ColorDropDownInput
						helperText="Background Colour of the Post (e.g Finance Green)"
						source="background"
						isRequired
						label="Background Color"
						onChange={(e) => {
							setVariables({
								...variables,
								backgroundColour: e,
							});
						}}
					/>

					<BooleanInput
						source="showQuotationMark"
						label="Show Quotation Marks"
						onChange={(e) => {
							if (e.target) {
								setVariables({
									...variables,
									showQuotationMark: e.target.checked,
								});
							}
						}}
					/>

					<DateTimeInput
						helperText="Quote's Scheduled Start Time"
						source="start"
						required
						label="Start Time"
						onChange={(e) => {
							if (e.target.value)
								setVariables({
									...variables,
									start: new Date(e.target.value),
								});
						}}
					/>

					<DateTimeInput
						helperText="Quote's Scheduled End Time"
						source="end"
						required
						label="End Time"
						onChange={(e) => {
							if (e.target.value)
								setVariables({
									...variables,
									end: new Date(e.target.value),
								});
						}}
					/>
				</SimpleForm>
			</CardContent>
		</Card>
	);
};

export default QuoteCreatePage;
