import React from 'react';

type ContainerProps = {
	children: React.ReactNode;
	large?: boolean;
	icon?: 'book' | 'jumping' | 'sofa';
};

const Container = ({ children, large, icon = 'book' }: ContainerProps) => {
	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			<div className="flex flex-row w-full h-screen">
				<div className="w-full md:1/2 md:flex lg:w-2/5 bg-yellowed h-full">
					<div className="flex w-full h-full justify-center items-center text-left">
						<div
							className={`w-full h-full lg:max-w-${
								large ? 'xl' : 'md'
							} flex flex-col justify-center p-10 text-left`}
						>
							{children}
						</div>
					</div>
				</div>
				<div className="hidden md:w-1/2 md:flex lg:w-3/5 bg-oldYellow h-full">
					<div className="flex w-full h-full items-center justify-center">
						<div className="flex flex-col items-center">
							<img
								alt="Uplevyl"
								className="h-36 xl:h-40 mb-1"
								src="https://cdn.uplevyl.com/logo/full/grey.svg"
							/>
							<p className="font-light mb-10">
								Redesigning the World for Women
							</p>
							<img
								alt="Uplevyl"
								className={`h-40 xl:h-56 mt-20 xl:mt-40 ${
									icon === 'jumping' ? 'invert' : ''
								}`}
								src={`https://cdn.uplevyl.com/icons/${icon}.svg`}
							/>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Container;
