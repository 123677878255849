import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	BasicLoginMutation,
	IBasicLoginMutationVariables,
	IBasicLoginMutationData,
} from '../graphql/mutations/basicLogin';

/**
 * Basic login user for basic web actions (payment portal etc)
 * @param options
 * @returns
 */
export const useBasicLoginMutation = (
	options?: MutationHookOptions<
		IBasicLoginMutationData,
		IBasicLoginMutationVariables
	>,
) =>
	useMutation<IBasicLoginMutationData, IBasicLoginMutationVariables>(
		BasicLoginMutation,
		options,
	);
