import { makeStyles } from '@mui/styles';
import React from 'react';
import {
	Show,
	ShowProps,
	TextField,
	SimpleShowLayout,
	DateField,
	RichTextField,
} from 'react-admin';

const useStyles = makeStyles({
	description: {
		backgroundColor: '#F6F6F6',
		padding: '0.5rem 1rem',
		borderRadius: '0.35rem',
	},
});

export const FreeTextShow = (props: ShowProps) => {
	const classes = useStyles();

	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" label="ID" />
				<TextField source="title" />
				<RichTextField
					source="description"
					label="Body"
					className={classes.description}
				/>
				<TextField source="backgroundColour" />
				<TextField source="imageURL" label="Image URL" />
				<TextField source="imageSubTitle" label="Image Subtitle" />
				<TextField source="imageTitle" label="Image Title" />
				<TextField source="imageFormat" label="Image Format" />
				<DateField label="Start" source="schedule.start" showTime />
				<DateField label="End" source="schedule.end" showTime />
			</SimpleShowLayout>
		</Show>
	);
};
