import React, {useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
// import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
// import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';

type YesNoAvailabilityProps = {
    inputName: InputName;
    setValue: UseFormSetValue<IntakeFormData>;
};

const YesNoAvailability = ({ inputName, setValue }: YesNoAvailabilityProps) => {
    // const { currentIndex } = useContext(IntakeContext);
    // const { scroll } = useScroll();
    const [selectedOption, setSelectedOption] = useState<boolean | null>(null);
    // const [availability, setAvailability] = useState<string>('');

    const handleOptionChange = (choice: string) => {
        const isYes = choice === 'Yes';
        setSelectedOption(isYes);
        setValue(inputName, isYes);
        // scroll('down', currentIndex); // Removed scroll on option change
    };

    // const handleAvailabilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setAvailability(e.target.value);
    // };

    // useEffect(() => {
    //     if (selectedOption) {
    //         // Scroll to next question when an option is selected
    //         scroll();
    //     }
    // }, [selectedOption, currentIndex, setValue, inputName, availability, scroll]);

    // useEffect(() => {
    //     if (selectedOption === 'Yes') {
    //         setValue(inputName as string, {
    //             selection: selectedOption,
    //             availability,
    //         });
    //     }
    // }, [availability, selectedOption, setValue, inputName]);

    return (
        <div className="w-full max-w-3xl py-3">
            <style>{`
                .custom-radio {
                    appearance: none;
                    -webkit-appearance: none;
                    background-color: #fff;
                    margin: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    border: 2px solid #d1d5db;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                }

                .custom-radio:checked {
                    border-color: #000 !important;
                    background-color: #fff !important;
                }

                .custom-radio:checked::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 0.75rem;
                    height: 0.75rem;
                    border-radius: 50%;
                    background-color: #000 !important;
                }

                .custom-radio:focus {
                    outline: none;
                    box-shadow: 0 0 0 0px #000;
                }

                .custom-radio:hover {
                    border-color: #9ca3af;
                }
            `}</style>

            <div className="flex items-center font-button text-xl space-x-6">
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="choice-yes"
                        name="yesno-selection"
                        value="Yes"
                        checked={selectedOption === true}
                        onChange={() => handleOptionChange('Yes')}
                        className="custom-radio"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="choice-yes" className="ml-3">Yes</label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="choice-no"
                        name="yesno-selection"
                        value="No"
                        checked={selectedOption === false}
                        onChange={() => handleOptionChange('No')}
                        className="custom-radio"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="choice-no" className="ml-3">No</label>
                </div>
            </div>
            {/* {selectedOption === 'Yes' && (
                <div className="mt-4">
                    <p className="font-button text-xl mb-2">• If yes, please specify your availability:</p>
                    <input
                        type="text"
                        value={availability}
                        onChange={handleAvailabilityChange}
                        className="w-full border-black border-b border-t-0 border-r-0 border-l-0 px-2 py-1"
                        style={{
                            outline: 'none',
                            WebkitAppearance: 'none',
                            boxShadow: 'none',
                        }}
                        onFocus={(e) => { e.target.style.borderBottomColor = 'black'; }}
                        onBlur={(e) => { e.target.style.borderBottomColor = '' }}
                    />
                </div>
            )} */}
        </div>
    );
};

export default YesNoAvailability;
