import { DocumentNode } from 'graphql';
import create from './create';
import update from './update';
import deletes from './deletes';
import deleteMany from './deleteMany';

export interface IMutations {
	users: DocumentNode;
	livingRooms: DocumentNode;
	reports: DocumentNode;
	freeTexts: DocumentNode;
	quotes: DocumentNode;
	polls: DocumentNode;
}

// eslint-disable-next-line
export default { create, update, deletes, deleteMany };
