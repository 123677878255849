import React from 'react';
import { Bar } from 'react-chartjs-2';
import { authorsPillarCounts, AuthorsPillarsCount } from '../sessionsQuery';

export const AuthorWiseGroupedChart = () => {
	AuthorsPillarsCount();
	const info = {
		labels: ['Professional', 'Personal', 'Financial'],
		datasets: [
			{
				label: 'Till Now',
				backgroundColor: 'rgba(136, 132, 216, 0.2)',
				borderColor: 'rgba(136, 132, 216, 1)',
				borderWidth: 1,
				data: [
					authorsPillarCounts.Professional,
					authorsPillarCounts.Personal,
					authorsPillarCounts.Financial,
				],
			},
		],
	};
	return (
		<div className="App">
			<Bar
				data={info}
				width={360}
				height={320}
				options={{
					maintainAspectRatio: false,
				}}
			/>
		</div>
	);
};
