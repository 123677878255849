import React, { useState, useEffect } from 'react';

type CustomCarouselProps = {
	images: string[];
	interval?: number; // Interval for autoplay in milliseconds
};

const CustomCarousel: React.FC<CustomCarouselProps> = ({
	images,
	interval = 10000,
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const autoplay = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, interval);
		return () => clearInterval(autoplay);
	}, [images.length, interval]);

	const prevSlide = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length,
		);
	};

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	return (
		<div className="relative w-full mx-auto overflow-hidden">
			<button
				className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-gray-800 text-white p-2 cursor-pointer"
				onClick={prevSlide}
			>
				❮
			</button>
			<div className="flex justify-center items-center">
				<img
					src={images[currentIndex]}
					alt={`slide-${currentIndex}`}
					className="w-full h-auto object-contain"
				/>
			</div>
			<button
				className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-gray-800 text-white p-2 cursor-pointer"
				onClick={nextSlide}
			>
				❯
			</button>
		</div>
	);
};

export default CustomCarousel;
