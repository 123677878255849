import { gql } from '@apollo/client';

export const ResetPasswordMutation = gql`
	mutation resetPassword(
		$password: String!
		$phone: String!
		$verification: String!
	) {
		resetPassword(
			phone: $phone
			verification: $verification
			password: $password
		) {
			accessToken
		}
	}
`;

export interface IResetPasswordMutationVariables {
	phone: string;
	verification: string;
	password: string;
}

export interface IResetPasswordMutationData {
	resetPassword: {
		accessToken: string;
	};
}
