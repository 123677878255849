import React from 'react';
import { ApolloError } from '@apollo/client';
import Button from '../../../components/Button';

interface IErrorMessage {
	error: ApolloError;
}
const ErrorMessage = ({ error }: IErrorMessage) => {
	// Custom message for invalid tokens
	if (error.graphQLErrors[0].extensions?.code === 'INVALID_TOKEN')
		return (
			<div className="text-center">
				<h3 className="text-lg mb-8">
					Hmmm that link didn&apos;t seem to work. <br /> It has
					either expired, or is not valid. Reach out to us to request
					a new activation link by clicking the button below.
				</h3>
				<Button
					onClick={() => {
						window.location.href =
							'mailto:hello@uplevyl.com?subject=Activation%20Link%20Expired&body=Hello,%0D%0A%0D%0A%20I%20would%20like%20to%20request%20a%20new%20Uplevyl%20activation%20link,%20as%20mine%20has%20expired%20or%20is%20not%20valid.';
					}}
				>
					Request a new link
				</Button>
				<p className="text-sm text-gray-400 mt-4">
					Activation links are only valid for 4 days
				</p>
			</div>
		);

	return (
		<div className="text-center">
			<h3 className="text-xl text-red-600 mb-8">
				Hmmm that didn&apos;t seem to work. Try again, or contact
				support if this error persists.
			</h3>
			<p className="text-sm text-red-400">{error.message}</p>
		</div>
	);
};

export default ErrorMessage;
