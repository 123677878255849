/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import {
	useRefresh,
	useNotify,
	SimpleForm,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import { Button, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import Confirm from '../../components/Confirm';

interface DownloadAssetButtonProps {
	resource?: string;
}

const DownloadAssetButton = ({
	resource = 'custom',
}: DownloadAssetButtonProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [id, setID] = useState<string>('');
	const refresh = useRefresh();
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const { mutate: broadcastNotification, isLoading } = useMutation(
		() =>
			dataProvider.downloadAsset({
				id,
			}),
		{
			// undoable: true,
			onSuccess: () => {
				refresh();
				notify('Processing master asset - this can take a few minutes');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		broadcastNotification();
		setOpen(false);
	};

	return (
		<>
			<Button
				disabled={isLoading}
				onClick={handleClick}
				variant="contained"
				startIcon={<DownloadIcon />}
			>
				Download Asset
			</Button>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title="Download Asset"
				content="Enter the entry ID from Contentful below. This will generate a master file for you to download. This will be sent to you via your email once finished processing."
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			>
				<SimpleForm toolbar={<></>}>
					<TextField
						label="Contentful Entry ID"
						fullWidth
						multiline
						required
						value={id}
						onChange={(e) => setID(e.target.value)}
					/>
				</SimpleForm>
			</Confirm>
		</>
	);
};

export default DownloadAssetButton;
