import * as React from 'react';
import {
	Button,
	useRefresh,
	useNotify,
	useUnselectAll,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useEffect, useState } from 'react';
import Confirm from '../../../components/Confirm';

interface IArchiveButtonProps {
	selectedIds: string[];
	resource?: string;
	single?: boolean;
}

const ArchiveButton = ({
	selectedIds,
	resource = 'users',
	single,
}: IArchiveButtonProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll('shares');
	const dataProvider = useDataProvider();

	const {
		mutate: archiveUsers,
		isLoading,
		data,
		error,
	} = useMutation<{ archive: any }[]>(() =>
		dataProvider.archive({ input: { ids: selectedIds } }),
	);

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		archiveUsers();
		setOpen(false);
	};

	useEffect(() => {
		if (data && data.length && data[0].archive) {
			refresh();
			notify(`${data.length} users have been archived`);
			unselectAll();
		} else if (error) {
			notify(`${error}`, { type: 'warning' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error]);

	return (
		<>
			<Button
				label={`Archive user${single ? '' : 's'}`}
				disabled={isLoading}
				onClick={handleClick}
			>
				<ArchiveIcon />
			</Button>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title={`Archive User${single ? '' : 's'}`}
				content={`Are you sure you want to archive ${selectedIds.length} user accounts? This will remove them from active list`}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default ArchiveButton;
