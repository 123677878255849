import React from 'react';
import { Filter, SearchInput, SelectInput } from 'react-admin';

interface IReportFilterProps {
	classes?: Record<'button' | 'form', string>;
	context?: 'form' | 'button';
	variant?: string;
}
const ReportFilter = (props: IReportFilterProps) => {
	return (
		<Filter {...props}>
			<SearchInput source="query" alwaysOn />
			<SelectInput
				source="status"
				choices={[
					{
						id: 'PENDING',
						name: 'Pending',
					},
					{
						id: 'ACTIONED',
						name: 'Actioned',
					},
					{ id: 'IGNORED', name: 'Ignored' },
				]}
			/>
		</Filter>
	);
};

export default ReportFilter;
