import React from 'react';
import {
	BooleanInput,
	DateTimeInput,
	Edit,
	EditProps,
	NumberInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import LivingRoomTitle from './components/living-room.title';
import LivingRoomMilestones from './living-room.milestones';

type ILivingRoomEditProps = EditProps;

export const LivingRoomEdit = (props: ILivingRoomEditProps) => {
	return (
		<Edit title={<LivingRoomTitle />} {...props}>
			<SimpleForm sx={{ maxWidth: 500 }}>
				<TextInput source="title" required fullWidth />

				<LivingRoomMilestones />
				<DateTimeInput source="start" required />
				<DateTimeInput source="end" required />
				<BooleanInput source="recorded" />
				<NumberInput source="capacity" required min={1} />
				<TextInput source="description" required multiline fullWidth />
			</SimpleForm>
		</Edit>
	);
};
