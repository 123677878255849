import { QueryHookOptions, useQuery } from '@apollo/client';
import { IGetMeInfoQueryData, GetMeInfoQuery } from '../graphql/queries/getMe';

/**
 * Get user profile information from access token
 * @param options
 * @returns
 */
export const useGetMeQuery = (
	options?: QueryHookOptions<IGetMeInfoQueryData>,
) => useQuery<IGetMeInfoQueryData>(GetMeInfoQuery, options);
