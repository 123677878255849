import { RaRecord as Record } from 'react-admin';
import { BaseFormatter } from './default.formatter';
/* eslint no-param-reassign: "error" */
export class MilestoneFormatter extends BaseFormatter {
	public create<D extends Record, O extends Record>(data: D): O {
		const milestones = new Set();
		const pillars = new Set();
		(data.milestones as string[]).forEach((id) => {
			const [pillar, milestone] = id.split(':');
			pillars.add(pillar);
			milestones.add(milestone);
		});
		(data.milestones as unknown[]) = [...milestones];
		(data.pillars as unknown[]) = [...pillars];

		return data as unknown as O;
	}

	public list<T extends Record>(_data: T): { data: Record[]; total: number } {
		let data = _data.edges.map((edge: { node: any }) => edge.node);

		data = data.map((milestone: any) => {
			return {
				id: `${milestone.id}`,
				name: `${milestone.pillar.name}`,
			};
		});

		return {
			data,
			total: _data ? _data.pageInfo.total : 0,
		};
	}
}

/* eslint no-param-reassign: "error" */
