import { gql } from '@apollo/client';
import { IQueries } from '.';
import fragments from '../fragments';

const many: IQueries = {
	users: gql`
		${fragments.users}
		query getManyUsers($ids: [ID!]!) {
			usersMany(ids: $ids) {
				edges {
					node {
						...UserDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	livingRooms: gql`
		${fragments.livingRooms}
		query getManyLivingRooms($ids: [ID!]!) {
			livingRoomsMany(ids: $ids) {
				edges {
					node {
						...LivingRoomDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	milestones: gql`
		${fragments.milestones}
		query getManyMilestones($ids: [ID!]!) {
			milestonesMany(ids: $ids) {
				edges {
					node {
						...MilestoneDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	reports: gql`
		${fragments.reports}
		query getManyReports($ids: [ID!]!) {
			reportsMany(ids: $ids) {
				edges {
					node {
						...ReportDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	logs: gql`
		${fragments.logs}
		query getManyLogs($ids: [ID!]!) {
			logsMany(ids: $ids) {
				edges {
					node {
						...LogDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	polls: gql`
		${fragments.polls}
	`,
	freeTexts: gql`
		${fragments.freeTexts}
	`,
	quotes: gql`
		${fragments.quotes}
	`,
	schedules: gql`
		${fragments.schedules}
	`,
};
export default many;
