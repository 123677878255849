import * as React from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { Link, To } from 'react-router-dom';

interface Props {
	icon: React.FC<any>;
	to: To;
	title?: string;
	value?: number;
	subtitle?: string;
	children?: React.ReactNode;
}

const TotalCard = (props: Props) => {
	const { icon, title, subtitle, value, to, children } = props;

	return (
		<Card
			sx={{
				minHeight: subtitle || value ? 52 : 40,
				display: 'flex',
				flexDirection: 'column',
				flex: '1',
				'& a': {
					textDecoration: 'none',
					color: 'inherit',
				},
			}}
		>
			<Link to={to}>
				<Box
					sx={{
						overflow: 'inherit',
						padding: '16px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						'& .icon': {
							color: (theme) =>
								theme.palette.mode === 'dark'
									? 'inherit'
									: '#dc2440',
						},
					}}
				>
					<Box width="3em" className="icon">
						{React.createElement(icon, { fontSize: 'large' })}
					</Box>
					<Box textAlign="right">
						<Typography
							color={subtitle ? 'textPrimary' : 'textSecondary'}
						>
							{title}
						</Typography>
						{subtitle && (
							<Typography
								variant="subtitle2"
								color="textSecondary"
								component="h2"
							>
								{subtitle || ' '}
							</Typography>
						)}
						{value && (
							<Typography variant="h5" component="h2">
								{value || ' '}
							</Typography>
						)}
					</Box>
				</Box>
			</Link>
			{children && <Divider />}
			{children}
		</Card>
	);
};

export default TotalCard;
