import React from 'react'

interface IButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children: React.ReactNode;
	fullWidth?: boolean;
	className?: string;
}
const Button = ({
	children,
	disabled,
	fullWidth,
	className,
	...props
}: IButtonProps) => {
	return (
		<button
			className={`bg-gray-900 rounded-md text-white w-full h-12 lg:w-auto lg:py-2 lg:px-7 md:w-auto md:py-2 md:px-7 border border-transparent transition-colors duration-200 font-button${fullWidth ? ' w-full' : ''
				} ${disabled
					? 'opacity-50 cursor-not-allowed'
					: 'hover:bg-white hover:border-black hover:text-black'
				} ${className}`}
			disabled={disabled ?? false}
			{...props}
		>
			{children}
		</button>
	);
};

export default Button;
