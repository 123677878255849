import React from 'react';
import { Tab, Container, Typography, Paper } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
// import PastLivingRoomData from '../past-living-room';
import CurrentLivingRoomData from '../current-living-room';
import { PillarsGroupedChart } from '../charts/pillarsgroupedChart';
import { SeesionsMonthlyChart } from '../charts/compareChart';
import { PillarDropdown } from '../pillarDropdown';

const card3 = {
	width: '450px',
	height: '420px',
	padding: '32px',
	marginRight: '10px',
	marginTop: '10px',
	marginLeft: '70px',
	marginBottom: '10px',
};
const card2 = {
	width: '450px',
	height: '420px',
	padding: '32px',
	marginTop: '10px',
	marginRight: '30px',
	marginBottom: '10px',
};
const card4 = {
	width: '1030px',
	height: '540px',
	padding: '10px',
	display: 'flex',
	margin: '10px',
};

export const ActiveSessionContent = () => {
	// const [value, setValue] = React.useState('1');
	// const handleChange = (event: React.SyntheticEvent, newValue: string) => {
	//  setValue(newValue);
	// };

	const [index, setIndex] = React.useState('living-room-data');

	const livingHandleChange = (
		event: React.SyntheticEvent,
		newIndex: string,
	) => {
		setIndex(newIndex);
	};
	return (
		<>
			<TabContext value={index}>
				<TabList
					onChange={livingHandleChange}
					aria-label="living-room-active-tabs"
				>
					<Tab label="Living Room Data" value="living-room-data" />
					<Tab
						label="Living Room Graphical Analytics"
						value="living-room-chart"
					/>
				</TabList>
				<TabPanel value="living-room-data">
					<Container
						style={{
							width: '1000px',
							height: '600px',
							padding: '10px',
							overflowY: 'auto',
							float: 'left',
							backgroundColor: 'white',
							borderRadius: '5px',
						}}
					>
						<Typography variant="h5" component="h2">
							Living Rooms
						</Typography>
						<CurrentLivingRoomData />
					</Container>
				</TabPanel>
				<TabPanel value="living-room-chart">
					<div
						style={{
							display: 'flex',
						}}
					>
						<Paper elevation={3} style={card3}>
							<Typography
								variant="h5"
								component="h2"
								style={{
									marginBottom: '20px',
								}}
							>
								Pillar wise sessions
							</Typography>
							<PillarsGroupedChart />
						</Paper>
						<Paper elevation={3} style={card2}>
							<Typography
								variant="h5"
								component="h2"
								style={{
									marginBottom: '20px',
								}}
							>
								Most sessions happened
							</Typography>
							<SeesionsMonthlyChart />
						</Paper>
					</div>
					<Paper elevation={3} style={card4}>
						<PillarDropdown />
					</Paper>
				</TabPanel>
			</TabContext>
		</>
	);
};
