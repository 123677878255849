import React from 'react';
import { useNavigate } from 'react-router-dom';
import uplevyl from '../../../assets/icons/Uplevyl.svg';
import shubhiRao from '../../../assets/icons/shubhiRao.svg';

const FutureForwardPage = () => {
    const navigate = useNavigate();

    const openIntakeForm = () => {
        navigate('/intake');
    };

    return (
        <div className="min-h-screen bg-white flex justify-center items-center">
            <div className="max-w-4xl w-full px-8 py-12 mt-8">
                {/* Logo */}
                <div className="flex justify-center mb-10">
                    <img src={uplevyl} alt="Uplevyl Logo" className="h-10" />
                </div>

                <h1 className="text-3xl font-header text-center mb-12">
                    <span className='block'>Stay Relevant:</span>
                    <span className='block'>Apply To Join Future Forward Now</span>
                </h1>

                <p className="text-lg font-button">Dear Woman Leader,</p>

                <p className="text-lg font-button my-6">
                    Want to stay relevant in your career?
                </p>

                <p className="text-lg font-button mb-8">
                    Major changes are coming to every workplace because of AI and digital transformation.
                </p>

                <p className="text-lg font-button mb-2">
                    The next five years will define which women leaders succeed and which are left behind.
                </p>

                <ul className="text-lg font-button ml-6 space-y-2">
                    <li className="flex">
                        <span className="mr-2">⚠️</span>
                        <span>
                            92% of employers consider digital transformation a top priority (McKinsey)
                        </span>
                    </li>
                    <li className="flex">
                        <span className="mr-2">⚠️</span>
                        <span>
                            AI is hurtling towards us and will affect us all.
                        </span>
                    </li>
                    <li className="flex">
                        <span className="mr-2">⚠️</span>
                        <span>
                            Digital acumen will become crucial to every leadership role.
                        </span>
                    </li>
                </ul>
                <p className="text-lg font-button mb-5 mt-6">
                If you don&apos;t keep up with digital change, you risk becoming obsolete in your current role and losing out on future opportunities.
                </p>
                <p className="text-lg font-button mb-8 mt-6">
                    If you maintain strong digital acumen...
                </p>

                <ul className="text-lg font-button ml-6 mb-8 space-y-2">
                    <li className="flex">
                        <span className="mr-2">✅</span>
                        <span>
                            You&apos;re more likely to have greater job security.
                        </span>
                    </li>
                    <li className="flex">
                        <span className="mr-2">✅</span>
                        <span>
                            You&apos;re more likely to get salary increases.
                        </span>
                    </li>
                    <li className="flex">
                        <span className="mr-2">✅</span>
                        <span>
                            You&apos;re more likely to stay relevant.
                        </span>
                    </li>
                </ul>

                <div className="text-left mb-8">
                    <h2 className="text-2xl font-header underline font-thin mb-6">
                        Join Future Forward
                    </h2>
                </div>

                <p className="text-lg font-button mb-4">
                    Brought to you by <a href="https://uplevyl.com" className="text-black">Uplevyl</a> (the AI-for-women-by-women platform).
                </p>

                <p className="text-lg font-button mb-4">
                I would like to invite you to apply to join me and other senior women from across the country in Future Forward. It is a selective membership program designed to give you the skills, insights, and network you need to lead in the digital age.
                </p>

                <p className="text-lg font-button">
                Places are limited and we will commence in January 2025.
                </p>
                <div className="mb-8">
                    <button
                        onClick={openIntakeForm}
                        className="text-2xl text-left font-header underline font-thin my-7 text-black hover:text-black"
                    >
                        Apply now
                    </button>


                    <p className="text-lg font-button mb-4">The application process is straightforward, private, and secure.</p>
                    <p className="text-lg font-button mb-4">
                    However, spaces are limited, and this opportunity won&apos;t last. Applying does not guarantee membership, nor are you obligated to join if accepted. You will receive an email regarding your application status within 5-7 business days. If your application is successful, you will receive sign-up and payment details.
                    </p>
                </div>

                <div className="text-base mb-8">
                    <h3 className="text-2xl text-left font-header underline font-thin my-7">Don&apos;t Get Left Behind</h3>
                    <p className='text-lg font-button mb-4'>I urge you to take action and apply now.</p>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-center my-12 lg:my-24 lg:space-x-20 space-y-6 lg:space-y-0 px-4">
                    {/* Image on the Left */}
                    <div className="relative w-32 h-32 lg:w-48 lg:h-48 rounded-full overflow-hidden">
                        <img src={shubhiRao} alt="Shubhi Rao" className="w-full h-full object-cover" />
                    </div>

                    {/* Text on the Right */}
                    <div className="text-center lg:text-left">
                        <h2
                            className="text-2xl lg:text-3xl font-header"
                            style={{
                                background: 'linear-gradient(to right, #F6164F, #FFC903)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundClip: 'text',
                                color: 'transparent',
                                display: 'inline-block'
                            }}
                        >
                            Shubhi Rao
                        </h2>
                        <p className="text-base lg:text-lg font-button my-2 lg:my-4">CEO & Founder, Uplevyl</p>
                        <div className="mt-2 lg:mt-4 max-w-full lg:max-w-lg text-sm lg:text-lg font-button">
                            <p>
                                AI Founder & CEO | Public Board Director | (former Vice President, Treasurer and Officer of
                                Alphabet/Google, Tesco, PwC, Ford) | Executive Member, UNESCO - Women4Ethical AI Platform
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-left">
                    <button
                        onClick={openIntakeForm}
                        className="bg-gray-900 text-white font-button text-lg lg:w-auto lg:px-8 lg:py-2.5 md:w-auto md:px-8 md:py-2.5 w-full h-12 rounded-md hover:bg-gray-800 transition-colors duration-200"
                    >
                        Apply Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FutureForwardPage;
