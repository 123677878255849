import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
	GetPaymentQuery,
	IGetPaymentQueryData,
	IGetPaymentQueryVariables,
	IGetUpdateQueryData,
	IUpdatePaymentStatusVariable,
	updatePaymentStatus,
} from '../graphql/payment';

/**
 * Get user checkout information from invite token
 * @param options
 * @returns
 */

export const usePaymentQuery = (
	options?: QueryHookOptions<IGetPaymentQueryData, IGetPaymentQueryVariables>,
) =>
	useLazyQuery<IGetPaymentQueryData, IGetPaymentQueryVariables>(
		GetPaymentQuery,
		options,
	);

export const useUpdatePaymentQuery = (
	options?: QueryHookOptions<
		IGetUpdateQueryData,
		IUpdatePaymentStatusVariable
	>,
) =>
	useLazyQuery<IGetUpdateQueryData, IUpdatePaymentStatusVariable>(
		updatePaymentStatus,
		options,
	);
