import { TokenService } from '../token.service';
import { ApolloClient } from './apollo.client';
import { IClient } from './client.interface';
import { UrqlClient } from './urql.client';

export const buildClient = (type: 'apollo' | 'urql'): IClient => {
	if (!process.env.REACT_APP_API) throw new Error('No API URL given');
	const url = process.env.REACT_APP_API;

	const tokenService = new TokenService();

	if (type === 'urql') return new UrqlClient(url, tokenService);

	return new ApolloClient(url, tokenService);
};
