import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	ISubmitSupportReplyMutationData,
	ISubmitSupportReplyMutationVariables,
	SubmitSupportReplyMutation,
} from '../graphql/mutations/submitSupportReply';

/**
 * Submit a support request mutation
 * @param options
 * @returns Mutation hook
 */
export const useSubmitSupportReplyMutation = (
	options?: MutationHookOptions<
		ISubmitSupportReplyMutationData,
		ISubmitSupportReplyMutationVariables
	>,
) =>
	useMutation<
		ISubmitSupportReplyMutationData,
		ISubmitSupportReplyMutationVariables
	>(SubmitSupportReplyMutation);
