import { DocumentNode } from 'graphql';
import { gql } from 'urql';
import { IMutations } from '.';
import fragments from '../fragments';

const update: IMutations = {
	users: gql`
		mutation updateUser($id: ID!, $input: AdminUserUpdateInput!) {
			adminUserUpdate(id: $id, input: $input) {
				id
				firstName
				lastName
				email
				dob
				phone
				comment
				avatar
				role
				locked
				timeout
				created
				updated
				contributor
				enterprise
			}
		}
	`,
	livingRooms: gql`
		${fragments.livingRooms as DocumentNode}
		mutation updateLivingRoom($id: ID!, $input: LivingRoomUpdateInput!) {
			livingRoomUpdate(id: $id, input: $input) {
				...LivingRoomDetails
			}
		}
	`,
	reports: gql`
		${fragments.reports as DocumentNode}
		mutation updateReport($id: ID!, $input: ReportUpdateInput!) {
			reportUpdate(id: $id, input: $input) {
				...ReportDetails
			}
		}
	`,
	quotes: gql`
		${fragments.quotes as DocumentNode}
		mutation updateQuote($id: ID!, $input: QuoteUpdateInput!) {
			quoteUpdate(id: $id, input: $input) {
				...QuoteDetails
			}
		}
	`,
	freeTexts: gql`
		${fragments.freeTexts as DocumentNode}
		mutation updateTextFree($id: ID!, $input: FreeTextUpdateInput!) {
			freeTextUpdate(id: $id, input: $input) {
				...FreeTextDetails
			}
		}
	`,
	polls: gql`
		${fragments.polls as DocumentNode}
		mutation updatePoll($id: ID!, $input: PollUpdateInput!) {
			pollUpdate(id: $id, input: $input) {
				...PollDetails
			}
		}
	`,
};

export default update;
