import React from 'react';
import {
	Button,
	RaRecord,
	useDataProvider,
	useNotify,
	useRefresh,
	useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';

interface IRemoveButtonProps {
	record?: RaRecord;
	resource?: string;
}

const RemoveButton = ({ resource }: IRemoveButtonProps) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const record = useRecordContext();

	const { mutate: removeRegistration, isLoading } = useMutation(
		() =>
			dataProvider.removeRegistration({
				id: record?.livingRoom.id,
				input: {
					userId: record?.user.id,
				},
			}),
		{
			// undoable: true,
			onSuccess: () => {
				refresh();
				notify('User removed from living room');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	return (
		<Button
			label="Remove"
			onClick={() => removeRegistration()}
			disabled={isLoading}
		>
			<DeleteIcon />
		</Button>
	);
};

export default RemoveButton;
