import React, { useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { getPrettyErrorMessage } from '../../../helpers';
import { useVerifyCodeMutation } from './hooks/useVerifyCodeMutation';

interface EnterCodeProps {
	setVerification: React.Dispatch<React.SetStateAction<string | undefined>>;
	setEnterPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnterCode = ({ setVerification, setEnterPassword }: EnterCodeProps) => {
	const [verifyCode, { loading }] = useVerifyCodeMutation();

	const [code, setCode] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>();

	const handleSubmit = async () => {
		if (!code) return setErrorMessage('Please enter a valid code');
		try {
			const { errors, data } = await verifyCode({
				variables: {
					code,
				},
			});

			if (errors?.[0]) return setErrorMessage(errors[0].message);

			if (data?.verifyCode) {
				setVerification(data.verifyCode);
				setErrorMessage(undefined);
				setEnterPassword(true);
			}
		} catch (err) {
			if (err?.message)
				setErrorMessage(getPrettyErrorMessage(err.message));
		}
	};

	return (
		<>
			<p className="font-light mt-2">
				Please enter the one-time code sent to your email/mobile number.
			</p>
			<form
				className="flex flex-col w-full mt-10"
				onSubmit={(e) => {
					e.preventDefault();
					return handleSubmit();
				}}
			>
				<Input
					label="Code"
					type="text"
					className="mb-4 opacity-75"
					disabled={loading ?? false}
					value={code}
					placeholder="Enter code"
					onChange={(e) => setCode(e.target.value)}
				/>

				<p className="text-sm text-red-400 mb-4">{errorMessage}</p>

				<Button type="submit" value="submit" disabled={loading}>
					{loading ? 'Verifying...' : 'Continue'}
				</Button>
			</form>
		</>
	);
};

export default EnterCode;
