import React from 'react';
import { useNavigate } from 'react-router';

const ResetSuccess = () => {
	const navigate = useNavigate();
	return (
		<>
			<p className="font-light my-5">
				Your password has been reset. Please{' '}
				<a
					href="/login"
					className="text-black underline"
					onClick={(e) => {
						e.preventDefault();
						navigate('/login');
					}}
				>
					login
				</a>{' '}
				with the new password and continue your Uplevyl journey
			</p>
		</>
	);
};

export default ResetSuccess;
