import React, { useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	ListProps,
	EditButton,
	ReferenceField,
	DateField,
	SelectField,
	PaginationProps,
	Pagination,
	useNotify,
	useListContext,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import { exportFile, exportFilter } from '../../helpers';
import ReportActionsButtons from './components/report.actions';
import ReportFilter from './components/reports.filter';

type IReportListProps = ListProps;

const ReportPagination = (props: PaginationProps) => (
	<Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const Aside = (props: any) => {
	const { setFilter } = props;
	const { filterValues } = useListContext();
	setFilter(filterValues);
	return null;
};

export const ReportList = (props: IReportListProps) => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const [filter, setFilter] = useState<any>();
	const { mutate: exportReports, data } = useMutation<{
		data: Record<string, any>;
	}>(
		() =>
			dataProvider.reportExportCsv({
				filter: exportFilter(filter),
			}),
		{
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	const exporter = async () => {
		exportReports();
	};

	useEffect(() => {
		if (data) exportFile(data?.data, 'reports');
	}, [data]);

	return (
		<List
			sort={{
				field: 'created',
				order: 'desc',
			}}
			aside={<Aside setFilter={setFilter} />}
			filters={<ReportFilter />}
			exporter={exporter}
			bulkActionButtons={<ReportActionsButtons />}
			pagination={<ReportPagination />}
			{...props}
		>
			<Datagrid rowClick="show">
				<TextField source="id" label="ID" />
				<ReferenceField
					label="Made By"
					source="madeBy.id"
					reference="users"
				>
					<TextField source="email" />
				</ReferenceField>
				<ReferenceField label="User" source="user.id" reference="users">
					<TextField source="email" />
				</ReferenceField>
				<SelectField
					source="status"
					choices={[
						{
							id: 'PENDING',
							name: 'Pending',
						},
						{
							id: 'ACTIONED',
							name: 'Actioned',
						},
						{ id: 'IGNORED', name: 'Ignored' },
					]}
				/>
				<DateField source="created" />
				<EditButton />
			</Datagrid>
		</List>
	);
};
