import React from 'react';
import get from 'lodash/get';
import { Link } from '@mui/material';
import { sanitizeFieldRestProps, UrlFieldProps } from 'react-admin';

interface ILocalURLFieldProps extends UrlFieldProps {
	linkResource: string;
	value?: string;
}

const LocalURLField: React.FC<ILocalURLFieldProps> =
	React.memo<ILocalURLFieldProps>(
		({ linkResource, value, className, source, record = {}, ...rest }) => {
			const entity = get(record, source ?? '');
			const _value = get(record, value ?? '');
			const href = `/admin/${linkResource}/${entity}`;

			return (
				<>
					<p className="MuiFormLabel-root custom-label">
						{rest.label}
					</p>
					<Link
						className={className}
						href={href}
						{...sanitizeFieldRestProps(rest)}
					>
						{rest.defaultValue
							? rest.defaultValue
							: _value ?? entity}
					</Link>
					<div className="mb-4" />
				</>
			);
		},
	);

export default LocalURLField;
