import React, { useContext } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { uniqueId } from 'lodash';
import useScroll from '../../hooks/useScroll';
import { InputName } from '../../intake.data';
import { IntakeContext } from '../../intake.provider';
import { IntakeFormData } from '../../IntakePage';
import Button from '../../../../components/Button';

type SkillRatingProps = {
  inputName: InputName;
  choices: string[];
  setValue: UseFormSetValue<IntakeFormData>;
};

const SkillRating = ({ inputName, choices, setValue }: SkillRatingProps) => {
  const { currentIndex } = useContext(IntakeContext);
  const { scroll } = useScroll();
  const [ratings, setRatings] = React.useState<number[]>(new Array(choices.length).fill(0));

  const handleRatingChange = (index: number, rating: number) => {
    const updatedRatings = [...ratings];
    updatedRatings[index] = rating;
    setRatings(updatedRatings);
  };

  const handleNext = () => {
    setValue(inputName as keyof IntakeFormData, ratings);
    scroll('down', currentIndex);
  };

  const isAllRated = ratings.every((rating) => rating > 0);

  return (
    <div className="w-full max-w-3xl pb-8">
      <style>{`
        .custom-radio {
          appearance: none;
          -webkit-appearance: none;
          background-color: #fff;
          margin: 0;
          width: 1.5rem;
          height: 1.5rem;
          border: 2px solid #d1d5db;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }

        .custom-radio:checked {
          border-color: #000 !important;
          background-color: #fff !important;
        }

        .custom-radio:checked::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: #000 !important;
        }

        .custom-radio:focus {
          outline: 2px solid #000;
          outline-offset: 2px;
        }

        .custom-radio:hover {
          border-color: #9ca3af;
        }

        @media (max-width: 768px) {
          .custom-radio {
            width: 1.25rem;
            height: 1.25rem;
          }

          .custom-radio:checked::after {
            width: 0.6rem;
            height: 0.6rem;
          }
        }
      `}</style>

      {/* Rating Headers */}
      <div className="flex w-full md:mb-4 lg:flex-row flex-col">
        <div className="flex-1 lg:block hidden" />
        <div className="flex justify-between lg:w-64 w-full px-4 lg:px-0">
          {[1, 2, 3, 4, 5].map((rating) => (
            <div key={rating} className="w-8 text-center font-button text-sm lg:text-base">
              {rating}
            </div>
          ))}
        </div>
      </div>

      {/* Skill Rating Choices */}
      <div className="space-y-6 lg:space-y-4">
        {choices.map((skill, index) => (
          <div key={uniqueId()} className="flex flex-col lg:flex-row lg:items-center font-button text-lg lg:text-xl">
            <div className="flex-1 lg:mr-0 mb-3 lg:mb-0 text-left lg:text-left">
              <span className="mr-2 hidden lg:inline">•</span>
              {skill}
            </div>
            <div className="flex justify-between lg:w-64 w-full px-4 lg:px-0">
              {[1, 2, 3, 4, 5].map((rating) => (
                <div key={`${skill}-${rating}`} className="w-8 flex justify-center">
                  <input
                    type="radio"
                    id={`${skill}-${rating}`}
                    name={`${skill}-${index}`}
                    value={rating}
                    checked={ratings[index] === rating}
                    onChange={() => handleRatingChange(index, rating)}
                    className="custom-radio"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Button - Now always visible but conditionally disabled */}
      <div className="mt-8 flex justify-center text-center">
        <Button
          type="button"
          className={`px-6 lg:px-8 py-2 rounded w-full lg:w-auto ${
            isAllRated 
              ? 'bg-gray-900 text-white cursor-pointer' 
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          onClick={handleNext}
          disabled={!isAllRated}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SkillRating;