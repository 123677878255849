import { gql } from 'urql';
import { IMutations } from '.';

const deleteMany: IMutations = {
	users: gql`
		mutation deleteUsers($ids: [ID!]!) {
			adminUsersDelete(ids: $ids)
		}
	`,
	reports: gql`
		mutation deleteReports($ids: [ID!]!) {
			reportsDelete(ids: $ids)
		}
	`,
	livingRooms: gql`
		mutation deleteLivingRooms($ids: [ID!]!) {
			livingRoomsDelete(ids: $ids)
		}
	`,
	freeTexts: gql`
		mutation deleteFreeTexts($ids: [ID!]!) {
			freeTextsDelete(ids: $ids)
		}
	`,
	quotes: gql`
		mutation deleteQuotes($ids: [ID!]!) {
			quotesDelete(ids: $ids)
		}
	`,
	polls: gql`
		mutation deletePolls($ids: [ID!]!) {
			pollsDelete(ids: $ids)
		}
	`,
};

export default deleteMany;
