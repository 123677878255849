import { MutationHookOptions, useMutation } from '@apollo/client';
import {
	CreateUserMutation,
	ICreateUserArgs,
	ICreateUserMutationData,
} from '../graphql/createUser';

/**
 * create a user
 * @param options
 * @returns Mutation hook
 */
export const useCreateUserMutation = (
	options?: MutationHookOptions<ICreateUserMutationData, ICreateUserArgs>,
) => useMutation<ICreateUserMutationData, ICreateUserArgs>(CreateUserMutation);
