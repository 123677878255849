import React, { useState, ChangeEvent } from 'react';
import {
	BooleanInput,
	Filter,
	SearchInput,
	SelectInput,
	RaRecord,
} from 'react-admin';

interface ILivingRoomFilterProps {
	classes?: Record<'button' | 'form', string>;
	context?: 'form' | 'button';
	variant?: string;
}

const LivingRoomFilter = (props: ILivingRoomFilterProps) => {
	const [selectedPillar, setSelectedPillar] = useState<string>('');

	const handlePillarChange = (
		event: RaRecord | ChangeEvent<HTMLInputElement>,
	) => {
		setSelectedPillar(event.target.value as string);
	};

	return (
		<Filter {...props}>
			<SearchInput source="query" alwaysOn />
			<BooleanInput
				source="past"
				alwaysOn
				label="Show past living rooms"
			/>
			<SelectInput
				source="pillars"
				choices={[
					{
						id: 'Professional',
						name: 'Professional',
					},
					{
						id: 'Personal',
						name: 'Personal',
					},
					{
						id: 'Financial',
						name: 'Financial',
					},
				]}
				onChange={handlePillarChange}
			/>
			<SelectInput
				source="milestones"
				choices={
					selectedPillar === 'Professional'
						? [
								{
									id: 'Career Coaching',
									name: 'Career Coaching',
								},
								{
									id: 'Leadership and Peak performance',
									name: 'Leadership and Peak performance',
								},
								{
									id: 'Diversity, Equity and Inclusion',
									name: 'Diversity, Equity and Inclusion',
								},
								{
									id: 'Performance Reviews and Promotions',
									name: 'Performance Reviews and Promotions',
								},
								{
									id: 'Career Pivots and Job Loss',
									name: 'Career Pivots and Job Loss',
								},
								{
									id: 'Fierce after 50',
									name: 'Fierce after 50',
								},
								{
									id: 'Networking',
									name: 'Networking',
								},
								{
									id: 'Career Search',
									name: 'Career Search',
								},
								{
									id: 'Entrepreneurship',
									name: 'Entrepreneurship',
								},
								{
									id: 'Mentorship',
									name: 'Mentorship',
								},
						  ]
						: selectedPillar === 'Personal'
						? [
								{
									id: 'Mental Health and Wellbeing',
									name: 'Mental Health and Wellbeing',
								},
								{
									id: 'Spiritual Wellness',
									name: 'Spiritual Wellness',
								},
								{
									id: 'Family and Home',
									name: 'Family and Home',
								},
								{
									id: 'Health and Beauty',
									name: 'Health and Beauty',
								},
								{
									id: 'Giving & Impact',
									name: 'Giving & Impact',
								},
								{
									id: 'Food & Culture',
									name: 'Food & Culture',
								},
								{
									id: 'The Gender Lens',
									name: 'The Gender Lens',
								},
								{
									id: 'Marriage & Relationships',
									name: 'Marriage & Relationships',
								},
								{
									id: 'Aging & Caregiving',
									name: 'Aging & Caregiving',
								},
								{
									id: 'Midlife & Menopause',
									name: 'Midlife & Menopause',
								},
								{
									id: 'Adulting',
									name: 'Adulting',
								},
								{
									id: 'Family Planning & Pregnancy',
									name: 'Family Planning & Pregnancy',
								},
								{
									id: 'Personal Growth',
									name: 'Personal Growth',
								},
								{
									id: 'Friendship',
									name: 'Friendship',
								},
								{
									id: 'Breakups and Divorce',
									name: 'Breakups and Divorce',
								},
						  ]
						: selectedPillar === 'Financial'
						? [
								{
									id: 'Family, Divorce & Education',
									name: 'Family, Divorce & Education',
								},
								{
									id: 'Impact & ESG Investing',
									name: 'Impact & ESG Investing',
								},
								{
									id: 'Financial Independence & Investing',
									name: 'Financial Independence & Investing',
								},
								{
									id: 'Homeownership',
									name: 'Homeownership',
								},
								{
									id: 'Crypto & More',
									name: 'Crypto & More',
								},
								{
									id: 'Taxes & Insurance',
									name: 'Taxes & Insurance',
								},
								{
									id: 'Me, Myself & Money',
									name: 'Me, Myself & Money',
								},
								{
									id: 'Budgeting & Spending',
									name: 'Budgeting & Spending',
								},
								{
									id: 'Establishing & Managing Credit',
									name: 'Establishing & Managing Credit',
								},
								{
									id: 'Managing Debt',
									name: 'Managing Debt',
								},
						  ]
						: []
				}
			/>
			<SelectInput
				source="hosts"
				choices={[
					{
						id: 'Host A',
						name: 'Host A',
					},
					{
						id: 'Host B',
						name: 'Host B',
					},
					{
						id: 'Host C',
						name: 'Host C',
					},
				]}
			/>
		</Filter>
	);
};

export default LivingRoomFilter;
