import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

const SkillsRatingsField = () => {
    const record = useRecordContext();

    // Define the questions and map each one to the respective index in `aiSkillsRatings`
    const questions = [
        "Understanding AI Concepts",
        "Using AI Tools (e.g., chatbots, data analytics)",
        "Data Analysis Techniques",
        "Data Privacy and Ethics",
        "Digital Communication Platforms"
    ];

    if (!record || !record.additionalFormInfo || !record.additionalFormInfo.aiSkillsRatings) {
        return <Typography>No AI Skills Ratings available</Typography>;
    }

    return (
        <Box>
            <Typography component="span"
            variant="body2">AI Skills Ratings</Typography>
            {record.additionalFormInfo.aiSkillsRatings.map((rating: number, index: number) => (
                <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="subtitle1" style={{ marginRight: '1rem' }}>
                        {questions[index] ?? `Question ${index + 1}`}
                    </Typography>
                    <Typography variant="body1">{rating}</Typography>
                </Box>
            ))}
        </Box>
    );
};

export default SkillsRatingsField;
