import { useContext } from 'react';
import { IntakeContext } from '../intake.provider';
import sections from '../intake.data';

const useScroll = () => {
	const { setCurrentIndex } = useContext(IntakeContext);

	const scroll = (direction: 'down' | 'up', index: number) => {
		if (index === 0 && direction === 'up') return;
		if (index === sections.length - 1 && direction === 'down') return;

		const elementIndex =
			direction === 'down' ? `${index + 1}` : `${index - 1}`;
		const element = document.getElementById(elementIndex);
		if (!element) return;
		const pos = element.getBoundingClientRect().top + window.pageYOffset;

		window.scrollTo({
			top: pos,
			behavior: 'smooth',
		});

		const input = document.getElementById(`input-${elementIndex}`);
		setTimeout(() => {
			if (input) input.focus();
		}, 500);

		setCurrentIndex(direction === 'down' ? index + 1 : index - 1);
	};

	const resetScroll = () => {
		const element = document.getElementById(`${0}`);
		if (!element) return;
		const topPos = element.getBoundingClientRect().top + window.pageYOffset;

		window.onbeforeunload = () => {
			window.scrollTo({
				top: topPos,
			});
		};
		setCurrentIndex(0);

		const body = document.getElementsByTagName('body')[0];
		body.classList.add('overflow-hidden');

		document.addEventListener('keypress', (e) => {
			if (e.key === 'Enter') e.preventDefault();
		});
	};

	return {
		scroll,
		resetScroll,
	};
};

export default useScroll;
