import React from 'react';
import {
	Show,
	ShowProps,
	TextField,
	ReferenceField,
	SimpleShowLayout,
	DateField,
} from 'react-admin';
import ReportTitle from './components/report.title';

export const ReportShow = (props: ShowProps) => (
	<Show title={<ReportTitle />} {...props}>
		<SimpleShowLayout>
			<TextField source="id" label="ID" />
			<DateField source="created" showTime />
			<TextField source="reason" />
			<TextField source="status" />
			<ReferenceField
				label="User Reported"
				source="user.id"
				reference="users"
			>
				<TextField source="email" />
			</ReferenceField>
			<ReferenceField
				label="Report Made By"
				source="madeBy.id"
				reference="users"
			>
				<TextField source="email" />
			</ReferenceField>
			<TextField
				source="livingRoom.id"
				label="Living Room ID of incident (if applicable)"
			/>
		</SimpleShowLayout>
	</Show>
);
