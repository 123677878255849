/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
// import Header from '../../components/Header';
import { useAnalyticsService } from '../Intake/hooks/useAnalyticsService';

const BillingSuccess = () => {
	const analytics = useAnalyticsService();
	useEffect(() => {
		const sendEvent = async () => {
			await analytics?.logScreenView({
				screen_name: 'membership confirmed',
			});
		};
		sendEvent();
	}, [analytics]);

	useEffect(() => {
		setTimeout(() => {
			const iframe: any = document.querySelector(
				'iframe[style*="z-index: 2147483647"]',
			);
			if (iframe) {
				iframe.style.display = 'none';
			}
		}, 4000);
	}, []);

	return (
		<>
			<div className="bg-white p-6 rounded-lg h-dvh px-8 md:px-24 py-8">
				<div className="flex justify-center mb-8 md:mb-4">
					<img
						src="/assets/logo.png"
						alt="Logo"
						className="w-44 md:w-56"
					/>
				</div>
				<div className="flex flex-col items-center mt-5 mb-50 justify-center rounded rounded-lg md-custom-border">
					<div className="flex w-full md:w-2/4 justify-center mb-4">
						<h1 className="text-black font-abril text-4xl mb-8 md:md-0">
							Thank you so much!
						</h1>
					</div>
					<div style={{ fontSize: '22px' }} className="flex w-full md:w-3/4 font-kreon justify-center mb-4">
						<p>
							You’re in! We look forward to welcome you the Future Forward Accelerator in January 2025. Over the next few weeks we will connect with you to share exciting pre-launch activities, program highlights and curated resources.
						</p>

					</div>
					<div style={{ fontSize: '22px' }} className="flex w-full md:w-3/4 font-kreon justify-center mb-4">
						<p >
							For any questions,
							please don’t hesitate to reach us at eva@uplevyl.com.
						</p>
					</div>

				</div>
			</div>
		</>
	);
};

export default BillingSuccess;
