import React, { useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	ListProps,
	DateField,
	EditButton,
	BooleanField,
	Pagination,
	PaginationProps,
	useNotify,
	useDataProvider,
	FunctionField,
} from 'react-admin';
import { useMutation } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { exportFile, exportFilter } from '../../helpers';
import UsersFilterSidebar from './components/users-filter.sidebar';
import UserActionsButtons from './components/users.actions';

type IUserListProps = ListProps;

const UserPagination = (props: PaginationProps) => (
	<Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const UserList = (props: IUserListProps) => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const [filter, setFilter] = useState<any>();
	const { mutate: exportUsers, data } = useMutation<{
		data: Record<string, any>;
	}>(
		() =>
			dataProvider.userExportCsv({
				filter: exportFilter(filter),
			}),
		{
			onError(error: any) {
				notify(`Error: ${error.message}`, { type: 'warning' });
			},
		},
	);

	const exporter = async () => {
		exportUsers();
	};

	useEffect(() => {
		if (data) exportFile(data?.data, 'users');
	}, [data]);

	return (
		<List
			sort={{
				field: 'created',
				order: 'DESC',
			}}
			aside={<UsersFilterSidebar setFilter={setFilter} />}
			exporter={exporter}
			// filters={<UserFilter />}
			bulkActionButtons={<UserActionsButtons />}
			pagination={<UserPagination />}
			title="Members"
			{...props}
		>
			<Datagrid rowClick="show">
				<TextField source="firstName" />
				<TextField source="lastName" />
				<EmailField source="email" />
				<TextField source="role" />
				<TextField source="mobileOS" label="MobileOS" />
				<BooleanField
					source="referred"
					label="Referred"
					sortable={false}
				/>
				<BooleanField source="contributor" />
				<BooleanField source="enterprise" />
				<BooleanField
					source="hasComment"
					label="Comments"
					sortable={false}
				/>
				<FunctionField
					label="status"
					render={(record) => (record.status === 'APPROVED' ? <CheckIcon titleAccess='Yes' /> : <CloseIcon titleAccess='No' />)}
				/>
				<BooleanField source="active" />
				<FunctionField
					label="Paid"
					render={(record) => (record.subscription === 'ACTIVE' ? <CheckIcon titleAccess='Yes' /> : <CloseIcon titleAccess='No' />)}
				/>
				<BooleanField source="archive" label="Archived" />
				{/* <SelectField
					source="subscription"
					choices={enumToSelection(UserSubscription)}
				/> */}
				<DateField source="created" />
				<EditButton />
			</Datagrid>
		</List>
	);
};
