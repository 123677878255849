import { gql } from '@apollo/client';
import { IQueries } from '.';
import fragments from '../fragments';

const list: IQueries = {
	users: gql`
		${fragments.users}
		query getUsers(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
		) {
			users(limit: $perPage, page: $page, sort: $sort, filter: $filter) {
				edges {
					node {
						...UserDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	// Don't need this, is over-ridden by schedule
	polls: gql`
		${fragments.polls}
	`,
	reports: gql`
		${fragments.reports}
		query getReports(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
		) {
			reports(
				limit: $perPage
				page: $page
				sort: $sort
				filter: $filter
			) {
				edges {
					node {
						...ReportDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	livingRooms: gql`
		${fragments.livingRooms}
		query getLivingRooms(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
		) {
			livingRooms(
				limit: $perPage
				page: $page
				sort: $sort
				filter: $filter
			) {
				edges {
					node {
						...LivingRoomDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	milestones: gql`
		${fragments.milestones}
		query getMilestones(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
		) {
			milestones(
				limit: $perPage
				page: $page
				sort: $sort
				filter: $filter
			) {
				edges {
					node {
						...MilestoneDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	logs: gql`
		${fragments.logs}
		query getLogs(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
			$start: DateTime
			$end: DateTime
		) {
			logs(
				limit: $perPage
				page: $page
				sort: $sort
				filter: $filter
				start: $start
				end: $end
			) {
				edges {
					node {
						...LogDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	schedules: gql`
		${fragments.schedules}
		query getSchedules(
			$perPage: Float!
			$page: Float!
			$sort: ConnectionSort
			$filter: ConnectionFilter
		) {
			schedules(
				limit: $perPage
				page: $page
				sort: $sort
				filter: $filter
			) {
				edges {
					node {
						...ScheduleDetails
					}
				}
				pageInfo {
					total
				}
			}
		}
	`,
	freeTexts: gql`
		${fragments.freeTexts}
	`,
	quotes: gql`
		${fragments.quotes}
	`,
};
export default list;
