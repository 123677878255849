import { Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import {
	DateTimeInput,
	SimpleForm,
	TextInput,
	Title,
	useNotify,
	useRefresh,
	useDataProvider,
} from 'react-admin';
import { useMutation } from 'react-query';
import {
	RichTextInput,
	FormatButtons,
	RichTextInputToolbar,
	LevelSelect,
	ListButtons,
	ClearButtons,
} from 'ra-input-rich-text';
import { useNavigate } from 'react-router-dom';
import ColorDropDownInput from '../../components/ColorDropdownInput';
import ImageFormatDropDownInput from '../../components/ImageFormatDropdownInput';

type PollCreateInput = {
	title?: string;
	imageURL?: string;
	imageFormat?: string;
	imageTitle?: string;
	imageSubTitle?: string;
	description?: string;
	backgroundColour?: string;
	start?: Date;
	end?: Date;
};

const FreeTextCreatePage = () => {
	const [variables, setVariables] = useState<PollCreateInput>({});

	const refresh = useRefresh();
	const notify = useNotify();
	const navigate = useNavigate();
	const dataProvider = useDataProvider();

	const { mutate: freeTextCreate } = useMutation(
		() => dataProvider.freeTextCreate({ input: variables }),
		{
			// undoable: true,
			onSuccess: (result: any) => {
				refresh();
				if (result?.data?.id) {
					navigate(`/admin/freetexts/${result?.data?.id}/show`);
				}
				notify('Element Created');
			},
			onError: (error: any) =>
				notify(`Error: ${error.message}`, { type: 'warning' }),
		},
	);

	return (
		<Card sx={{ mt: 2 }}>
			<Title title="Create Free Text" />
			<CardContent>
				<SimpleForm
					onSubmit={() => {
						freeTextCreate();
					}}
				>
					<TextInput
						helperText=" FreeText's Title (e.g. `The Peculiar Story`)"
						source="title"
						required
						onChange={(e) => {
							setVariables({
								...variables,
								title: e.target.value,
							});
						}}
					/>

					<RichTextInput
						helperText="Rich Body Text"
						toolbar={
							<RichTextInputToolbar>
								<LevelSelect />
								<FormatButtons />
								<ListButtons />
								<ClearButtons />
							</RichTextInputToolbar>
						}
						source="description"
						onChange={(e) => {
							setVariables((current) => ({
								...current,
								description: e,
							}));
						}}
					/>

					<TextInput
						helperText="Image Title"
						source="question"
						label="Image Title"
						onChange={(e) => {
							setVariables({
								...variables,
								imageTitle: e.target.value,
							});
						}}
					/>

					<TextInput
						helperText="Image Subtitle Text"
						source="imageSubTitle"
						label="Image Subtitle"
						onChange={(e) => {
							setVariables({
								...variables,
								imageSubTitle: e.target.value,
							});
						}}
					/>

					<TextInput
						helperText="Poll's Image URL  (e.g. `https://image.png`)"
						source="imageURL"
						label="Image URL"
						onChange={(e) => {
							setVariables({
								...variables,
								imageURL: e.target.value,
							});
						}}
					/>

					<ImageFormatDropDownInput
						helperText="Format of the Image (e.g. LARGE)"
						source="imageFormat"
						isRequired
						label="Image Format"
						onChange={(e) => {
							setVariables({
								...variables,
								imageFormat: e,
							});
						}}
					/>

					<ColorDropDownInput
						helperText="Background Colour of the Post (e.g. Finance Green)"
						source="background"
						isRequired
						label="Background Color"
						onChange={(e) => {
							setVariables({
								...variables,
								backgroundColour: e,
							});
						}}
					/>

					<DateTimeInput
						helperText="FreeText's Scheduled Start Time"
						source="start"
						required
						label="Start Time"
						onChange={(e) => {
							if (e.target.value)
								setVariables({
									...variables,
									start: new Date(e.target.value),
								});
						}}
					/>

					<DateTimeInput
						helperText="FreeText's Scheduled End Time"
						source="end"
						required
						label="End Time"
						onChange={(e) => {
							if (e.target.value)
								setVariables({
									...variables,
									end: new Date(e.target.value),
								});
						}}
					/>
				</SimpleForm>
			</CardContent>
		</Card>
	);
};

export default FreeTextCreatePage;
