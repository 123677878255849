import { AuthProvider } from 'react-admin';
import { IClient } from './client/client.interface';
import { handleErrors } from './data.provider';
import auth from './mutations/auth';

const buildAuthProvider = (client: IClient): AuthProvider => ({
	login: async ({ username, password }) => {
		const response = await client.mutate<{
			login: { role: string; accessToken: string };
		}>(auth.login, {
			email: username,
			password,
		});

		if (response?.login) {
			if (response.login.role === 'CUSTOMER')
				return Promise.reject(
					new Error(
						'You do not have the permission to access this resource',
					),
				);
			client.login(response.login.accessToken);
			return Promise.resolve();
		}

		return Promise.reject(new Error('Email or password incorrect'));
	},
	logout: async () => {
		await client.mutate(auth.logout);

		client.logout();
		return Promise.resolve();
	},
	checkError: async (error) => {
		if (
			Array.isArray(error.body?.graphQLErrors) &&
			error.body.graphQLErrors.some(
				(e: any) => e.extensions?.code === 'UNAUTHENTICATED',
			)
		) {
			return Promise.reject(new Error('You are not logged in'));
		}

		return Promise.resolve();
	},
	checkAuth: async () => {
		return client.isLoggedInOrPending()
			? Promise.resolve()
			: Promise.reject();
	},
	getPermissions: () => Promise.resolve(),
	getIdentity: async () => {
		let response: Record<string, any> | undefined;
		try {
			response = await client.query(auth.currentUser);
		} catch (err) {
			client.logout();
			return Promise.reject(handleErrors(err));
		}

		if (response?.me) {
			return Promise.resolve({
				id: response.data.me.id ?? '',
				fullName: response.data.me.name ?? '',
			});
		}
		client.logout();
		return Promise.reject();
	},
});
export default buildAuthProvider;
