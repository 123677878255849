import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Container from '../../components/Container';
import TextArea from '../../components/TextArea';
import { getPrettyErrorMessage } from '../../helpers';
import SupportReply from './components/SupportReply';
import { ISupportReply } from './graphql/queries/getSupportRequest';
import { useGetSupportRequestQuery } from './hooks/useGetSupportRequestQuery';
import { useSubmitSupportReplyMutation } from './hooks/useReplySupportRequestMutation';

const SupportRequest = () => {
	const { id } = useParams<{ id: string }>();
	const [body, setBody] = useState<string>('');
	const [replies, setReplies] = useState<ISupportReply[]>();
	const [errorMessage, setErrorMessage] = useState<string>();

	const { data, loading, error } = useGetSupportRequestQuery({
		variables: {
			id,
		},
	});

	const [replySupportRequest, { loading: replyLoading }] =
		useSubmitSupportReplyMutation();

	useEffect(() => {
		if (data?.support.replies && data?.support?.replies.length > 0) {
			setReplies(data?.support.replies);
		}
	}, [data]);

	const handleSubmit = async () => {
		if (replyLoading) return;
		try {
			const { data: replyData, errors } = await replySupportRequest({
				variables: { id, input: { body } },
			});
			if (errors?.[0]) return setErrorMessage(errors[0].message);
			if (replyData?.supportReply) {
				const reply =
					replyData.supportReply.replies[
						replyData.supportReply.replies.length - 1
					];
				setBody('');
				setReplies([...(replies ?? []), reply]);
			}
		} catch (err) {
			if (err?.message) setErrorMessage(getPrettyErrorMessage(err.msg));
		}
	};

	return (
		<main className="w-screen h-screen flex justify-center items-center font-body text-black">
			{loading ? (
				<h3 className="text-xl">
					We&apos;re just fetching your information, one sec...
				</h3>
			) : !data?.support || error ? (
				<p className="text-red-400 text-xl">
					{getPrettyErrorMessage(
						error?.message ?? 'An unknown issue occured',
					)}
				</p>
			) : (
				<Container large>
					<h1 className="text-2xl mb-7 font-bold">
						{data?.support.subject}
					</h1>
					<SupportReply
						defaultName={data?.support.name}
						reply={{
							body: data?.support.body,
							created: data?.support?.created,
						}}
					/>
					{replies &&
						replies.length !== 0 &&
						replies.map((reply) => {
							return (
								<SupportReply
									defaultName={data.support.name}
									reply={reply}
								/>
							);
						})}

					<form
						className="flex flex-col w-full mt-3"
						onSubmit={(e) => {
							e.preventDefault();
							return handleSubmit();
						}}
					>
						<TextArea
							fullWidth
							className="mb-4"
							placeholder="Message"
							label="Message"
							value={body}
							rows={3}
							disabled={replyLoading}
							onChange={(e) => setBody(e.target.value)}
						/>
						{errorMessage && (
							<p className="text-sm text-red-400 mb-4">
								{errorMessage}
							</p>
						)}
						<Button
							fullWidth
							type="submit"
							value="submit"
							disabled={replyLoading}
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</form>
				</Container>
			)}
		</main>
	);
};

export default SupportRequest;
