import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import { useSubmitSupportRequestMutation } from './hooks/useSubmitSupportRequestMutation';

const CreateSupport = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [body, setBody] = useState<string>('');
	const [subject, setSubject] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>();
	const [supportId, setSupportId] = useState<string>();

	const [createSupportRequest, { loading }] =
		useSubmitSupportRequestMutation();

	const handleSubmit = async () => {
		try {
			const { data, errors } = await createSupportRequest({
				variables: { input: { body, name, email, subject } },
			});
			if (errors?.[0]) return setErrorMessage(errors[0].message);
			if (data?.supportCreate.id) setSupportId(data?.supportCreate.id);
		} catch (err) {
			if (err?.message)
				setErrorMessage(
					err?.message.replace(/\[([A-Z_])*\](:)?(\s)?/i, ''),
				);
		}
	};

	return (
		<Container large>
			<h1 className="text-2xl mb-2 font-bold">
				{supportId ? 'Request submitted' : 'Submit a support request'}
			</h1>
			<p className="font-light my-3">
				{supportId
					? "Your support request has been successfully submitted. You'll receive replies via email."
					: "Questions or concerns? Not to worry, we're here to help. Submit a support request below."}
			</p>
			{supportId && (
				<>
					<p className="font-light mt-2 mb-6">
						Alternatively, you can monitor replies online by
						following the button below
					</p>
					<Button
						type="submit"
						value="submit"
						onClick={() => {
							navigate(`/support/${supportId}`);
						}}
					>
						View Support Request
					</Button>
				</>
			)}

			{!supportId && (
				<form
					className="flex flex-col w-full"
					onSubmit={(e) => {
						e.preventDefault();
						return handleSubmit();
					}}
				>
					<Input
						fullWidth
						label="Name"
						type="text"
						className="mb-4 w-full"
						value={name}
						placeholder="Name (Optional)"
						disabled={loading}
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						fullWidth
						label="Email"
						type="email"
						className="mb-4"
						value={email}
						placeholder="Email"
						disabled={loading}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Input
						fullWidth
						label="Subject"
						type="text"
						className="mb-4"
						value={subject}
						placeholder="Subject"
						disabled={loading}
						onChange={(e) => setSubject(e.target.value)}
					/>
					<TextArea
						fullWidth
						className="mb-4"
						placeholder="Message"
						label="Message"
						value={body}
						rows={3}
						disabled={loading}
						onChange={(e) => setBody(e.target.value)}
					/>
					{errorMessage && (
						<p className="text-sm text-red-400 mb-4">
							{errorMessage}
						</p>
					)}
					<Button
						fullWidth
						type="submit"
						value="submit"
						disabled={loading}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</form>
			)}
		</Container>
	);
};

export default CreateSupport;
