import React from 'react';
import { BooleanInput, Filter } from 'react-admin';

interface IScheduleFilterProps {
	context?: 'form' | 'button';
	variant?: string;
}
const ScheduleFilter = (props: IScheduleFilterProps) => {
	return (
		<Filter {...props}>
			<BooleanInput source="past" alwaysOn label="Show past posts" />
		</Filter>
	);
};

export default ScheduleFilter;
