import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectScheduleList = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('/admin/schedules');
	}, [navigate]);
	return <></>;
};
