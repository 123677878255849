import React from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';
import ExternalActivateButton from './external.activate.button';
import ExternalArchiveButton from './external.archive.button';

const UserShowActions = () => {
	const record = useRecordContext();
	return (
		<TopToolbar>
			<EditButton record={record} />
			{!record?.active && <ExternalActivateButton record={record} />}
			{!record?.archive && <ExternalArchiveButton record={record} />}
		</TopToolbar>
	);
};

export default UserShowActions;
