export const countryCodes = [
	{ name: 'Afghanistan (+93)', id: '+93' },
	{ name: 'Aland Islands (+358)', id: '+358' },
	{ name: 'Albania (+355)', id: '+355' },
	{ name: 'Algeria (+213)', id: '+213' },
	{ name: 'AmericanSamoa (+1684)', id: '+1684' },
	{ name: 'Andorra (+376)', id: '+376' },
	{ name: 'Angola (+244)', id: '+244' },
	{ name: 'Anguilla (+1264)', id: '+1264' },
	{ name: 'Antarctica (+672)', id: '+672' },
	{ name: 'Antigua and Barbuda (+1268)', id: '+1268' },
	{ name: 'Argentina (+54)', id: '+54' },
	{ name: 'Armenia (+374)', id: '+374' },
	{ name: 'Aruba (+297)', id: '+297' },
	{ name: 'Australia (+61)', id: '+61' },
	{ name: 'Austria (+43)', id: '+43' },
	{ name: 'Azerbaijan (+994)', id: '+994' },
	{ name: 'Bahamas (+1242)', id: '+1242' },
	{ name: 'Bahrain (+973)', id: '+973' },
	{ name: 'Bangladesh (+880)', id: '+880' },
	{ name: 'Barbados (+1246)', id: '+1246' },
	{ name: 'Belarus (+375)', id: '+375' },
	{ name: 'Belgium (+32)', id: '+32' },
	{ name: 'Belize (+501)', id: '+501' },
	{ name: 'Benin (+229)', id: '+229' },
	{ name: 'Bermuda (+1441)', id: '+1441' },
	{ name: 'Bhutan (+975)', id: '+975' },
	{ name: 'Bolivia (+591)', id: '+591' },
	{ name: 'Bosnia and Herzegovina (+387)', id: '+387' },
	{ name: 'Botswana (+267)', id: '+267' },
	{ name: 'Brazil (+55)', id: '+55' },
	{ name: 'British Indian Ocean Territory (+246)', id: '+246' },
	{ name: 'Brunei Darussalam (+673)', id: '+673' },
	{ name: 'Bulgaria (+359)', id: 'BG +359' },
	{ name: 'Burkina Faso (+226)', id: '+226' },
	{ name: 'Burundi (+257)', id: 'BI +257' },
	{ name: 'Cambodia (+855)', id: 'KH +855' },
	{ name: 'Cameroon (+237)', id: 'CM+237' },
	{ name: 'Canada (+1)', id: '+1' },
	{ name: 'Cape Verde (+238)', id: '+238' },
	{ name: 'Cayman Islands (+ 345)', id: '+345' },
	{ name: 'Central African Republic (+236)', id: '+236' },
	{ name: 'Chad (+235)', id: '+235' },
	{ name: 'Chile (+56)', id: '+56' },
	{ name: 'China (+86)', id: '+86' },
	{ name: 'Christmas Island (+61)', id: '+61' },
	{ name: 'Cocos (Keeling) Islands (+61)', id: '+61' },
	{ name: 'Colombia (+57)', id: '+57' },
	{ name: 'Comoros (+269)', id: '+269' },
	{ name: 'Congo (+242)', id: '+242' },
	{ name: 'Congo (+243)', id: '+243' },
	{ name: 'Cook Islands (+682)', id: '+682' },
	{ name: 'Costa Rica (+506)', id: '+506' },
	{ name: "Cote d'Ivoire (+225)", id: '+225' },
	{ name: 'Croatia (+385)', id: '+385' },
	{ name: 'Cuba (+53)', id: '+53' },
	{ name: 'Cyprus (+357)', id: '+357' },
	{ name: 'Czech Republic (+420)', id: '+420' },
	{ name: 'Denmark (+45)', id: '+45' },
	{ name: 'Djibouti (+253)', id: '+253' },
	{ name: 'Dominica (+1767)', id: '+1767' },
	{ name: 'Dominican Republic (+1849)', id: '+1849' },
	{ name: 'Ecuador (+593)', id: '+593' },
	{ name: 'Egypt (+20)', id: '+20' },
	{ name: 'El Salvador (+503)', id: '+503' },
	{ name: 'Equatorial Guinea (+240)', id: '+240' },
	{ name: 'Eritrea (+291)', id: '+291' },
	{ name: 'Estonia (+372)', id: '+372' },
	{ name: 'Ethiopia (+251)', id: '+251' },
	{ name: 'Falkland Islands (Malvinas) (+500)', id: '+500' },
	{ name: 'Faroe Islands (+298)', id: '+298' },
	{ name: 'Fiji (+679)', id: '+679' },
	{ name: 'Finland (+358)', id: '+358' },
	{ name: 'France (+33)', id: '+33' },
	{ name: 'French Guiana (+594)', id: '+594' },
	{ name: 'French Polynesia (+689)', id: '+689' },
	{ name: 'Gabon (+241)', id: '+241' },
	{ name: 'Gambia (+220)', id: '+220' },
	{ name: 'Georgia (+995)', id: '+995' },
	{ name: 'Germany (+49)', id: '+49' },
	{ name: 'Ghana (+233)', id: '+233' },
	{ name: 'Gibraltar (+350)', id: '+350' },
	{ name: 'Greece (+30)', id: '+30' },
	{ name: 'Greenland (+299)', id: '+299' },
	{ name: 'Grenada (+1473)', id: '+1473' },
	{ name: 'Guadeloupe (+590)', id: '+590' },
	{ name: 'Guam (+1671)', id: '+1671' },
	{ name: 'Guatemala (+502)', id: '+502' },
	{ name: 'Guernsey (+44)', id: '+44' },
	{ name: 'Guinea (+224)', id: '+224' },
	{ name: 'Guinea-Bissau (+245)', id: '+245' },
	{ name: 'Guyana (+595)', id: '+595' },
	{ name: 'Haiti (+509)', id: '+509' },
	{ name: 'Holy See (Vatican City State) (+379)', id: '+379' },
	{ name: 'Honduras (+504)', id: '+504' },
	{ name: 'Hong Kong (+852)', id: '+852' },
	{ name: 'Hungary (+36)', id: '+36' },
	{ name: 'Iceland (+354)', id: '+354' },
	{ name: 'India (+91)', id: '+91' },
	{ name: 'Indonesia (+62)', id: '+62' },
	{ name: 'Iran (+98)', id: '+98' },
	{ name: 'Iraq (+964)', id: '+964' },
	{ name: 'Ireland (+353)', id: 'IE +353' },
	{ name: 'Isle of Man (+44)', id: '+44' },
	{ name: 'Israel (+972)', id: '+972' },
	{ name: 'Italy (+39)', id: '+39' },
	{ name: 'Jamaica (+1876)', id: '+1876' },
	{ name: 'Japan (+81)', id: '+81' },
	{ name: 'Jersey (+44)', id: '+44' },
	{ name: 'Jordan (+962)', id: '+962' },
	{ name: 'Kazakhstan (+77)', id: '+77' },
	{ name: 'Kenya (+254)', id: '+254' },
	{ name: 'Kiribati (+686)', id: '+686' },
	{ name: 'Korea (North) (+850)', id: '+850' },
	{ name: 'Korea (South) (+82)', id: '+82' },
	{ name: 'Kuwait (+965)', id: '+965' },
	{ name: 'Kyrgyzstan (+996)', id: '+996' },
	{ name: 'Laos (+856)', id: '+856' },
	{ name: 'Latvia (+371)', id: '+371' },
	{ name: 'Lebanon (+961)', id: '+961' },
	{ name: 'Lesotho (+266)', id: '+266' },
	{ name: 'Liberia (+231)', id: '+231' },
	{ name: 'Libyan Arab Jamahiriya (+218)', id: '+218' },
	{ name: 'Liechtenstein (+423)', id: '+423' },
	{ name: 'Lithuania (+370)', id: '+370' },
	{ name: 'Luxembourg (+352)', id: '+352' },
	{ name: 'Macao (+853)', id: '+853' },
	{ name: 'Macedonia (+389)', id: '+389' },
	{ name: 'Madagascar (+261)', id: '+261' },
	{ name: 'Malawi (+265)', id: '+265' },
	{ name: 'Malaysia (+60)', id: '+60' },
	{ name: 'Maldives (+960)', id: '+960' },
	{ name: 'Mali (+223)', id: '+223' },
	{ name: 'Malta (+356)', id: '+356' },
	{ name: 'Marshall Islands (+692)', id: ' +692' },
	{ name: 'Martinique (+596)', id: '+596' },
	{ name: 'Mauritania (+222)', id: '+222' },
	{ name: 'Mauritius (+230)', id: '+230' },
	{ name: 'Mayotte (+262)', id: '+262' },
	{ name: 'Mexico (+52)', id: '+52' },
	{ name: 'Micronesia (+691)', id: '+691' },
	{ name: 'Moldova (+373)', id: '+373' },
	{ name: 'Monaco (+377)', id: '+377' },
	{ name: 'Mongolia (+976)', id: '+976' },
	{ name: 'Montenegro (+382)', id: '+382' },
	{ name: 'Montserrat (+1664)', id: '+1664' },
	{ name: 'Morocco (+212)', id: '+212' },
	{ name: 'Mozambique (+258)', id: '+258' },
	{ name: 'Myanmar (+95)', id: '+95' },
	{ name: 'Namibia (+264)', id: '+264' },
	{ name: 'Nauru (+674)', id: '+674' },
	{ name: 'Nepal (+977)', id: '+977' },
	{ name: 'Netherlands (+31)', id: '+31' },
	{ name: 'Netherlands Antilles (+599)', id: '+599' },
	{ name: 'New Caledonia (+687)', id: '+687' },
	{ name: 'New Zealand (+64)', id: '+64' },
	{ name: 'Nicaragua (+505)', id: '+505' },
	{ name: 'Niger (+227)', id: '+227' },
	{ name: 'Nigeria (+234)', id: '+234' },
	{ name: 'Niue (+683)', id: '+683' },
	{ name: 'Norfolk Island (+672)', id: '+672' },
	{ name: 'Northern Mariana Islands (+1670)', id: '+1670' },
	{ name: 'Norway (+47)', id: '+47' },
	{ name: 'Oman (+968)', id: '+968' },
	{ name: 'Pakistan (+92)', id: '+92' },
	{ name: 'Palau (+680)', id: '+680' },
	{ name: 'Palestinian Territory (+970)', id: '+970' },
	{ name: 'Panama (+507)', id: '+507' },
	{ name: 'Papua New Guinea (+675)', id: '+675' },
	{ name: 'Paraguay (+595)', id: '+595' },
	{ name: 'Peru (+51)', id: '+51' },
	{ name: 'Philippines (+63)', id: '+63' },
	{ name: 'Pitcairn (+872)', id: '+872' },
	{ name: 'Poland (+48)', id: '+48' },
	{ name: 'Portugal (+351)', id: '+351' },
	{ name: 'Puerto Rico (+1939)', id: '+1939' },
	{ name: 'Qatar (+974)', id: '+974' },
	{ name: 'Romania (+40)', id: '+40' },
	{ name: 'Russia (+7)', id: '+7' },
	{ name: 'Rwanda (+250)', id: '+250' },
	{ name: 'Reunion (+262)', id: '+262' },
	{ name: 'Saint Barthelemy (+590)', id: '+590' },
	{ name: 'Saint Helena (+290)', id: '+290' },
	{ name: 'Saint Kitts and Nevis (+1869)', id: '+1869' },
	{ name: 'Saint Lucia (+1758)', id: '+1758' },
	{ name: 'Saint Martin (+590)', id: '+590' },
	{ name: 'Saint Pierre and Miquelon (+508)', id: '+508' },
	{ name: 'Saint Vincent and the Grenadines (+1784)', id: '+1784' },
	{ name: 'Samoa (+685)', id: '+685' },
	{ name: 'San Marino (+378)', id: '+378' },
	{ name: 'Sao Tome and Principe (+239)', id: '+239' },
	{ name: 'Saudi Arabia (+966)', id: '+966' },
	{ name: 'Senegal (+221)', id: '+221' },
	{ name: 'Serbia (+381)', id: '+381' },
	{ name: 'Seychelles (+248)', id: '+248' },
	{ name: 'Sierra Leone (+232)', id: '+232' },
	{ name: 'Singapore (+65)', id: '+65' },
	{ name: 'Slovakia (+421)', id: '+421' },
	{ name: 'Slovenia (+386)', id: '+386' },
	{ name: 'Solomon Islands (+677)', id: '+677' },
	{ name: 'Somalia (+252)', id: '+252' },
	{ name: 'South Africa (+27)', id: '+27' },
	{ name: 'South Sudan (+211)', id: '+211' },
	{ name: 'South Georgia (+500)', id: '+500' },
	{ name: 'Spain (+34)', id: '+34' },
	{ name: 'Sri Lanka (+94)', id: '+94' },
	{ name: 'Sudan (+249)', id: '+249' },
	{ name: 'Suriname (+597)', id: '+597' },
	{ name: 'Svalbard and Jan Mayen (+47)', id: '+47' },
	{ name: 'Swaziland (+268)', id: '+268' },
	{ name: 'Sweden (+46)', id: '+46' },
	{ name: 'Switzerland (+41)', id: '+41' },
	{ name: 'Syrian Arab Republic (+963)', id: '+963' },
	{ name: 'Taiwan (+886)', id: '+886' },
	{ name: 'Tajikistan (+992)', id: '992' },
	{ name: 'Tanzania (+255)', id: '+255' },
	{ name: 'Thailand (+66)', id: '+66' },
	{ name: 'Timor-Leste (+670)', id: '+670' },
	{ name: 'Togo (+228)', id: '+228' },
	{ name: 'Tokelau (+690)', id: '+690' },
	{ name: 'Tonga (+676)', id: '+676' },
	{ name: 'Trinidad and Tobago (+1868)', id: '+1868' },
	{ name: 'Tunisia (+216)', id: '+216' },
	{ name: 'Turkey (+90)', id: '+90' },
	{ name: 'Turkmenistan (+993)', id: '+993' },
	{ name: 'Turks and Caicos Islands (+1649)', id: '+1649' },
	{ name: 'Tuvalu (+688)', id: '+688' },
	{ name: 'Uganda (+256)', id: '+256' },
	{ name: 'Ukraine (+380)', id: '+380' },
	{ name: 'United Arab Emirates (+971)', id: '+971' },
	{ name: 'United Kingdom (+44)', id: '+44' },
	{ name: 'United States (+1)', id: '+1' },
	{ name: 'Uruguay (+598)', id: '+598' },
	{ name: 'Uzbekistan (+998)', id: '+998' },
	{ name: 'Vanuatu (+678)', id: '+678' },
	{ name: 'Venezuela (+58)', id: '+58' },
	{ name: 'Vietnam (+84)', id: '+84' },
	{ name: 'Virgin Islands, British (+1284)', id: '+1284' },
	{ name: 'Virgin Islands, U.S. (+1340)', id: '+1340' },
	{ name: 'Wallis and Futuna (+681)', id: '+681' },
	{ name: 'Yemen (+967)', id: '+967' },
	{ name: 'Zambia (+260)', id: '+260' },
	{ name: 'Zimbabwe (+263)', id: '+263' },
];
