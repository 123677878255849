import { DocumentNode } from 'graphql';
import {
	createClient,
	Client,
	dedupExchange,
	cacheExchange,
	fetchExchange,
	errorExchange,
	Operation,
	AnyVariables,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import auth from '../mutations/auth';
import { IClient } from './client.interface';
import { TokenService } from '../token.service';

const STATUS = 'com.uplevyl.authStatus:urql';
type AuthenticationStatus = 'authenticated' | 'pending';

interface ITokens {
	accessToken: string;
	refreshToken?: string;
}

export class UrqlClient implements IClient {
	private client: Client;

	constructor(baseURL: string, private readonly tokenService: TokenService) {
		this.client = this.createClient(baseURL);
	}

	getClient<Client>(): Client {
		return this.client as unknown as Client;
	}

	async mutate<D = Record<string, any>, V = Record<string, any>>(
		query: DocumentNode,
		variables?: V | undefined,
	): Promise<D | undefined> {
		const result = await this.client
			.mutation<D>(query, variables ?? {})
			.toPromise();

		if (result.error) throw result.error;

		return result.data;
	}

	async query<D = Record<string, any>, V = Record<string, any>>(
		query: DocumentNode,
		variables?: V | undefined,
	): Promise<D | undefined> {
		const result = await this.client
			.query<D>(query, variables ?? {})
			.toPromise();

		if (result.error) throw result.error;

		return result.data;
	}

	login(token: string): void {
		this.tokenService.token = token;
	}

	logout(): void {
		this.tokenService.removeToken();
	}

	/**
	 * Is the user logged in (or token refresh in flight)
	 * @description Can be edited by user as in LS
	 */
	public isLoggedInOrPending() {
		return !!this.tokenService.token;
	}

	private setStatus(status: AuthenticationStatus | null) {
		if (!status) return localStorage.removeItem(STATUS);
		localStorage.setItem(STATUS, status);
	}

	/**
	 * Refresh access token using refresh token
	 */
	public async refreshTokens(): Promise<string> {
		this.setStatus('pending');
		const response = await this.mutate(auth.refresh);
		if (response?.refresh?.accessToken) {
			this.setStatus('authenticated');
			return response.refresh.accessToken;
		}
		this.setStatus(null);
		throw new Error();
	}

	private createClient(url: string) {
		return createClient({
			url,
			fetchOptions: {
				credentials: 'include',
			},
			exchanges: [
				dedupExchange,
				cacheExchange,
				errorExchange({
					onError: (error) => {
						const isAuthError = error.graphQLErrors.some(
							(e) => e.extensions?.code === 'UNAUTHENTICATED',
						);

						if (isAuthError) {
							this.logout();
						}
					},
				}),
				authExchange<ITokens>({
					addAuthToOperation: ({
						authState,
						operation,
					}): Operation<any, AnyVariables> => {
						if (!authState || !authState.accessToken) {
							return operation;
						}

						const fetchOptions =
							typeof operation.context.fetchOptions === 'function'
								? operation.context.fetchOptions()
								: operation.context.fetchOptions || {};

						return makeOperation(operation.kind, operation, {
							...operation.context,
							fetchOptions: {
								...fetchOptions,
								headers: {
									...fetchOptions.headers,
									Authorization: `Bearer ${authState.accessToken}`,
								},
							},
						});
					},
					didAuthError: ({ error }): boolean => {
						return error.graphQLErrors.some(
							(e) => e.extensions?.code === 'UNAUTHENTICATED',
						);
					},
					willAuthError: (): boolean => {
						return false;
					},
					getAuth: async ({
						authState,
						mutate,
					}): Promise<ITokens | null> => {
						if (!authState) {
							const accessToken = this.tokenService.token;
							if (accessToken) {
								return {
									accessToken,
								};
							}
							return null;
						}

						this.log('Refreshing');
						const result = await mutate(auth.refresh);

						if (result.data?.refresh) {
							this.login(result.data.refresh.accessToken);

							return {
								accessToken: result.data.refresh.accessToken,
								refreshToken: result.data.refresh.refreshToken,
							};
						}

						this.logout();

						return null;
					},
				}),
				fetchExchange,
			],
		});
	}

	private log(...messages: any[]) {
		console.log(`[Urql Client]: ${messages.join(' ')}`);
	}
}
